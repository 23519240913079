<div class="jk-search-result-container">

    <mat-card>
        <div class="jk-search-result-card">
            <div class="jk-search-result-image">
                <img src="assets/images/resort.png">
            </div>
            <div class="jk-search-result-content">
                <h1>Haja Premium Villa</h1>
                <p>Courtallam-627818 </p>
                <div class="jk-search-result-facility">
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="wifi"></mat-icon> <span>Free
                        WiFi</span>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="tv"></mat-icon>
                    <span>Tv</span>
                    <span>+5 more</span>
                </div>

                <div class="jk-search-result-offer">
                    <p>&#x20B9; 2000 <del>4000</del><span>50% offer</span></p>
                    <p>+Rs 100 taxes & fees per room per night</p>
                </div>
                <button mat-raised-button>Book Now</button>

            </div>

        </div>
    </mat-card>

    <mat-card>
        <div class="jk-search-result-card">
            <div class="jk-search-result-image">
                <img src="assets/images/resort.png">
            </div>
            <div class="jk-search-result-content">
                <h1>Haja Premium Villa</h1>
                <p>Courtallam-627818 </p>
                <div class="jk-search-result-facility">
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="wifi"></mat-icon> <span>Free
                        WiFi</span>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="tv"></mat-icon>
                    <span>Tv</span>
                    <span>+5 more</span>
                </div>

                <div class="jk-search-result-offer">
                    <p>&#x20B9; 2000 <del>4000</del><span>50% offer</span></p>
                    <p>+Rs 100 taxes & fees per room per night</p>
                </div>
                <button mat-raised-button>Book Now</button>

            </div>

        </div>
    </mat-card>


</div>