import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PropertyService } from '../../shared/services/property.service';
import { HttpClientModule } from '@angular/common/http';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, timestamp } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../shared/services/notification.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { IBookingDetail } from '../../property/property';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-booking-details',
  standalone: true,
  providers: [
    DatePipe
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    TranslateModule,
    SpinnerComponent],
  templateUrl: './booking-details.component.html',
  styleUrl: './booking-details.component.scss'
})

export class BookingDetailsComponent implements OnInit {
  public masterBookingid!: number;
  public dialogResult!: boolean;
  private confirmAction = false; // Recieves value from the its child component (ConfirmDialogComponent)
  public booking_id!: number;
  public viewDataSource!: IBookingDetail[];
  displayedColumns: string[] = ['booking_id', 'property_name', 'from_date', 'to_date', 'total_amount_received', 'booked_on', ];
  dataSource = this.viewDataSource;
  public showSpinner: boolean = false;
  public createdAt!: string | null
  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private readonly translate: TranslateService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe) {

    this.getViewBookingDetails();

  }

  ngOnInit() {
    this.showSpinner = true;
  }

  getViewBookingDetails() {

    this.propertyService.getViewBookingDetail().subscribe(result => {
      this.dataSource = result;
      this.showSpinner = false;
    });

  }

  showCancelPopUp() {
    this.openConfirmationDialogBox();
  }

  public openConfirmationDialogBox() {

    let title = '';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      minHeight: '150px',
      maxHeight: '95vh',
      data: {
        // propertyId: this.propertyId,
        // floorDetails: this.floorDetails,
        title: "Cancel Room",
        message: "Do you want to cancel all the room"
      }
    });

    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
      if (this.confirmAction) {
        // this.cancelRoom();
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result.value;
      console.log(this.dialogResult)
      dialogSub.unsubscribe();
    });
  }


  // cancelRoom() {
  //   this.propertyService.cancelRoom().subscribe(result => {
  //     this.router.navigate(['user/success-page/cancel']);
  //     console.log(result.data);

  //   });
  // }

  setDateFormat(timestamp: string): string {

    const formattedDate = this.datePipe.transform(timestamp, 'dd-MM-YYYY');
    this.createdAt = formattedDate;

    return formattedDate ? formattedDate : '';
  }


}
