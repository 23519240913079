import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IBooKRoomResponse, IrpCheckoutResponse } from '../../property/razorPay';

@Injectable({
  providedIn: 'root'
})
export class RazorPayService {

  private createOrderUrl = environment.apiUrl + 'bookRooms'
  private savePaymentDetailsUrl = environment.apiUrl + 'razorpayUserPaymentHistory'
  

  constructor(private http: HttpClient) { }

  // getRelatedProperties(): Observable<{ data: IPropertyDetails[] }> {
  //   return this.http.get<any>(this.relatedPropertyUrl);
  // }

  
    createOrder(params: any): Observable<IBooKRoomResponse> {
    console.log(params);
    return this.http.post<any>(this.createOrderUrl, params);
  }

  savePaymentDetails(params: IrpCheckoutResponse): Observable<any> {
    
    return this.http.post<any>(this.savePaymentDetailsUrl, params);
  }


}


