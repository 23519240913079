import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { IScreensize } from '../../../app';
import { WashroomTypesPipe } from '../../../shared/master-data/pipes/washroom-types.pipe';
import { YesNoPipe } from '../../../shared/master-data/pipes/yes-no.pipe';
import { NotificationService } from '../../../shared/services/notification.service';
import { PropertyService } from '../../../shared/services/property.service';
import { ScreensizeDetectService } from '../../../shared/services/screensize-detect.service';
import { ISearchPropertyDetails } from '../../property';
@Component({
  selector: 'app-results',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    RouterLink,
    MatButtonModule,
    YesNoPipe,
    WashroomTypesPipe,],
  templateUrl: './results.component.html',
  styleUrl: './results.component.scss'
})
export class ResultsComponent implements OnInit {

  public thumbnailUrl = environment.imageUrl + 'thumbnail/';
  public noPhotoUrl = environment.imageUrl + 'watermark/';
  //public genderId:number = 1;
  public screenInfo!: IScreensize;

  @Input() properties: ISearchPropertyDetails[] = [];

  constructor(
    private propertyService: PropertyService,
    private notificationService: NotificationService,
    private readonly translate: TranslateService,
    private screensizeDetectService: ScreensizeDetectService
  ) {

    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
  }

  ngOnInit(): void {

  }

}
