<div class="jk-pp-container">
    <div class="jk-pp-notice">
        <h1>CourtallamRooms Privacy Policy </h1>
        <p>At CourtallamRooms, safeguarding your information is our top priority. This privacy notice outlines the
            details
            of information collection, usage, protection, your rights, and contact information for inquiries about your
            information usage.</p>
    </div>
    <div class="jk-pp-information">
        <h2>Information about CourtallamRooms</h2>
        <p>In this notice, "CourtallamRooms," "we," "us," or "our" refer to Thamira Technologies and its subsidiaries,
            collectively forming the "CourtallamRooms Group."</p>
    </div>
    <div class="jk-pp-scope">
        <h2>Scope of Our Privacy Notice</h2>
        <p>This notice is applicable to anyone interacting with us for our products and services. This includes various
            channels such as apps, websites, email, phone, walk-ins to CourtallamRooms-partnered accommodations, or
            booking
            via third-party agencies. Additional privacy notices may be provided for specific interactions.</p>
    </div>
    <div class="jk-pp-personal-information">
        <h2>Ways We Obtain Personal Information</h2>
        <p>We obtain personal information directly from you and from third parties like travel agents who book
            accommodations on your behalf. If you provide information about others, ensure they are aware of and
            comfortable
            with this privacy policy.</p>
    </div>
    <div class="jk-pp-categories">
        <h2>Categories of Personal Information</h2>
        <p>We process two categories of personal information:</p>
        <div class="lists">
            <ol>
                <li>
                    <p>Standard Personal Information: Used for contact, identification, and relationship management.</p>
                </li>
                <li>
                    <p>Special Categories: Includes preferences like medical conditions requiring special
                        accommodations.
                    </p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-pp-processing">
        <h2>Purposes and Lawful Grounds for Processing</h2>
        <p>We process personal information for various purposes based on legal grounds. Different legal grounds apply
            depending on the category of personal information processed. Standard personal information is usually
            processed
            based on the necessity for contract performance, legitimate interests, or law.</p>
    </div>
    <div class="jk-pp-interests">
        <h2>Legitimate Interests</h2>
        <p>We process personal information for various legitimate interests, including providing services, administering
            relationships, marketing, and service/product improvement. Detailed information about our legitimate
            interests
            is provided below.</p>
    </div>
    <div class="jk-pp-marketing">
        <h2>Marketing and Preferences</h2>
        <p>Your information is utilized for marketing through various channels. Employing technology, we analyze your
            preferences, facilitating personalized recommendations and marketing communications. At any time, you have
            the
            option to unsubscribe or modify preferences. If you wish to raise objections to profiling for direct
            marketing,
            please reach out to : <strong>{{email}}</strong></p>
    </div>
    <div class="jk-pp-analytics">
        <h2>Analytics, Advertising, and Data Sharing at CourtallamRooms: </h2>
        <div class="lists">
            <ol>
                <li>
                    <div class="jk-pp-advertising">
                        <h3>Analytics and Advertising Services</h3>
                        <p>CourtallamRooms employs third-party services for audience measurement, analytics, and
                            tailored
                            marketing.
                            These services analyze your online interactions across websites, including
                            CourtallamRooms,
                            providing
                            insights into marketing performance. The Google Place API is used for location-based
                            suggestions.
                            Refer to
                            Google's privacy policy at <a
                                href="https://policies.google.com/privacy">http://www.google.com/privacy.html</a> for
                            details.
                        </p>
                    </div>
                </li>
                <li>
                    <div class="jk-pp-sharing">
                        <h3> Sharing Your Information</h3>
                        <p>Your information is shared within the CourtallamRooms Group and with service providers to
                            enhance
                            your
                            experience. Sharing is also done in compliance with the law. Key recipients include:</p>
                        <ul>

                            <li>
                                <p>Other CourtallamRooms Group Members: Ensures seamless service across the
                                    CourtallamRooms
                                    network.</p>
                            </li>
                            <li>
                                <p>Accommodation Managers: Shared details like your name and expected check-in time for
                                    service
                                    provision.</p>
                            </li>
                            <li>
                                <p>Third-Party Suppliers: Assist in delivering products or services on CourtallamRooms's
                                    behalf
                                    or
                                    through partnerships.</p>
                            </li>
                            <li>
                                <p>Referral Contacts: Shared if you use a referral feature.</p>
                            </li>
                            <li>
                                <p>Law Enforcement or Government Authorities: Information disclosed if required by law.
                                </p>
                            </li>
                            <li>
                                <p>Merger or Business Transactions: Information shared in connection with business
                                    transactions,
                                    such
                                    as mergers or acquisitions.</p>
                            </li>
                        </ul>
                        <p>When sharing your information, adequate safeguards are in place to meet data protection laws.
                            In
                            some
                            instances, information may be shared in an aggregated or anonymized form to prevent
                            identification.
                        </p>
                    </div>
                </li>
                <li>
                    <div class="jk-pp-retention-period">
                        <h3>Retention Period</h3>
                        <p>Your personal information is retained following CourtallamRooms's records retention policies.
                            Circumstances may necessitate extended retention, such as claims related to a booking.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-pp-protection">
                        <h3>Data Protection Contacts</h3>
                        <p>For any questions, comments, complaints, or suggestions regarding this notice or concerns
                            about
                            information processing, reach out to our Privacy Team at <strong>{{email}}</strong></p>
                    </div>
                </li>
                <p>at CourtallamRooms, we prioritize your privacy, employ secure data-sharing practices, and are
                    transparent
                    about
                    our procedures.</p>
            </ol>
        </div>
    </div>
</div>