import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-layout-new-user',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterOutlet,TranslateModule,MatIconModule,MatToolbarModule],
  templateUrl: './layout-new-user.component.html',
  styleUrl: './layout-new-user.component.scss'
})
export class LayoutNewUserComponent {
  public environment!: any;

  constructor(
  ) {
    this.environment = environment;
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
  }




}
