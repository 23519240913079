import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtmlPipe } from '../safe-html.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BedTypePipe } from '../master-data/pipes/bed-types.pipe';
import { MasterDataService } from '../services/master-data.service';
@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatDialogActions, MatDialogContent, SafeHtmlPipe, MatButtonModule, TranslateModule, MatDialogClose, MatCheckboxModule,FormsModule,MatInputModule,BedTypePipe],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  @Output() confirmAction = new EventEmitter<boolean>();
  // extraBedChecked: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private readonly masterDataService: MasterDataService,) {

  }



  confirm(): void {
    this.confirmAction.emit(true);
    // this.dialogRef.close({ confirmed, extraBedChecked: this.extraBedChecked })
  }
  cancel(): void {
    this.confirmAction.emit(false);
  }
}
