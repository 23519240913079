import { Component } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { ICancellationTable, ICheckInTable, ICheckOutTable } from '../../property/property';
import { debounceTime, fromEvent, map, tap } from 'rxjs';
const CHECK_IN_DATA: ICheckInTable[] = [
  { CheckinTime: 'Before 6 AM ', EarlyCheckinCharges: '100% charges for one day payable as per room rates for the previous day ' },
  { CheckinTime: 'Between 6 AM and 10 AM  ', EarlyCheckinCharges: '0% to 30% charges payable as per room rates for the previous day, depending on hotel policy  ' },
  { CheckinTime: 'Between 10 AM and 12 PM  ', EarlyCheckinCharges: 'Complimentary-subject to availability  ' },
];
const CHECK_OUT_DATA: ICheckOutTable[] = [
  { CheckoutTime: 'Between 10 AM and 1 PM  ', LateCheckoutCharges: 'Complimentary-subject to availability ' },
  { CheckoutTime: 'Between 1 PM and 5 PM   ', LateCheckoutCharges: 'Up to 30% of the room rate for the day, depending on hotel policy  ' },
  { CheckoutTime: 'After 5 PM  ', LateCheckoutCharges: '100% of the room rate for the day ' },
];
const CANCELLATION: ICancellationTable[] = [
  {CancellationPeriod   : 'More than 72 hours (3 days) before check-in time   ',RefundPercentage :'90% '},
  {CancellationPeriod   : '24 hours to 72 hours (3 days) before check-in time  ',RefundPercentage :'70% '},
  {CancellationPeriod   : ' Within 24 hours of the check-in time   ',RefundPercentage :'10%'},
];
@Component({
  selector: 'app-general-booking-policy',
  standalone: true,
  imports: [MatTableModule,],
  templateUrl: './booking-policy.component.html',
  styleUrl: './booking-policy.component.scss'

})
export class BookingPolicyComponent  {
 
  displayedColumns: string[] = ['CheckinTime', 'EarlyCheckinCharges'];
  dataSource = CHECK_IN_DATA;
  displayedColumn: string[] = ['CheckoutTime', 'LateCheckoutCharges'];
  dataSources = CHECK_OUT_DATA;
  displayed: string[] = ['CancellationPeriod', 'RefundPercentage'];
  data = CANCELLATION;
}
