@if(propertyDetails && searchValuesArr){
<div class="jk-vp-container" [ngClass]="screenInfo.cssClass">
    <div class="jk-vp-title">
        <h1>{{propertyDetails.property_name}}</h1>
    </div>


    <div class="jk-vp-row">
        <div class="jk-vp-row-item">
            <!-- <h4>Capacity:</h4> -->
            <div class="jk-vp-category">

                <div class="jk-vp-category-item">

                    <mat-icon aria-hidden="false">date_range</mat-icon>
                    <div><span class="jk-vp-date">{{displayFromDate}}</span> To <span
                            class="jk-vp-date">{{displayToDate}}</span> (<span class="jk-vp-days">{{noOfDays}} day{{
                            (noOfDays > 0) ? 's' : '' }}</span>)</div>
                </div>

                <!-- <div class="jk-vp-category-item">

                    <mat-icon aria-hidden="false">date_range</mat-icon>
                    <div>Check-out: &nbsp;{{displayToDate}}</div>
                </div> -->

                <div class="jk-vp-category-group">
                    <div class="jk-vp-category-item jk-vp-person-count">
                        <mat-icon aria-hidden="false" aria-label="">cottage</mat-icon>
                        <div>{{this.searchValuesArr.room_count}} Room(s)</div>
                    </div>

                    <div class="jk-vp-category-item">

                        <mat-icon aria-hidden="false">person</mat-icon>
                        <div>{{this.searchValuesArr.adult_count}} Adult(s)</div>
                    </div>

                    <div class="jk-vp-category-item">
                        <mat-icon aria-hidden="false" aria-label="" fontIcon="diversity_3"></mat-icon>
                        <div>{{this.searchValuesArr.children_count}} Children</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="jk-spacer"></div>

        @if(!propertyDetails.bed_types.length){
        <div class="jk-vp-no-rooms-msg">
            No Rooms available in this property for the given dates
        </div>
        }
    </div>


    @if(propertyDetails.bed_types.length){
    <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms"
        (selectedTabChange)="getCurrentRoomDetailsForActiveTab($event)" dynamicHeight>
        @for (bed_type of propertyDetails.bed_types; track bed_type) {

        <mat-tab label="{{bed_type.bed_type_id | bedType}}">
            @if(propertyPhotoDetails){
            <div class="jk-vp-image-container">
                <div class="jk-vp-img-big">
                    @if(propertyPhotoDetails[currentIndex]){
                    <img [src]="mainPhotoUrl + propertyPhotoDetails[currentIndex].path + '/' + propertyPhotoDetails[currentIndex].name + '.' + propertyPhotoDetails[currentIndex].ext"
                        [width]="500" (click)="openImageDialog(propertyPhotoDetails[0].property_photo_id, property_id)">
                    }
                </div>

                <div class="gallery">
                    <button [disabled]="currentIndex === 0" class="prev"
                        (click)="prevImage()"><mat-icon>arrow_back_ios</mat-icon></button>
                    <div class="thumbnails-wrapper">
                        <div class="thumbnails" [ngStyle]="{'transform': 'translateX(' + thumbnailOffset + ')'}">
                            <ng-container *ngFor="let photo of propertyPhotoDetails; index as i">
                                <div [ngClass]="{ 'jk-img-active': i === currentIndex}" class="thumbnail"
                                    (click)="showImage(i)">
                                    <!-- <img [src]="photo.path + photo.name + '.' + photo.ext" alt="Thumbnail Image"> -->
                                    <img [src]="thumbnailUrl + photo.path + '/' + photo.name + '.' + photo.ext"
                                        [width]="screenInfo.screenSize !== 'xs' ? '115' : '60'" 
                                        alt="Thumbnail Image">
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <button [disabled]="currentIndex === propertyPhotoDetails.length - 1" class="next"
                        (click)="nextImage()"><mat-icon>arrow_forward_ios</mat-icon></button>
                </div>

            </div>
            }

            <div class="jk-vp-property-details-container">
                <div class="jk-vp-property-details">
                    <p>{{propertyDetails.property_description}}</p>

                    <!-- {{bed}} rooms available  -->
                    <div class="jk-vp-section-container">
                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Room Facilities</div>
                            <div class="jk-vp-section-body" *ngIf="currentRoomDetailsForDisplay">
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="person"></mat-icon>
                                    <label>No. of
                                        Guests:</label><span>{{this.currentRoomDetailsForDisplay.no_of_occupancy}}</span>
                                </div>
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="bed"></mat-icon>
                                    <label>No of Extra
                                        Beds:</label><span>{{this.currentRoomDetailsForDisplay.no_of_extra_bed}}</span>
                                </div>
                                <!-- <div class="jk-vp-data">
                                    <label>Price:</label><span>&#8377;
                                        {{this.currentRoomDetailsForDisplay.discounted_price}}</span>
                                </div> -->

                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="">airline_seat_recline_normal</mat-icon>
                                    <label>Washroom
                                        Type:</label><span>{{this.currentRoomDetailsForDisplay.washroom_type_id |
                                        washroomType}}</span>
                                </div>
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="water"></mat-icon>
                                    <label>Water
                                        Heater:</label><span>{{this.currentRoomDetailsForDisplay.water_heater |
                                        YesNo}}</span>
                                </div>
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="ac_unit"></mat-icon>
                                    <label>Ac:</label><span>{{this.currentRoomDetailsForDisplay.ac
                                        | YesNo }}</span>
                                </div>
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="tv"></mat-icon>
                                    <label>Tv:</label><span>{{this.currentRoomDetailsForDisplay.tv
                                        | YesNo}}</span>
                                </div>

                                <!-- </div>
                            <div class="jk-vp-section-body"> -->

                            </div>

                        </div>
                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Property Facilities</div>
                            <div class="jk-vp-section-body">

                                <!-- <div class="jk-vp-data"><label>Pet Owner:</label><span>Dog(s) and cat(s)</span></div> -->
                                <!-- <div class="jk-vp-data"><label>Property type:</label><span>Treehouse</span></div> -->
                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label="Example home icon"
                                        fontIcon="ramen_dining"></mat-icon><label>Complimentary Breakfast:</label><span>
                                        {{propertyDetails.breakfast
                                        | YesNo}}</span>
                                </div>
                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="wifi"></mat-icon><label>wifi:</label><span>
                                        {{propertyDetails.free_wifi
                                        | YesNo}}</span>
                                </div>
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="elevator"></mat-icon>
                                    <label>Lift:</label><span>{{propertyDetails.elevator
                                        | YesNo}}</span>
                                </div>
                                <!-- <div class="jk-vp-data"><label>Hair Dryer:</label><span>Yes</span></div> -->

                                <!-- </div>
                             <div class="jk-vp-section-body"> -->
                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="directions_car"></mat-icon>
                                    <label>Car
                                        Parking:</label><span>
                                        {{propertyDetails.car_parking | YesNo}}</span>
                                </div>

                                <!--mapping the all facilities-->

                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label="" fontIcon="local_bar"></mat-icon>
                                    <label>Bar:</label><span>
                                        {{propertyDetails.bar
                                        | YesNo}}</span>
                                </div>

                                <!-- <div class="jk-vp-data"><label>Cash Only:</label><span> {{propertyDetails.cash_only
                                        | YesNo}}</span>
                                </div> -->

                                <div class="jk-vp-data"> <mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="videocam"></mat-icon><label>Cctv:</label><span>
                                        {{propertyDetails.cctv
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data">
                                    <mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="coffee_maker"></mat-icon><label>Coffee/Tea Maker:</label><span>
                                        {{propertyDetails.coffee_tea_maker
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="accessible"></mat-icon><label>Disabled
                                        Friendly :</label><span>
                                        {{propertyDetails.disabled_friendly
                                        | YesNo}}</span>
                                </div>
                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="family_restroom"></mat-icon><label>Family /
                                        Child Friendly:</label><span>
                                        {{propertyDetails.family_child_friendly
                                        | YesNo}}</span>
                                </div>


                                <!-- <div class="jk-vp-data"><label>Free Cancellation:</label><span>
                                        {{propertyDetails.free_cancellation
                                        | YesNo}}</span>
                                </div> -->

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="child_care"></mat-icon><label>Free Kids
                                        Stay:</label><span>
                                        {{propertyDetails.free_kids_stay
                                        | YesNo}}</span>
                                </div>


                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="hot_tub"></mat-icon><label>Front
                                        Desk:</label><span>
                                        {{propertyDetails.front_desk
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="groups"></mat-icon><label>Group
                                        Accepted:</label><span>
                                        {{propertyDetails.group_accepted
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="restaurant_menu"></mat-icon><label>Kitchen:</label><span>
                                        {{propertyDetails.kitchen
                                        | YesNo}}</span>
                                </div>
                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="cleaning_services"></mat-icon><label>Daily House
                                        Keeping:</label><span>
                                        {{propertyDetails.daily_house_keeping
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="landscape"></mat-icon><label>Mountain
                                        View:</label><span>
                                        {{propertyDetails.mountain_view
                                        | YesNo}}</span>
                                </div>

                                <!-- <div class="jk-vp-data"><label>Pay at Hotel:</label><span>
                                        {{propertyDetails.pay_at_hotel
                                        | YesNo}}</span>
                                </div> -->

                                <!-- <div class="jk-vp-data"><label>Pay Now:</label><span>
                                        {{propertyDetails.pay_now
                                        | YesNo}}</span>
                                </div> -->

                                <!-- <div class="jk-vp-data"><label>Pay with Credit Card:</label><span>
                                        {{propertyDetails.pay_with_creditcard
                                        | YesNo}}</span>
                                </div> -->

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="power"></mat-icon><label>Power
                                        Backup:</label><span>
                                        {{propertyDetails.power_backup
                                        | YesNo}}</span>
                                </div>


                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="water"></mat-icon><label>Private
                                        Falls:</label><span>
                                        {{propertyDetails.private_falls
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="kitchen"></mat-icon><label>Refrigerator:</label><span>
                                        {{propertyDetails.refrigerator
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="restaurant"></mat-icon><label>Restaurant:</label><span>
                                        {{propertyDetails.restaurant
                                        | YesNo}}</span>
                                </div>

                                <!-- <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="ramen_dining"></mat-icon><label>Free
                                        Breakfast:</label><span>
                                        {{propertyDetails.breakfast
                                        | YesNo}}</span>
                                </div> -->

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="room_service"></mat-icon><label>Room
                                        Service:</label><span>
                                        {{propertyDetails.room_service
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data">
                                    @if(propertyDetails.smoke_free==1){

                                    <mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="smoking_rooms"></mat-icon><label>Smoke
                                        Free:</label><span>
                                        {{propertyDetails.smoke_free
                                        | YesNo}}</span>
                                    }@else{
                                    <mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="smoke_free"></mat-icon><label>Smoke
                                        Free:</label><span>
                                        {{propertyDetails.smoke_free
                                        | YesNo}}</span>
                                    }
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="spa"></mat-icon><label>Spa:</label><span>
                                        {{propertyDetails.spa
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false"
                                        aria-label="">balcony</mat-icon><label>Terrace:</label><span>
                                        {{propertyDetails.terrace
                                        | YesNo}}</span>
                                </div>

                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="pool"></mat-icon><label>Swimming Pool:</label><span>
                                        {{propertyDetails.swimming_pool
                                        | YesNo}}</span>
                                </div>



                                <!-- <div class="jk-vp-data"><label>Property Name:</label><span>
                                        {{propertyDetails.property_name
                                        }}</span>
                                </div> -->


                                <!-- <div class="jk-vp-data"><label>Longitude:</label><span>
                                        {{propertyDetails.longitude
                                        }}</span>
                                </div>

                                <div class="jk-vp-data"><span
                                        class="material-icons">place</span><label>Latitude:</label><span>
                                        {{propertyDetails.latitude
                                        }}</span>
                                </div> -->

                                <!-- <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="location_city"></mat-icon><label>Landmark:</label><span>
                                        {{propertyDetails.landmark
                                        }}</span>
                                </div>
                                <div class="jk-vp-data"><mat-icon aria-hidden="false" aria-label=""
                                        fontIcon="my_location"></mat-icon><label>City:</label><span>
                                        {{propertyDetails.city
                                        }}</span>
                                </div> -->
                                <!-- <div class="jk-vp-data"><label>Address:</label><span>
                                        {{propertyDetails.address_line1
                                        }}</span>
                                </div> -->

                                <!-- <div class="jk-vp-data"><label>Safety Box:</label><span>Yes</span></div> -->
                                <!-- <div class="jk-vp-data"><label>Television:</label><span>Yes</span></div> -->
                                <!-- <div class="jk-vp-data"><label>Room Type:</label><span>{{bed_type.bed_type_id }} -
                                        current cateogory</span></div> -->
                            </div>


                        </div>

                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Price</div>
                            <div class="jk-vp-section-body">
                                <div class="jk-vp-data">
                                    <span>
                                        @if(currentRoomDetailsForDisplay.price){
                                        <div class="jk-price-strike">&#8377;{{ currentRoomDetailsForDisplay.price/100
                                            }}
                                            / Day
                                        </div>
                                        }
                                        &#8377;{{currentRoomDetailsForDisplay.discounted_price/100}}
                                        / Day
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">CheckIn/ Checkout Policies</div>
                            <div class="jk-vp-section-body">
                                <div class="jk-vp-data">
                                    <label>Check In:</label><span>{{propertyDetails.checkin_from | AmPm}} to
                                        {{(propertyDetails.checkin_until==24)?'11:59 PM' : propertyDetails.checkin_until
                                        | AmPm}}</span>
                                </div>

                                <div class="jk-vp-data">
                                    <label>Check Out Until:</label><span>{{propertyDetails.checkout_until | AmPm
                                        }}</span>
                                </div>

                            </div>
                        </div>
                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Cancellation Policy</div>
                            <div class="jk-vp-section-body">
                                <div class="jk-vp-data">
                                    Please Refer <span class="jk-cancellation-link"><a
                                            href="/new-user/booking-policy#cancellation-policy"
                                            target="_blank">here</a></span>
                                </div>
                            </div>
                        </div>

                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Location</div>
                            <div class="jk-vp-section-body">
                                <div class="jk-vp-data">
                                    <label>City:</label><span>{{propertyDetails.city}}</span>
                                </div>
                                @if(propertyDetails.landmark){
                                <div class="jk-vp-data">
                                    <label>Landmark:</label><span>{{propertyDetails.landmark}}</span>
                                </div>
                                }

                            </div>
                        </div>
                        <!-- <h1><a href="user/booking-policy/#section1">aaaa</a></h1> -->

                        <!-- <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Safety features</div>
                            <div class="jk-vp-section-body">
                                <div class="jk-vp-data">Smoke detector</div>
                                <div class="jk-vp-data">Carbon monoxide detector</div>

                            </div>
                        </div> -->

                        <div class="jk-vp-section">
                            <div class="jk-vp-section-title">Address</div>
                            <div class="jk-vp-section-body">
                                @if(propertyDetails.address_line1){
                                <div class="jk-vp-data">
                                    {{propertyDetails.address_line1}} {{propertyDetails.address_line2}}
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="jk-vp-select-button">
                <!-- <button mat-raised-button color="primary" (click)="showExtraBedConfirmationDialog()" -->
                <button mat-raised-button color="primary" (click)="addRoomDetailsToCart()"
                    [disabled]="checkIfRoomsNotAvailable()">Select this Room</button>
                <div class="jk-available-rooms-caption"> {{getAvailableRoomCountForBedType() > 0 ?
                    getAvailableRoomCountForBedType() : "No" }} Room(s) Available</div>
            </div>
        </mat-tab>

        }


    </mat-tab-group>
    }
    <!-- <app-related-properties></app-related-properties> -->
</div>
}


@if(screenInfo.screenSize !== 'xs'){

@if(selectedRooms.length){
<div class="jk-cart">
    <!-- When you add any input property here, we need to add the same in cart-bottom-sheet.component.ts to work in mobile verison-->
    <app-cart [selectedRoomDetails]="selectedRooms" [property_id]="property_id" [searchValuesArr]="searchValuesArr"
        (updateRoomSelections)="updateRoomSelections($event)"></app-cart>

</div>
}
}@else{
<mat-toolbar class="jk-search-footer-toolbar  mat-elevation-z6" [ngClass]="screenInfo.cssClass">
    <div class="jk-search-footer-menus">

        <div class="jk-lo-widget">
            <a [routerLink]="['/landing']">
                <mat-icon>dashboard</mat-icon>
            </a>
        </div>

        <div class="jk-lo-widget">
            <a [routerLink]="['/user/search']" routerLinkActive="jk-active-menu">
                <mat-icon>person_search</mat-icon>
            </a>
        </div>

        <div class="jk-lo-widget">
            @if(totalNoOfRoomsSelected > 0){
            <mat-icon aria-hidden="false" matBadge="{{totalNoOfRoomsSelected}}" matBadgeColor="accent"
                (click)="openBottomSheet(property_id)">shopping_cart</mat-icon>
            }@else {
            <mat-icon matBadge="{{totalNoOfRoomsSelected}}" matBadgeColor="accent">shopping_cart</mat-icon>
            }

        </div>

        <div class="jk-lo-widget" [mat-menu-trigger-for]="userMenuXs" mat-icon-button class="">
            <mat-icon>person</mat-icon>
        </div>
    </div>

    <mat-menu #userMenuXs="matMenu" x-position="before">

        @if(token){
        <a mat-menu-item [routerLink]="['/user/view-my-profile']">
            <mat-icon>person_pin</mat-icon>
            {{'USER.VIEW_PROFILE' | translate }}
        </a>
        <a mat-menu-item [routerLink]="['/user/booking-details']">
            <mat-icon>history</mat-icon>
            {{'BOOKING_HISTORY' | translate }}
        </a>
        <a mat-menu-item [routerLink]="['/new-user/logout']">
            <mat-icon>exit_to_app</mat-icon>
            {{'SIGN_OUT' | translate }}
        </a>
        }@else{
        <a mat-menu-item [routerLink]="['/new-user/signup']">
            <mat-icon>person_add</mat-icon>
            {{'SIGNUP' | translate }}
        </a>

        <a mat-menu-item [routerLink]="['/new-user/login']">
            <mat-icon>login</mat-icon>
            {{'LOGIN' | translate }}
        </a>
        }

        <!-- <button (click)="changePassword()" mat-menu-item *ngxPermissionsOnly="['CHANGE_PASSWORD']">
            <mat-icon>lock_open</mat-icon>
            {{'CHANGE_PASSWORD' | translate }}
        </button>
        <button (click)="editProfile()" mat-menu-item *ngxPermissionsOnly="['EDIT_PROFILE']">
            <mat-icon>edit</mat-icon>
            {{'EDIT_PROFILE' | translate }}
        </button> -->
    </mat-menu>

</mat-toolbar>
}

@if(showSpinner){

<span class="jk-loading-text"> Loading Property Details</span>
<app-spinner></app-spinner>
}

