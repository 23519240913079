import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../../services/master-data.service';


@Pipe({
    name: 'AmPm',
    standalone: true
})
export class AmPmPipe implements PipeTransform {

    constructor(private masterDataService: MasterDataService) { }


    transform(value: number): string {

        if (value <= 12) {
            var timePeriod = value + "AM"
           
        } else {
            value = value % 12;
            var timePeriod = value + "PM"

        }

        return( timePeriod);

    }

}
