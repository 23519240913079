import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [RouterLink,CommonModule,MatIconModule,ClipboardModule],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {
  public email = 'support@courtallamrooms.com';
  showIcon = false;
  copyText() {
    const textToCopy = 'support@courtallamrooms.com';
    if (!navigator.clipboard) {
      // Fallback for browsers that don't support Clipboard API
    } else {
      navigator.clipboard.writeText(textToCopy).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    }
  }
}
