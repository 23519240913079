<div class="jk-app mat-typography">
    <!-- @if(!environment.production){
    <div class="jk-env jk-env-{{environment.envName}}"> {{ environment.envName }}</div>
    } -->
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <a class="jk-logo-link" [routerLink]="['/landing']">
                <div class="jk-logo-container">

                    <div class="jk-logo">

                    </div>

                    <div class="jk-logo-sub">COURTALLAM ROOMS</div>

                </div>
            </a>
            <span class="jk-spacer"></span>
            <a [routerLink]="['/new-user/signup']">
                <mat-icon color="secondary">person_add</mat-icon>
                {{'SIGNUP' | translate }}
            </a>

            <a [routerLink]="['/new-user/login']">
                <mat-icon>login</mat-icon>
                {{'LOGIN' | translate }}
            </a>

        </mat-toolbar-row>
    </mat-toolbar>


    <div class="jk-layout-body-container">
        <router-outlet></router-outlet>
    </div>
    <footer class="jk-lp-footer" color="primary">
        <ul>
            <li><a [routerLink]="['/new-user/about-us']">About Us</a></li>
            <li><a [routerLink]="['/new-user/privacy-policy']">Privacy Policy</a></li>
            <li><a [routerLink]="['/new-user/booking-policy']">Booking Policy</a></li>
            <li><a href="/new-user/booking-policy#cancellation-policy">Cancellation Policy</a></li>
            <li><a [routerLink]="['/new-user/terms-conditions']">Terms & Conditions</a></li>
            <!-- <li><a [routerLink]="['/new-user/faqs']">FAQs</a></li> -->
            <li><a [routerLink]="['/new-user/contact-us']">Contact Us</a></li>
        </ul>
        <div class="jk-lp-footer-sub">
            <div class="jk-lp-cr">&copy; 2024 CourtallamRooms.com, All rights reserved.</div>
            <div class="jk-lp-cro">CourtallamRooms.com is part of Thamira Technologies LLP.</div>
        </div>
    </footer>
</div>