<mat-toolbar class="jk-main-header">
    <div class="jk-branding">
        <div class="jk-logo"></div>
    </div>
    <div>
        <span>{{data.title || ''}}</span>
    </div>
    <div fxFlex></div>
</mat-toolbar>
<mat-dialog-content>
    <!-- @if(data.bedTypeId){
        <div *ngIf="!data.isHtml">You are removing  {{ data.bedTypeId | bedType}} room!.</div>
        } -->
    <div *ngIf="!data.isHtml">{{ data.message }}</div>
    <div *ngIf="data.isHtml" class="" [innerHtml]="data.message | safeHtml"> </div>

   
    <!-- <mat-checkbox *ngIf="data.showCheckbox" [(ngModel)]="extraBedChecked">Extra Bed</mat-checkbox> -->

    <!-- @if(extraBedChecked){



    <div class="jk-button-container jk-adult-container">
        <div class="jk-increment-btn">-
        </div>
        <input type="text" matInput [(ngModel)]="data.countOfExtraBed" readonly>
        <div class="jk-increment-btn">+
        </div>
    </div>




    } -->

</mat-dialog-content>
<mat-dialog-actions>
    <span fxFlex></span>
    <button mat-raised-button color="default" (click)="cancel()" mat-dialog-close>
        {{ 'DIALOG_CANCEL_BTN' | translate }}
    </button>

    <button mat-raised-button color="primary" (click)="confirm()" mat-dialog-close>
        {{ 'DIALOG_CONFIRM_BTN' | translate}}
    </button>

</mat-dialog-actions>