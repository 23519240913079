import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PropertyService } from '../../shared/services/property.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterOutlet,
    MatIconModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    HttpClientModule,
    MatToolbarModule,
    MatSlideToggleModule,
    TranslateModule,
    SpinnerComponent,],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss'
})
export class RegistrationComponent {
  public hidePassword = true;
  public hideConfirmPassword = true;
  // Login form will be dispalyed in a new page or as part of the cart window
  @Input() cartPage: boolean = false;
  // This will be trigged only if the registration form is called from the cart. Event handler is added int the cart component
  @Output() userRegistered = new EventEmitter<boolean>();
  public registrationFormGroup!: FormGroup;
  public showButton:boolean=true;
  public showSpinner: boolean = false;
  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private readonly translate: TranslateService,
    private router: Router
  ) {

    this.setFormFields();
  }


  setFormFields() {
    this.registrationFormGroup = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email]],
      confirm_password: ['', [Validators.required, Validators.minLength(8), this.confirmPasswordValidator()]],
      mobile_no: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('[1-9][0-9]{9}')]],
      is_whats_app: [false],
      is_telegram: [false],
    })
  }

//   ngOnChanges(changes: SimpleChanges) {
// if(this.registrationFormGroup.dirty){
//   this.showButton=true;
// }
//     // only run when property "data" changed
//     // if (changes[' this.registrationFormGroup.value']) {
      

     
//     // }

//   }

 
  ngOnInit() {
  this.registrationFormGroup.valueChanges.subscribe(() => {
    // Check if any field in the form is dirty
    this.showButton = this.registrationFormGroup.dirty;
  });

  }
  onSubmit() {
    this.showButton=false;
    this.showSpinner=true;
    this.propertyService.saveRegistration(this.registrationFormGroup.value).subscribe(result => {
      // If registration page is triggered from cart, then inform (emit) the cart that registration is completed
      if (this.cartPage) {
        this.userRegistered.emit(true);
      } else {
        this.showSpinner=false;
        this.router.navigate(['new-user/login/otp/verify/', result.data.verification_id])
      }
    }, error => {
      this.showSpinner = false;
  });

  }
  confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const confirm_password = control.value;
      let password = null;
      if (this.registrationFormGroup) {
        password = this.registrationFormGroup.controls['password'].value;
      }
      if (confirm_password !== password) {
        return { confirmPasswordValidator: true };
      } else {
        return null;
      }
    };
  }
}
