@if(cancel){
<div class="jk-success-page-container">
    <div class="jk-success-tick">
        <img src="assets/images/success-tick.png">

    </div>
    <div class="jk-success-msg">
        <h1>Successfully Cancelled</h1>
    </div>
    <div class="jk-success-msg">
        <span (click)="openBookingHistoryPage()" class="mo-link"><a>Click here</a> to see the booking histroy</span>
    </div>
</div>
}@else{

<div class="jk-success-page-container">
    <div class="jk-success-tick">
        <img src="assets/images/success-tick.png">

    </div>
    <div class="jk-success-msg">
        <h1>Successfully Booked</h1>
    </div>
    <div class="jk-success-msg">
        <span (click)="openBookingHistoryPage()" class="mo-link"><a>Click here</a> to see the booking histroy</span>
    </div>
</div>
}