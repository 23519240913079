<!-- @if(domainName === 'courtallamrooms.com'){

<div class="jk-coming-soon"><img src="../../../assets/images/coming_soon.jpg" /></div>
}

@else{ -->
<!-- <div class="jk-env jk-env-{{environment.envName}}"> {{ environment.envName }}</div> -->
<div class="jk-landing-container" [ngClass]="screenInfo.cssClass">
    <section class="jk-ld-top-section">
        <div class="jk-ld-toolbar">
            <a class="jk-logo-link" [routerLink]="['/landing']">
                <div class="jk-logo-container">

                    <div class="jk-logo">

                    </div>

                    <div class="jk-logo-sub">COURTALLAM ROOMS</div>

                </div>
            </a>
            @if(screenInfo.screenSize !== 'xs'){
            <div class="jk-spacer"></div>
            <div class="jk-menu-items">
                @if(isUserLoggedIn){
                Welcome
                <button [mat-menu-trigger-for]="userMenu" mat-icon-button class="jk-ml-xs">
                    <mat-icon>person</mat-icon>
                </button>
                <mat-menu #userMenu="matMenu" x-position="before">
                    <a mat-menu-item [routerLink]="['/user/view-my-profile']">
                        <mat-icon>person_pin</mat-icon>
                        {{'USER.VIEW_PROFILE' | translate }}
                    </a>
                    <a mat-menu-item [routerLink]="['/user/booking-details']">
                        <mat-icon>history</mat-icon>
                        {{'BOOKING_HISTORY' | translate }}
                    </a>
                    <a mat-menu-item [routerLink]="['/new-user/logout']">
                        <mat-icon>exit_to_app</mat-icon>
                        {{'SIGN_OUT' | translate }}
                    </a>

                </mat-menu>
                }@else{
                <button [mat-menu-trigger-for]="userMenu" mat-icon-button class="jk-ml-xs">
                    <mat-icon>person</mat-icon>
                </button>
                <mat-menu #userMenu="matMenu" x-position="before">
                    <a mat-menu-item [routerLink]="['/new-user/signup']">
                        <mat-icon color="secondary">person_add</mat-icon>
                        {{'SIGNUP' | translate }}
                    </a>

                    <a mat-menu-item [routerLink]="['/new-user/login']">
                        <mat-icon>login</mat-icon>
                        {{'LOGIN' | translate }}
                    </a>
                </mat-menu>
                <!-- <a [routerLink]="['/new-user/signup']">
                    <mat-icon color="secondary">person_add</mat-icon>
                    {{'SIGNUP' | translate }}
                </a>

                <a [routerLink]="['/new-user/login']">
                    <mat-icon>login</mat-icon>
                    {{'LOGIN' | translate }}
                </a> -->
                }
            </div>
            }
        </div>
        <div class="jk-ld-form-container">
            <app-search-form [landingPage]="true"></app-search-form>
        </div>
        <!-- <div class="jk-social-media-icons">

            <div class="jk-sm-icons">
                <div><img width="60" src="../../../assets/images/whatsapp-icon.png" /></div>
                <div><img width="60" src="../../../assets/images/telegram-icon.png" /></div>
            </div>
            <div> Join Our Social Media Groups to get season updates</div>

        </div> -->
    </section>
    <section class="jk-why-cr">
        <div class="jk-why-cr-title">
            Why Courtallam Rooms?
        </div>
        <div class="jk-user-benefits">
            <div class="jk-user-benefit">
                <div class="jk-user-benefit-title">More Properties</div>
                <div>CourtallamRooms is the only website which has linked more properties in courtallam than any other
                    website. </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-user-benefit-title">Value for Money</div>
                <div>CourtallamRooms offer better price for various season. </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-user-benefit-title">Dedicated Customer Support</div>
                <div>CourtallamRooms have their own customer care office in courtallam during season time (Jun to Aug).
                    So you wont need to deal everything over phone. </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-user-benefit-title">Discounts</div>
                <div>CourtallamRooms have partnered with many restaurants and shops to offer their products with
                    discounted prices for our customers.</div>
            </div>
        </div>
    </section>

    <section class="jk-why-cr">
        <div class="jk-why-cr-title">
            Top Five Falls
        </div>
        <div class="jk-user-benefits">
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/main-falls.jpg" />
                    <div class="jk-user-benefit-title">Courtallam Main Falls</div>
                </div>

                <div>Nestled in the foothills of Agasthyamalai in the Tenkasi district of Tamil Nadu, Courtallam is not
                    merely a destination; it's a captivating tale woven by the Western Ghats. Often hailed as the 'Spa
                    of South India' this small town has earned its accolades for being a sanctuary of untouched natural
                    beauty. Set at an elevation of 160 meters, Courtallam stands as a testament to the unspoiled charm
                    of the Western Ghats.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/five-falls.jpg" />
                    <div class="jk-user-benefit-title">Five Falls</div>
                </div>

                <div>Nestled amidst the lush greenery of the Western Ghats in Tamil Nadu, India, Five Falls is a
                    captivating series of waterfalls that enchants visitors with its scenic beauty and serene ambiance.
                    Located near the town of Courtallam, Five Falls is a popular destination for nature lovers and
                    adventure enthusiasts seeking an escape into the heart of the wilderness.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/old-courtallam-falls.jpg" />
                    <div class="jk-user-benefit-title">Old Courtallam Falls</div>
                </div>

                <div>Nestled amidst the verdant landscapes of the Western Ghats in Tamil Nadu, India, Old Courtallam
                    Falls stands as a testament to the timeless beauty of nature. Tucked away in the serene town of
                    Courtallam, this enchanting waterfall exudes a charm and allure that captivates visitors from far
                    and wide. <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/tiger_falls.jpg" />
                    <div class="jk-user-benefit-title">Tiger Falls</div>
                </div>

                <div>Nestled within the verdant landscapes of Courtallam, Tiger Falls stands as a testament to the raw
                    beauty and untamed grace of nature. Unlike the bustling waterfalls and serene dams in the region,
                    Tiger Falls offers a unique spectacle, captivating visitors with its powerful cascade and rugged
                    surroundings.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/gundaru.jpg" />
                    <div class="jk-user-benefit-title">Gundaru Dam</div>
                </div>

                <div>Tucked away in the picturesque town of Courtallam, Gundaru Dam stands as a silent guardian,
                    embracing the region with its serene waters and breathtaking landscapes. Unlike the cascading
                    waterfalls that Courtallam is renowned for, Gundaru Dam offers a unique and tranquil experience,
                    combining the beauty of a reservoir with the allure of its natural surroundings.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
        </div>
    </section>

    <section class="jk-why-cr">
        <div class="jk-why-cr-title">
            Top Five Temples
        </div>
        <div class="jk-user-benefits">
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/kasi-viswanathar-temple.jpg" />
                    <div class="jk-user-benefit-title">Kasi Vishwanthar Temple</div>
                </div>

                <div>Located at the heart of Tenkasi, the Kasi Vishwanathar Temple stands as a timeless testament to the
                    spiritual and architectural heritage of Tamil Nadu. Dedicated to Lord Shiva, this sacred abode
                    exudes an aura of divine tranquility, drawing pilgrims and admirers alike.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/kumarar-kovil.jpg" />
                    <div class="jk-user-benefit-title"> Shri Ilanji Kumarar Temple</div>
                </div>

                <div>The Shri Ilanji Kumarar Temple, located near the enchanting town of Courtallam in Tamil Nadu,
                    stands as a venerable testament to the rich cultural and historical heritage of the region. This
                    ancient temple, dedicated to Lord Murugan, is steeped in mythology and history, attracting devotees
                    and history enthusiasts alike.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/thirukutranathar.png" />
                    <div class="jk-user-benefit-title">Thirukutralanathar Temple</div>
                </div>

                <div>The Kutralanathar Temple, dedicated to Lord Shiva, is located in the Thirukoodamalai hills of the
                    Western Ghats. Legend says Sage Agasthiar turned a Vishnu temple into a Shiva temple, changing the
                    deities Sridevi and Bhudevi into Kuzhalvai Mozhi Nayaki and Parasakthi. Parasakthi's altar, called
                    Dharani Peetam, represents her transformation from Bhudevi. Sage Agasthiar's fingerprints are
                    believed to be on the Lingam, and Lord Kutralanathar is given a special herbal mixture daily.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/thirumlai.jpg" />
                    <div class="jk-user-benefit-title">Thirumalaikumarasamy Temple</div>
                </div>

                <div>Perched at top the serene landscapes of Panpozhi, the Thirumalaikumarasamy Temple stands as a
                    beacon
                    of spirituality and architectural marvel. Dedicated to Lord Murugan, locally known as 'Thirumalai
                    Kumarasamy' or 'Thirumalai Murugan,' this sacred abode exudes a tranquil ambiance, welcoming
                    devotees and seekers of divine solace, especially those born under the Vishaka star, for whom this
                    temple holds special significance.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/chitra-saba.jpg" />
                    <div class="jk-user-benefit-title">Chithira Sabai Shivan Temple</div>
                </div>

                <div>The Chithira Sabai Shivan Temple in Courtallam, Tamil Nadu, is a sacred abode dedicated to Lord
                    Shiva, one of the principal deities in Hinduism. Nestled amid the serene landscapes of Courtallam,
                    this temple holds deep religious significance and cultural heritage, drawing devotees and tourists
                    alike.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>

        </div>
    </section>


    <section class="jk-why-cr">
        <div class="jk-why-cr-title">
            Top Five Restaurants
        </div>
        <div class="jk-user-benefits">
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/restaurants/maduram.jpg" />
                    <div class="jk-user-benefit-title">Sri Madhuram Veg Restaurant
                        <div class="jk-ub-sub-title"><span class="">Veg</span></div>
                    </div>
                </div>

                <div>Sri Madhuram Veg Restaurant in Tenkasi invites diners to embark on a gastronomical journey where
                    the
                    essence of South Indian vegetarian cuisine takes center stage. Situated in the heart of Tenkasi,
                    this restaurant serves as a testament to culinary excellence and a commitment to delivering a
                    delightful dining experience.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/restaurants/drizzle.jpg" />
                    <div class="jk-user-benefit-title">Drizzle Restaurant<div class="jk-ub-sub-title jk-food-nv">
                            <span>Non Veg</span>
                        </div>
                    </div>
                </div>

                <div>At Drizzle Restaurant on the Courtallam to Sengottai Road, pride is taken in offering more than
                    just a meal; it provides an immersive culinary journey. The menu features a tantalizing array of
                    dishes crafted with the finest ingredients, ensuring each bite is bursting with flavor. From
                    aromatic curries to sizzling tandoori delights, the chefs meticulously prepare each dish to
                    perfection, catering to discerning palates and culinary enthusiasts alike.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/restaurants/border.jpg" />
                    <div class="jk-user-benefit-title">Border Parrota Kadai<div class="jk-ub-sub-title  jk-food-nv">
                            <span class="jk-food-nv">Non Veg</span>
                        </div>
                    </div>
                </div>

                <div>Rahamath Parotta Stall in Courtallam is not just a dining spot; it's a culinary journey into the
                    heart of South Indian flavors. Situated amidst the picturesque surroundings of Courtallam, this
                    popular eatery beckons food enthusiasts to explore its diverse and mouthwatering menu.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/restaurants/hot-spot.jpg" />
                    <div class="jk-user-benefit-title">Hot Spot Restaurant <div class="jk-ub-sub-title jk-food-nv"><span
                                class="">Non Veg</span></div>
                    </div>
                </div>

                <div>Hot Spot Restaurant in Tenkasi is a great place for food lovers. They offer a mix of tasty dishes
                    that suit different tastes. From delicious main courses to sweet desserts, Hot Spot makes sure your
                    meal is a treat for your senses.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>

            <div class="jk-user-benefit">
                <div class="jk-ub-image-container">
                    <img src="../../../assets/images/restaurants/nila.jpg" />
                    <div class="jk-user-benefit-title">Nila Family Restaurant <div class="jk-ub-sub-title jk-food-nv">
                            <span> Non Veg</span>
                        </div>
                    </div>
                </div>

                <div>Nila Family Restaurant in Tenkasi is your go-to destination for a delightful dining experience.
                    With a diverse menu featuring a range of delectable dishes, this restaurant caters to a variety of
                    tastes and preferences.
                    <!-- <a href="">Read More...</a>  -->
                </div>
            </div>
        </div>
    </section>

    <section>
        <footer class="jk-lp-footer" color="primary">
            <ul>
                <li><a [routerLink]="['/new-user/about-us']">About Us</a></li>
                <li><a [routerLink]="['/new-user/privacy-policy']">Privacy Policy</a></li>
                <li><a [routerLink]="['/new-user/booking-policy']">Booking Policy</a></li>
                <li><a href="/new-user/booking-policy#cancellation-policy">Cancellation Policy</a></li>
                <li><a [routerLink]="['/new-user/terms-conditions']">Terms & Conditions</a></li>
                <!-- <li><a [routerLink]="['/new-user/faqs']">FAQs</a></li> -->
                <li><a [routerLink]="['/new-user/contact-us']">Contact Us</a></li>
            </ul>
            <div class="jk-lp-footer-sub">
                <div class="jk-lp-cr">&copy; 2024 CourtallamRooms.com, All rights reserved.</div>
                <div class="jk-lp-cro">CourtallamRooms.com is part of Thamira Technologies LLP.</div>
            </div>
        </footer>
    </section>
    <mat-toolbar class="jk-search-footer-toolbar  mat-elevation-z6" *ngIf="screenInfo.screenSize === 'xs'">


        <div class="jk-search-footer-menus">

            <div class="jk-lo-widget">
                <a [routerLink]="['/landing']">
                    <mat-icon>dashboard</mat-icon>
                </a>
            </div>

            <div class="jk-lo-widget">

            </div>

            <div class="jk-lo-widget" [mat-menu-trigger-for]="propertyMenuXs" mat-icon-button class="ml-xs">
                <mat-icon>person</mat-icon>
            </div>
        </div>

        <mat-menu #propertyMenuXs="matMenu" x-position="before">
            @if(token){
            <a mat-menu-item [routerLink]="['/user/view-my-profile']">
                <mat-icon>person_pin</mat-icon>
                {{'USER.VIEW_PROFILE' | translate }}
            </a>
            <a mat-menu-item [routerLink]="['/user/booking-details']">
                <mat-icon>history</mat-icon>
                {{'BOOKING_HISTORY' | translate }}
            </a>
            <a mat-menu-item [routerLink]="['/new-user/logout']">
                <mat-icon>exit_to_app</mat-icon>
                {{'SIGN_OUT' | translate }}
            </a>
            }@else{
            <a mat-menu-item [routerLink]="['/new-user/signup']">
                <mat-icon>person_add</mat-icon>
                {{'SIGNUP' | translate }}
            </a>

            <a mat-menu-item [routerLink]="['/new-user/login']">
                <mat-icon>login</mat-icon>
                {{'LOGIN' | translate }}
            </a>
            }
        </mat-menu>

    </mat-toolbar>
</div>
<!-- } -->