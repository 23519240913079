
export const environment = {
    production: false,
    baseHref: '/',
    envName: 'Development',
    domain: 'https://dev.kutralamrooms.in/',
    apiUrl: 'https://api.dev.kutralamrooms.in/api/',
    imageUrl: 'https://api.dev.kutralamrooms.in/storage/images/',
};

