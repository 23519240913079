import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SafeHtmlPipe } from '../../shared/safe-html.pipe';
import { ViewPropertyComponent } from '../view-property/view-property.component';

@Component({
  selector: 'app-extra-bed',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatDialogActions, MatDialogContent, SafeHtmlPipe, MatButtonModule, TranslateModule, MatDialogClose, MatRadioModule, FormsModule, MatInputModule],
  templateUrl: './extra-bed.component.html',
  styleUrl: './extra-bed.component.scss'
})
export class ExtraBedComponent {
  @Output() confirmAction = new EventEmitter<boolean>();
  public extra_bed: string = 'no';
  public ac: string = 'no';
  // public extraBedChecked: boolean = false;
  public extraBed: number = 1;
  public noOfAc: number = 1;
  public price_ac: number = 200;
  public extra_bed_price: number = 200;
  public total_amount!: number
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewPropertyComponent>) {

  }

  ngOnInit() {

  }
  //   decrementAdultCount(i: number) {
  //     let currentValue = +this..get('adult_count')?.value;
  //     if (currentValue >= 1) {
  //         if (currentValue !== 1) {
  //             currentValue -= 1;
  //         }
  //         this.getFormGroupOfRoomDetailsFormArray(i).get('adult_count')?.setValue(currentValue);
  //         this.countOfRoomDetails();
  //     }
  // }

  decrementBedCount() {
    let currentValue = +this.extraBed;
    if (currentValue >= 1) {
      if (currentValue !== 1) {
        currentValue -= 1;
      }
      this.extraBed = currentValue;
      this.extra_bed_price = this.extraBed * 200;

    }
  }


  incrementBedCount() {
    let currentValue = +this.extraBed;
    if (currentValue < this.data.countOfExtraBed) {
      currentValue += 1;
      this.extraBed = currentValue;
      this.extra_bed_price = this.extraBed * 200;

    }
  }


  decrementAcCount() {
    let currentValue = +this.noOfAc;
    if (currentValue >= 1) {
      if (currentValue !== 1) {
        currentValue -= 1;
      }
      this.noOfAc = currentValue;
      this.price_ac = this.noOfAc * 200;

    }
  }

  incrementAcCount() {
    let currentValue = +this.noOfAc;
    if (currentValue < this.data.countOfExtraBed) {
      currentValue += 1;
      this.noOfAc = currentValue;
      this.price_ac = this.noOfAc * 200;

    }
  }

  // totalAmount() {
  //   if (this.extra_bed == "yes" && this.ac == "yes") {
  //     this.total_amount = this.price_ac + this.extra_bed_price
  //   } else if (this.extra_bed == "yes") {
  //     this.total_amount = this.extra_bed_price;
  //   } else if(this.ac== "yes") {
  //     this.total_amount = this.price_ac;
  //   }
  // }


  confirm(): void {
    if (this.extra_bed == "yes" && this.ac == "yes") {
      this.dialogRef.close({
        noExtraBed: this.extraBed,
        extraBedPrice: this.extra_bed_price,
        noOfAc: this.noOfAc,
        acPrice: this.price_ac,


      })
    } else if (this.ac == "yes") {
      this.dialogRef.close({
        noOfAc: this.noOfAc,
        acPrice: this.price_ac,
      })
    } else if (this.extra_bed == "yes") {
      this.dialogRef.close({

        noExtraBed: this.extraBed,
        extraBedPrice: this.extra_bed_price,

      })
    }
  }
  cancel(): void {
    this.dialogRef.close()
  }

}
