<div class="jk-form-container">

    @if(this.bookingDetailsForRooms){
    <h1>{{propertyName}}</h1>
    <div class="jk-form-field-container">
        <mat-card>
            <mat-card-actions>
                <div class="jk-card-table">
                    <div class="jk-card-content">Booking Id</div>
                    <div class="jk-card-content"> From Date</div>
                    <div class="jk-card-content">To Date</div>
                    <div class="jk-card-content">Total Amount Received</div>
                    <div class="jk-card-content">Booked on</div>
                    @if(showCancelAllButton ){
                    <div class="jk-card-content">Action</div>
                    }
                </div>
            </mat-card-actions>
            <mat-card-actions>

                <div class="jk-card-values">

                    <span class="jk-card-content">{{this.masterBookingId}}</span>
                    <span class="jk-card-content">{{this.fromDate}} </span>
                    <span class="jk-card-content">{{this.toDate}}</span>
                    <span class="jk-card-content">&#8377; {{this.totalAmountReceived/100}}</span>
                    <span class="jk-card-content">{{this.bookedOn}}</span>
                    <!-- @if(showCancel){ -->
                    <span class="jk-card-content">
                        @if(showCancelAllButton){
                        @if(disableCancelAllButton){
                        Cancelled
                        }@else{
                        <button mat-raised-button color="primary" [disabled]="!showButton"
                            (click)="openCancelAllRoomDialogBox()">Cancel All
                            Rooms<app-spinner *ngIf="showButtonSpinner"></app-spinner></button>
                        }
                        }
                        <!-- <button mat-raised-button color="primary" (click)="openCancelAllRoomDialogBox()"
                            [disabled]="disableCancelAllButton">Cancel All
                            Rooms</button> -->

                    </span>
                    <!-- } -->
                    <!-- [disabled]="!allRoomsCancelled" -->
                </div>

            </mat-card-actions>

        </mat-card>
    </div>

    <div>
        <h2>Room Details</h2>
        <div *ngFor="let bookingDetailsForRoom of bookingDetailsForRooms; let i = index; ">
            <!-- @for(bookingDetailsForRoom of bookingDetailsForRooms;let i = index; track bookingDetailsForRooms){ -->
            <mat-card>
                <mat-card-actions>
                    <div class="jk-card-table">
                        <div class="jk-card-content">S.No.</div>
                        <div class="jk-card-content"> Room Type</div>
                        <div class="jk-card-content">Adult</div>
                        <div class="jk-card-content">Child</div>
                        <!-- <div class="jk-card-content">Extra Bed</div> -->
                        <div class="jk-card-content">Price</div>
                        @if(bookingDetailsForRoom.checkin_at==null && bookingDetailsForRoom.checkout_at==null ){
                        <!-- @if(bookingDetailsForRoom.is_cancel_allowed){ -->
                        <div class="jk-card-content">Action</div>
                        <!-- }@else{
                        <div class="jk-card-content">Action</div> -->
                        }
                    </div>
                </mat-card-actions>
                <mat-card-actions>

                    <div class="jk-card-values">

                        <div>
                            <span class="jk-card-content">{{ i + 1}}</span>
                            <span class="jk-card-content"> {{bookingDetailsForRoom.bed_type}}</span>
                            <span class="jk-card-content">{{bookingDetailsForRoom.no_of_adult}}</span>
                            <span class="jk-card-content">{{bookingDetailsForRoom.no_of_children}}</span>
                            <!-- <span class="jk-card-content">{{bookingDetailsForRoom.no_of_extra_bed}}</span> -->
                            <span class="jk-card-content">&#8377; {{bookingDetailsForRoom.room_price/100}}/day</span>
                            @if(bookingDetailsForRoom.checkin_at==null && bookingDetailsForRoom.checkout_at==null ){
                            <span class="jk-card-content">
                                @if(bookingDetailsForRoom.is_cancel_allowed){
                                <button mat-raised-button color="primary" [disabled]="!showButton"
                                    (click)="openCancelThisRoomDialogBox(bookingDetailsForRoom.booking_id)">Cancel This
                                    Room<app-spinner *ngIf="showButtonSpinner"></app-spinner></button>
                                }@else{
                                Cancelled
                                }
                            </span>
                            }
                        </div>

                    </div>

                </mat-card-actions>

            </mat-card>
            <!-- } -->
        </div>
    </div>
    <div class="jk-back-button">
        <a [routerLink]="['/user/booking-details']"><button mat-raised-button color="primary">Back</button></a>
    </div>
    }

    @if(showSpinner){
    <div class="jk-spinner">
        <div class="jk-loading-text">Loading Data...</div>
        <app-spinner></app-spinner>
    </div>
    }


</div>