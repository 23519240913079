import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MasterDataService } from './shared/services/master-data.service';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'user';

  constructor(
    private router: Router,
    private masterDataService: MasterDataService
  ) {
    this.masterDataService.loadMasterData();

  }

  ngOnInit() {
    this.router.events.pipe(
      //filter(event => event instanceof NavigationEnd)
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Google analyticsds
      gtag('config', 'G-VRNSDMTTRM', {
        'page_path': event.urlAfterRedirects
      });
    });
  }
}
