import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IChildrenAge, ISearchDisplayValues, ISearchFieldsValues } from '../../property/property';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  get(key: string): string | null {
    if (this.isBrowser) {
      return localStorage.getItem(key);
    }
    return null;
  }

  set(key: string, value: string): void {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    }
  }
  clear(): void {
    if (this.isBrowser) {
      localStorage.clear();
    }
  }

  public getSearchValuesFromLocalStorage(): ISearchDisplayValues {
    const searchJsonStr = this.get('searchJson');
    let searchValuesArr: ISearchDisplayValues = {
      from_date: '',
      to_date: '',
      adult_count: 0, // total adult count for all the rooms
      children_count: 0, // total children count for all the rooms
      room_count: 0, // total children count for all the rooms
      occupancy: '', // This is comma seperated adult count for each room which will be used as params to get property details
      children_age: '',
    };
    if (searchJsonStr) {
      const searchJson: ISearchFieldsValues = JSON.parse(searchJsonStr);

      // // Regular expression to extract adult and children counts
      // const regex = /.*Adult (\d+)(, Children (\d+))?/;

      // // Extract adult and children counts using the regex
      // const matches = searchJson.room_details.match(regex);



      // // Check if matches were found and extract counts
      // if (matches) {
      //   searchValuesArr.adult_count = (matches[1]) ? parseInt(matches[1]) : 0;
      //   searchValuesArr.children_count = (matches[3]) ? parseInt(matches[3]) : 0;
      //   searchValuesArr.children_age = (matches[3]) ? parseInt(matches[3]) : 0;
      // }

      searchValuesArr.from_date = (typeof searchJson.from_date === 'object') ? searchJson.from_date.toISOString() : searchJson.from_date;
      searchValuesArr.to_date = (typeof searchJson.to_date === 'object') ? searchJson.to_date.toISOString() : searchJson.to_date;

      let occupancyCommonSeparated = '';
      let childrenCountCommonSeparated = '';
      let childrenAgeCommonSeparated = '';
      let adult_count = 0;
      let children_count = 0;
      let room_count = searchJson.roomDetailsFormArray.length;
      for (let i in searchJson.roomDetailsFormArray) {
        adult_count += searchJson.roomDetailsFormArray[i].adult_count;
        children_count += searchJson.roomDetailsFormArray[i].children_count;

        if (occupancyCommonSeparated === '') {
          occupancyCommonSeparated += searchJson.roomDetailsFormArray[i].adult_count;

        } else {
          occupancyCommonSeparated = occupancyCommonSeparated + ',' + searchJson.roomDetailsFormArray[i].adult_count;
        }

        if (childrenCountCommonSeparated === '') {
          childrenCountCommonSeparated += searchJson.roomDetailsFormArray[i].children_count;
        } else {
          childrenCountCommonSeparated = childrenCountCommonSeparated + ',' + searchJson.roomDetailsFormArray[i].children_count;
        }


        // if (childrenAgeCommonSeparated === '') {
        //   childrenAgeCommonSeparated += searchJson.roomDetailsFormArray[i].childrenAgeFormArray;
        // } else {
        //   childrenCountCommonSeparated = childrenCountCommonSeparated + ',' + searchJson.roomDetailsFormArray[i].childrenAgeFormArray;
        // }




        childrenAgeCommonSeparated += this.getChildrenAge(searchJson.roomDetailsFormArray[i].childrenAgeFormArray);
        childrenAgeCommonSeparated = childrenAgeCommonSeparated.substring(0);

        // if (childrenAge === '') {
        //   childrenAge += searchJson.roomDetailsFormArray[i].childrenAgeFormArray[i].age;
        // } else {
        //   childrenAge = childrenAge + ',' + searchJson.roomDetailsFormArray[i].childrenAgeFormArray[i].age;
        // }

      }
      searchValuesArr.adult_count = adult_count;
      searchValuesArr.children_count = children_count;
      searchValuesArr.room_count = room_count;
      searchValuesArr.occupancy = occupancyCommonSeparated;
      searchValuesArr.children_age = childrenAgeCommonSeparated;

    }
    return searchValuesArr;

  }

  getChildrenAge(childrenAgeFormArray: IChildrenAge[]) {
    let childrenAge = '';
    for (let i in childrenAgeFormArray) {

      childrenAge += childrenAgeFormArray[i].age + ',';

    }
    return childrenAge;

  }

  //   /* This function is used to get the room details to send as param to bookroom request when user clicks on pay button */
  //   public getRoomDetailsFromLocalStorage():IBrrRoomDetails[] {
  //     const searchJsonStr = this.get('searchJson');
  //     let searchJsonObj: ISearchFieldsValues;
  //     let roomDetailsArr: IBrrRoomDetails[] = [];
  //     let roomDetails!:IBrrRoomDetails;
  //     if (searchJsonStr) {
  //       searchJsonObj = JSON.parse(searchJsonStr);

  //       for (let i in searchJsonObj.roomDetailsFormArray) {

  //           roomDetails = {
  //             room_id: searchJsonObj.roomDetailsFormArray[i].adult_count,
  //             no_of_adult: searchJsonObj.roomDetailsFormArray[i].adult_count,
  //             no_of_children: searchJsonObj.roomDetailsFormArray[i].children_count,
  //             children_age: searchJsonObj.roomDetailsFormArray[i].childrenAgeFormArray.toString(),
  //             price: searchJsonObj.roomDetailsFormArray[i].adult_count
  //           }
  //           roomDetailsArr.push(roomDetails);
  //       }

  //     }
  //     return roomDetailsArr;
  //   }
}