<div class="jk-gbp-container">
    <div class="jk-gbp-header">
        <h1>CourtallamRooms Booking Policy </h1>
    </div>
    <div class="jk-gbp-booking-policy">
        <h1>General Booking Policy:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Travel Guidelines: Guests are required to adhere to all laws and guidelines applicable to their
                        destination, which may vary at different times of the year.</p>
                </li>
                <li>
                    <p>Booking Policies: Specific policies will be communicated to guests at the time of booking or
                        during
                        check-in.</p>
                </li>
                <li>
                    <p>Definitions: References to "CourtallamRooms" include its affiliates, employees, and officers.
                        "Hotel"
                        refers to the property where the booking was made through CourtallamRooms.</p>
                </li>
                <li>
                    <p> Customer Support: For assistance with new bookings or changes, CourtallamRooms offers
                        customer support from 9 AM to 9 PM during peak seasons and from 10 AM to 6 PM during off-peak
                        seasons. You can reach us at 74185 111 02, 74185 222 43.</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-check-in-policy">
        <h1>Check-in Policy:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Age Requirement: The primary guest must be at least 18 years old to check into the hotel.</p>
                </li>
                <li>
                    <p>Check-in Time: Standard check-in time is 12 PM to 2 PM, though flexibility may be available
                        depending
                        on
                        room
                        availability.</p>
                </li>
                <li>
                    <p>Identification: All guests must present valid photo identification at check-in. Acceptable IDs
                        include
                        Aadhar Card, Driving License, Voter ID Card, and Passport.</p>
                </li>
                <li>
                    <p> Check-in Assistance: Guests facing check-in issues should contact CourtallamRooms immediately
                        for
                        resolution.</p>
                </li>
                <li>
                    <p>Unavailability: After reaching the hotel, if you face any difficulty in check-in and it cannot be
                        resolved by the Hotel, you are requested to contact CourtallamRooms immediately. CourtallamRooms
                        will
                        verify the issue with the Hotel and post verification, you would be provided the following
                        assistance:
                    </p>
                    <div class="jk-gbp-unavailability">
                        <ol type="a">
                            <li>
                                <p>CourtallamRooms will try to arrange for accommodation in the same Hotel</p>
                            </li>
                            <li>
                                <p>CourtallamRooms will try to provide you with alternate accommodation in its other
                                    listed
                                    properties if the same is available.</p>
                            </li>
                            <li>
                                <p>If CourtallamRooms is unable to provide alternative accommodation or you do not
                                    accept such alternate accommodation, you may be offered refund up to 60%.</p>
                            </li>
                            <li>
                                <p>CourtallamRooms will not be liable for compensation beyond your booking payment.</p>
                            </li>
                        </ol>
                    </div>
                </li>
                <li>
                    <p>Unless specifically intimated, CourtallamRooms shall not be held liable to refund the booking
                        amount
                        or
                        any part thereof in case of unavailability of rooms due to natural disaster (earthquake,
                        landslide
                        etc.), terrorist activity, government guidelines or any unforeseen circumstances beyond the
                        control
                        of
                        CourtallamRooms.</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-early-late">
        <h1>Early Check-in and Late Check-out:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Early Check-In: Subject to availability, early check-in may incur additional charges based on the
                        time of
                        arrival.</p>
                </li>
                <li>
                    <p>The standard check-in time in hotels is <strong>12 PM to 2 PM</strong> unless mentioned otherwise
                        in your Booking
                        voucher.
                        Early
                        check-in is <strong>subject to availability</strong>. Extra charges will usually apply as per
                        below policy:</p>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="CheckinTime">
                            <th mat-header-cell *matHeaderCellDef> Check-in Time </th>
                            <td mat-cell *matCellDef="let element"> {{element.CheckinTime}} </td>
                        </ng-container>

                        <ng-container matColumnDef="EarlyCheckinCharges">
                            <th mat-header-cell *matHeaderCellDef> Early Check-in Charges </th>
                            <td mat-cell *matCellDef="let element"> {{element.EarlyCheckinCharges}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </li>
                <li>
                    <p>The standard check-out time in hotels is <strong>10AM</strong> unless mentioned otherwise in your
                        Booking
                        voucher.
                        Late
                        check-out is <strong>subject to availability</strong> and cannot be confirmed with the Hotel in
                        advance.
                        Extra
                        charges
                        will usually apply as per the below policy: </p>


                    <table mat-table [dataSource]="dataSources" class="mat-elevation-z8">
                        <ng-container matColumnDef="CheckoutTime">
                            <th mat-header-cell *matHeaderCellDef> Check-out Time </th>
                            <td mat-cell *matCellDef="let element"> {{element.CheckoutTime}} </td>
                        </ng-container>

                        <ng-container matColumnDef="LateCheckoutCharges">
                            <th mat-header-cell *matHeaderCellDef> Late Check-out Charges</th>
                            <td mat-cell *matCellDef="let element"> {{element.LateCheckoutCharges}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
                    </table>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-extension">
        <h1>Booking Extension Policy:</h1>
        <p>Extension of stay is subject to room availability at current rates, not necessarily at the original booking
            rate.
        </p>
    </div>
    <div class="jk-gbp-cancellation" id="cancellation-policy">
        <h1>Cancellation Policy:</h1>
        <div class="jk-gbp-cancellation-policy">
            <ul>
                <li>
                    <p>If you cancel your booking more than 72 hours (3 days) before the check-in time (12 PM), you will
                        receive a refund of <strong>90%</strong> of the room price.
                    </p>
                </li>
                <li>
                    <p>If you cancel your booking between 24 hours and 72 hours (3 days) before the check-in time (12
                        PM), you will receive a refund of <strong>70%</strong> of the room price.</p>
                </li>
                <li>
                    <p>If you cancel your booking within 24 hours of the check-in time (12 PM), you will receive a
                        refund of <strong>10%</strong> of the room price.
                    </p>
                </li>
                <li>
                    <p>You will not be able to cancel your booking on or beyond the check-in time (12 PM), and no refund
                        will be provided.</p>
                </li>
            </ul>

            <table mat-table [dataSource]="data" class="mat-elevation-z8">
                <ng-container matColumnDef="CancellationPeriod">
                    <th mat-header-cell *matHeaderCellDef> Cancellation Period </th>
                    <td mat-cell *matCellDef="let element"> {{element.CancellationPeriod}} </td>
                </ng-container>

                <ng-container matColumnDef="RefundPercentage">
                    <th mat-header-cell *matHeaderCellDef> Refund Percentage </th>
                    <td mat-cell *matCellDef="let element"> <strong>{{element.RefundPercentage}}</strong> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayed"></tr>
                <tr mat-row *matRowDef="let row; columns: displayed;"></tr>
            </table>

            <p>Cancellation Process: Cancellations can be made through the CourtallamRooms website.</p>
            <p>Refund: Refunds will be processed within 7-14 working days. Please note that the platform fee/service fee
                will
                not be refunded upon cancellation of booking.</p>
            <p>GST will be applied to cancellation charges, and the final refund amount will be after the GST deduction.
            </p>
            <p>Exceptions: Some hotels may have specific policies regarding unmarried couples and local ID proofs, which
                may
                affect cancellation and refund eligibility.</p>
        </div>
    </div>
    <div class="jk-gbp-long-stay-bookings">
        <h1>Long Stay Bookings:</h1>
        <p>For stays longer than 7 nights, outstanding payments must be settled weekly.</p>
    </div>
    <div class="jk-gbp-visitors-policy">
        <h1>Visitors Policy:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Visitor Guidelines: Visitors are generally allowed to meet guests in guest rooms during the day,
                        but
                        overnight stays are not permitted.</p>
                </li>
                <li>
                    <p>Identification: All visitors must present government-approved photo identification at the hotel
                        front
                        desk.</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-child-policy">
        <h1>Child Policy:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p> Child Stay: One child up to 7 years of age stays free without an extra mattress.</p>
                </li>
                <li>
                    <p>Breakfast: Charges may apply for child breakfast.</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-service-on-time-policy">
        <h1>Service On-Time Policy:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Issue Resolution: CourtallamRooms promises issue resolution as soon as possible or room change.
                    </p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-code-of-conduct">
        <h1>Code of Conduct:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Illegal Activities: Illegal activities are prohibited in all CourtallamRooms hotels.</p>
                </li>
                <li>
                    <p>Behavioral Guidelines: Hotels may refuse service or evict guests for disruptive behavior or
                        property
                        damage.</p>
                </li>
                <li>
                    <p>You should be aware that the Hotel may refuse service or evict you</p>
                    <div class="jk-gbp-refuse">
                        <ol type="a">
                            <li>
                                <p> For refusal or failure to pay for accommodation,</p>
                            </li>
                            <li>
                                <p>If you act in a disorderly fashion as to disturb the peace of other guests,</p>
                            </li>
                            <li>
                                <p> If you destroy, damage, deface or threaten harm to hotel property or guests,</p>
                            </li>
                            <li>
                                <p>Any of your actions are deemed inappropriate by the Hotel.</p>
                            </li>
                        </ol>
                    </div>
                </li>
                <li>
                    <p>Please keep the Hotel room in a good condition and maintain hygiene and cleanliness. You may be
                        held
                        liable for any damage to Hotel assets (except normal wear and tear).</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-smoking-drugs-alcohol">
        <h1>Smoking, Drugs, and Alcohol:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Smoking: In guest rooms, smoking may be restricted or prohibited, subject to the hotel's specific
                        policies.</p>
                    <ul>
                        <li>
                            <p>It is the responsibility of guests to adhere to the smoking policy based on the hotel's
                                guidelines for the comfort and safety of all guests.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>Drugs and Alcohol: Use or influence of illegal drugs is prohibited, and alcohol consumption is
                        regulated
                        within hotel premises. It is the responsibility of guests to adhere to the alcohol consumption
                        policy
                        based on the hotel's guidelines</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-safety-security">
        <h1>Safety and Security:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Fire Safety: Guests are advised to follow fire safety procedures as directed by hotel staff.</p>
                </li>
                <li>
                    <p>Valuables: CourtallamRooms/Hotel are not liable for lost or stolen valuables.</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-contact-policies">
        <h1>Contact Policies:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Pre-Check-in Contact: Guests may be contacted before check-in to confirm arrival status or
                        provide
                        feedback.</p>
                </li>
                <li>
                    <p>Feedback and Offers: Guests may be contacted for feedback or offers.</p>
                </li>
            </ol>
        </div>
    </div>
    <div class="jk-gbp-beware-of-fraud">
        <h1>Beware of Fraud:</h1>
        <div class="lists">
            <ol>
                <li>
                    <p>Payment: CourtallamRooms does not authorize any of its employees, consultants, third-party
                        vendors,
                        associates to collect payment in any other form other than payments from secure CourtallamRooms
                        gateway.
                    </p>
                </li>
                <li>
                    <p>Unauthorized Payment: Any attempt to collect payment through unauthorized gateways is fraudulent,
                        and
                        CourtallamRooms is not liable for resulting losses.</p>
                </li>
            </ol>
        </div>
    </div>

</div>