<div class="jk-form-container jk-double-column-form">
    <h1>{{'USER.REGISTRATION' | translate }}</h1>
    <form [formGroup]="registrationFormGroup" (submit)="onSubmit()">
        <div class="jk-form-fields-container">

            <div class="jk-form-field-container">
                <mat-form-field>
                    <mat-label>{{'USER.FIRST_NAME' | translate }}</mat-label>
                    <input matInput placeholder="" formControlName="first_name">

                    @if (registrationFormGroup.controls['first_name'].errors?.['required']&&
                    registrationFormGroup.controls['mobile_no'].dirty) {
                    <mat-error>{{'USER.FIRST_NAME_REQUIRED' | translate }}</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="jk-form-field-container">
                <mat-form-field>
                    <mat-label>{{'USER.LAST_NAME' | translate }}</mat-label>
                    <input matInput placeholder="" formControlName="last_name">
                </mat-form-field>
            </div>

            <div class="jk-form-field-container">
                <mat-form-field>
                    <mat-label>{{'USER.PASSWORD' | translate }}</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder=""
                        formControlName="password">
                    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
                        'visibility'}}</mat-icon>

                    @if (registrationFormGroup.controls['password'].errors?.['required'] &&
                    registrationFormGroup.controls['mobile_no'].dirty) {
                    <mat-error>{{'USER.PASSWORD_REQUIRED' | translate }}</mat-error>
                    }
                    @if (registrationFormGroup.controls['password'].errors?.['minlength']) {
                    <mat-error>{{'USER.PASSWORD_lENGTH' | translate }}</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="jk-form-field-container">
                <mat-form-field>
                    <mat-label>{{'USER.CONFIRM_PASSWORD' | translate }}</mat-label>
                    <input [type]="hideConfirmPassword ? 'password' : 'text'" matInput
                        formControlName="confirm_password">
                    <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ?
                        'visibility_off' : 'visibility'}}</mat-icon>

                    @if (registrationFormGroup.controls['confirm_password'].errors?.['required']&&
                    registrationFormGroup.controls['mobile_no'].dirty) {
                    <mat-error>{{'USER.CONFIRM_PASSWORD_REQUIRED' | translate }}</mat-error>
                    }
                    @if (registrationFormGroup.controls['confirm_password'].errors?.['minlength']) {
                    <mat-error>{{'USER.CONFIRM_PASSWORD_lENGTH' | translate }}</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="jk-form-field-container">
                <mat-form-field>
                    <mat-label>{{'USER.EMAIL' | translate }}</mat-label>
                    <input type="email" matInput formControlName="email">

                    @if (registrationFormGroup.controls['email'].errors?.['required']&&
                    registrationFormGroup.controls['mobile_no'].dirty) {
                    <mat-error>{{'USER.EMAIL_REQUIRED' | translate }}</mat-error>
                    }

                    @if (registrationFormGroup.controls['email'].errors?.['email']) {
                    <mat-error>{{'USER.EMAIL_VALID' | translate }}</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="jk-form-field-container">
                <mat-form-field>
                    <mat-label>{{'USER.MOBILE_NUMBER' | translate }}</mat-label>
                    <input matInput placeholder="" type="number" formControlName="mobile_no" maxlength="10">

                    @if (registrationFormGroup.controls['mobile_no'].errors?.['required'] &&
                    registrationFormGroup.controls['mobile_no'].dirty) {
                    <mat-error>{{'USER.MOBILE_NUMBER_REQUIRED' | translate }}</mat-error>
                    }
                    @else if (registrationFormGroup.controls['mobile_no'].errors?.['pattern']) {
                    <mat-error>{{'USER.MOBILE_NUMBER_lENGTH' | translate }}</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="jk-form-field-container jk-sm-switches">
                <mat-slide-toggle formControlName="is_whats_app">{{'USER.WHATSAPP' | translate }}
                </mat-slide-toggle>
            </div>
            <div class="jk-form-field-container jk-sm-switches">
                <mat-slide-toggle formControlName="is_telegram">{{'USER.TELEGRAM' | translate }}
                </mat-slide-toggle>
            </div>

            <!-- <div class="jk-form-field-container"> -->
            <span class="jk-policy-link">By clicking "Submit" you agree to our <a
                    [routerLink]="'/new-user/terms-conditions'" target="_blank">Terms of
                    use</a> and <a [routerLink]="'/new-user/privacy-policy'" target="_blank">Privacy
                    policy</a></span>
            <!-- </div> -->


        </div>
        <div class="jk-form-field-container">
            <button mat-raised-button type="submit" color="primary"
                [disabled]="!registrationFormGroup.valid  || !showButton">{{'USER.SUBMIT'
                | translate }} <app-spinner *ngIf="showSpinner"></app-spinner></button>
        </div>
    </form>

    @if(!cartPage){
    <div class="jk-ln-links">
        <div class="jk-ln-link"><mat-label>Already have an account? </mat-label><a class="jk-ln-sp"
                [routerLink]="'/new-user/login'">Signin</a></div>
    </div>
    }


</div>