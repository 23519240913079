<!-- <div class="m-form-container" fxFlex.xs="95" fxFlex.sm="70" fxFlex.md="70" fxFlex.lg="60" fxFlex.xl="50" [ngClass]="screenInfo.cssClass"> -->
<div class="jk-sh-form-container" [ngClass]="screenInfo.cssClass">
  <ng-container *ngIf="screenInfo.screenSize !== 'xs'">
    <!-- <h2>Profile Search</h2> -->
    <!-- <mat-expansion-panel (opened)="openPanel()" (closed)="closePanel()" [hideToggle]="hideToggle"
      [expanded]="panelOpenState" color="accent">
      <mat-expansion-panel-header>
        <mat-panel-title>

        </mat-panel-title>
        <mat-panel-description>
          {{panelOpenState || (panelOpenState && properties.length) ? '' : 'Modify Search'}}
        </mat-panel-description>
      </mat-expansion-panel-header> -->

    <!-- NEED TO ADD INPUT PROP [results]="yes/no" based on which the loader has to be hidden inside onchange event in app-search-form component-->
    <app-search-form (searchFiltersReceived)="searchFiltersReceived($event)"
      [resultsReceived]="resultsReceived"></app-search-form>
    <!-- </mat-expansion-panel> -->

  </ng-container>
  <app-search-form *ngIf="screenInfo.screenSize === 'xs'" (searchFiltersReceived)="searchFiltersReceived($event)"
    [resultsReceived]="resultsReceived"></app-search-form>


  <!-- Show loading text until result comes -->

  @if(!resultsReceived){
  <div class="jk-loading-text-container">
    <span class="jk-loading-text"> Loading Property Details</span>
    <app-spinner></app-spinner>
  </div>
  }

  <app-results *ngIf="properties.length" [properties]=properties></app-results>
  <div *ngIf="resultsReceived && !properties.length" class="jk-sr-no-results"> No Properties Found - Please try with
    different search criteria</div>
  <div *ngIf="endSearchResult" class="jk-sr-end"> End of Search result </div>
</div>

<mat-toolbar class="jk-search-footer-toolbar  mat-elevation-z6"
  *ngIf="screenInfo.screenSize === 'xs' && properties.length">


  <div class="jk-search-footer-menus">

    <div class="jk-lo-widget">
      <a [routerLink]="['/landing']">
        <mat-icon>dashboard</mat-icon>
      </a>
    </div>

    <div class="jk-lo-widget">

      <!-- <mat-icon (click)="openBottomSheet()">person_search</mat-icon> -->

    </div>

    <div class="jk-lo-widget" [mat-menu-trigger-for]="propertyMenuXs" mat-icon-button class="ml-xs">
      <mat-icon>person</mat-icon>
    </div>
  </div>

  <mat-menu #propertyMenuXs="matMenu" x-position="before">

    @if(token){
    <a mat-menu-item [routerLink]="['/user/view-my-profile']">
      <mat-icon>person_pin</mat-icon>
      {{'USER.VIEW_PROFILE' | translate }}
    </a>
    <a mat-menu-item [routerLink]="['/user/booking-details']">
      <mat-icon>history</mat-icon>
      {{'BOOKING_HISTORY' | translate }}
    </a>
    <a mat-menu-item [routerLink]="['/new-user/logout']">
      <mat-icon>exit_to_app</mat-icon>
      {{'SIGN_OUT' | translate }}
    </a>
    }@else{
    <a mat-menu-item [routerLink]="['/new-user/signup']">
      <mat-icon>person_add</mat-icon>
      {{'SIGNUP' | translate }}
    </a>

    <a mat-menu-item [routerLink]="['/new-user/login']">
      <mat-icon>login</mat-icon>
      {{'LOGIN' | translate }}
    </a>
    }

    <!-- <button (click)="changePassword()" mat-menu-item *ngxPermissionsOnly="['CHANGE_PASSWORD']">
          <mat-icon>lock_open</mat-icon>
          {{'CHANGE_PASSWORD' | translate }}
      </button>
      <button (click)="editProfile()" mat-menu-item *ngxPermissionsOnly="['EDIT_PROFILE']">
          <mat-icon>edit</mat-icon>
          {{'EDIT_PROFILE' | translate }}
      </button> -->
  </mat-menu>

</mat-toolbar>