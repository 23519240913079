import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { IScreensize } from '../../app';
import { SearchFormComponent } from '../../property/search/search-form/search-form.component';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';
import { UserService } from '../../shared/services/user.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserStorageService } from '../../shared/services/storage.service';
@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, 
    RouterOutlet,
    MatIconModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    SearchFormComponent,
    TranslateModule,
    MatMenuModule,
    MatToolbarModule
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  public token!:string | null;
  public isUserLoggedIn:boolean = false;
  public environment!: any;
  public screenInfo!: IScreensize;
  public domainName: string;
  constructor(
    private userService: UserService, 
    private screensizeDetectService: ScreensizeDetectService,
    private browserStorageService: BrowserStorageService,
    @Inject(DOCUMENT) private document: any,
  ){
      this.isUserLoggedIn = this.userService.isUserLoggedIn();
      this.environment = environment;

      this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
        this.screenInfo = screenInfo;
      });

      this.domainName = this.document.location.hostname;
      this.token = this.browserStorageService.get('token');
      
    }
}
