<div class="jk-form-container jk-single-column-form">
  <!-- <mat-card class="jk-sr-card">
      <mat-card-header>
  
        <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> 
        <mat-card-title>{{'LOGIN' | translate }} </mat-card-title>
  
      </mat-card-header> -->
  <h1>{{'LOGIN' | translate }}</h1>
  <form [formGroup]="formLoginGroup" autocomplete="off">

    <div class="jk-form-field-container">
      <mat-form-field>
        <mat-label>{{ 'USER.EMAIL' | translate }}</mat-label>
        <input type="text" matInput formControlName="email" placeholder="">
        @if (formLoginGroup.controls['email'].errors?.['required']) {
          <mat-error>{{ 'USER.EMAIL_REQUIRED' | translate }}</mat-error>
          }
          @if (formLoginGroup.controls['email'].errors?.['email']) {
            <mat-error>{{ 'USER.EMAIL_VALID' | translate }}</mat-error>
            }
      </mat-form-field>

    </div>
    <div class="jk-form-field-container">
      <mat-form-field>
        <mat-label>{{ 'USER.PASSWORD' | translate }}</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" placeholder="" formControlName="password">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        @if (formLoginGroup.controls['password'].errors?.['required']) {
          <mat-error>{{ 'USER.PASSWORD_REQUIRED' | translate }}</mat-error>
          }
          @if (formLoginGroup.controls['password'].errors?.['minlength']) {
            <mat-error>{{ 'USER.PASSWORD_lENGTH' | translate }}</mat-error>
            }
      </mat-form-field>
    </div>

    <div class="jk-form-field-container">
      <button mat-button mat-raised-button color="primary" type="submit" (click)="saveLogin()"
        [disabled]="!formLoginGroup.valid">{{
        'LOGIN' | translate }}
        <app-spinner *ngIf="showSpinner"></app-spinner>
      </button>
      <!-- <div><mat-label>New customer? <a [routerLink]="['/new-user/registration']">Create Your
            Account.</a></mat-label></div> -->
    </div>
  </form>

  @if(!cartPage){
  <div class="jk-ln-links">
    <a class="jk-ln-otp jk-ln-link" [routerLink]="'/new-user/login/otp'">Login with OTP</a>
    <!-- <a class="jk-ln-fp jk-ln-link" [routerLink]="'/new-user/login/otp'">Forgot Password?</a> -->
    <div class="jk-ln-link"><mat-label>New User? </mat-label><a class="jk-ln-sp"
        [routerLink]="'/new-user/signup'">Create Your
        Account.</a></div>
  </div>
  }

  <!-- </mat-card> -->
</div>