import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CartComponent } from '../cart/cart.component';
import { IBottomSheetCartData } from '../property';

@Component({
  selector: 'app-cart-bottom-sheet',
  standalone: true,
  imports: [CartComponent],
  templateUrl: './cart-bottom-sheet.component.html',
  styleUrl: './cart-bottom-sheet.component.scss'
})
export class CartBottomSheetComponent {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: IBottomSheetCartData) { }
  updateRoomSelections(bed_type_id: number){

  }

}


