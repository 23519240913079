<mat-toolbar class="jk-main-header">
    <div class="jk-branding">
        <div class="jk-logo"></div>
    </div>
    <div>
        <span>{{data.title || ''}}</span>
    </div>
    <div fxFlex></div>
</mat-toolbar>
<mat-dialog-content>
    <div class="jk-container">
        <div class="jk-content">
            <div *ngIf="!data.isHtml" class="jk-tittle-message">{{ data.message_bed }}</div>
            <!-- <div *ngIf="data.isHtml" class="" [innerHtml]="data.message | safeHtml"> </div> -->


            <mat-radio-group aria-label="Select an option" [(ngModel)]="extra_bed">
                <mat-radio-button value="no">No</mat-radio-button>
                <mat-radio-button value="yes">Yes</mat-radio-button>
            </mat-radio-group>
        </div>
        @if(extra_bed=="yes"){
        <div class="jk-count-container">
            <div class="jk-button-container jk-adult-container">
                <div class="jk-increment-btn" (click)="decrementBedCount()">-
                </div>
                <input type="text" matInput [(ngModel)]="extraBed" readonly>
                <div class="jk-increment-btn" (click)="incrementBedCount()">+
                </div>
            </div>

            <div class="jk-price">+ &#8377;{{this.extra_bed_price}}</div>
        </div>

        }


        <div class="jk-content">
            <div *ngIf="!data.isHtml" class="jk-tittle-message">{{ data.message_ac }}</div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="ac">
                <mat-radio-button value="no">No</mat-radio-button>
                <mat-radio-button value="yes" >Yes</mat-radio-button>
            </mat-radio-group>
        </div>
        @if(ac=="yes"){
        <div class="jk-count-container">
            <div class="jk-button-container jk-adult-container">
                <div class="jk-increment-btn" (click)="decrementAcCount()">-
                </div>
                <input type="text" matInput [(ngModel)]="noOfAc" readonly>
                <div class="jk-increment-btn" (click)="incrementAcCount()">+
                </div>
            </div>

            <div class="jk-price">
                + &#8377;{{this.price_ac}}
            </div>
        </div>
        }

    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <span fxFlex></span>
    <button mat-raised-button color="default" (click)="cancel()" mat-dialog-close>
        {{ 'DIALOG_CANCEL_BTN' | translate }}
    </button>

    <button mat-raised-button color="primary" (click)="confirm()" mat-dialog-close>
        {{ 'DIALOG_CONFIRM_BTN' | translate}}
    </button>

</mat-dialog-actions>