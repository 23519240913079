<div class="jk-form-container">
  @if(userDetails){
    <div class="jk-form-fields-container">
        <h1>My Profile</h1>
        <div class="jk-form-info">
            <div class="jk-form-heading">First Name:</div>
            <div class="jk-form-content"> {{userDetails?.profile?.first_name}}</div>
        </div>
        <div class="jk-form-info">
            <div class="jk-form-heading">Last Name:</div>
            <div class="jk-form-content">{{userDetails?.profile?.last_name}}</div>
        </div>
        <div class="jk-form-info">
            <div class="jk-form-heading">Email Address:</div>
            <div class="jk-form-content"> {{userDetails?.profile?.email}} (Verified)</div>
        </div>
        <div class="jk-form-info">
            <div class="jk-form-heading">Mobile Number:</div>
            <div class="jk-form-content">{{userDetails?.profile?.mobile_no}} (Verified)</div>
        </div>

    </div>
   

  }

</div>