import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { RouterLink, RouterOutlet } from '@angular/router';
import { IScreensize } from '../../app';
import { IPaginationMetaData, IPropertyDetails } from '../../property/property';
import { PropertyService } from '../../shared/services/property.service';
import { ScrollHandleService } from '../../shared/services/scroll-handle.service';

@Component({
  selector: 'app-search-results',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    RouterOutlet,
    RouterLink,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule,
    MatSliderModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss'
})
export class SearchResultsComponent {
  public searchResultFormGroup!: FormGroup
  public screenInfo!: IScreensize;
  public properties: IPropertyDetails[] = [];
  private metaData!: IPaginationMetaData;
  public endSearchResult = false;
  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private scrollHandleService: ScrollHandleService,) {

    this.setFormFields();
    // this.propertyService.getAllPropertyDetails().subscribe(result => {

    // })

    this.scrollHandleService.triggerSearch$.subscribe(triggerSearch => {
      if (triggerSearch) {

        if (this.metaData && this.metaData.current_page < this.metaData.last_page) {
          this.doSearch(this.metaData.current_page + 1);
        } else {
          this.endSearchResult = true;
        }
      }
    })


  }

  // TODO:  24/02/24 Need to check why this functin has been definied in 2 places.(in search and search-resulst component). Adn this function needs to be modified as per search component
  doSearch(nextPage: number) {
    /** reset the value to false other wise simplechanges wont be trigged if the user clicks submit button for second time via modify search */
    if (nextPage === 1) {
      //this.resultsReceived = false;
    }

    const data = new HttpParams();

    // if(this.propertySearchDetailsJson){
    //             data.set('property_type_id', 2)
    // }
    // .set('from_date', '2024-07-01')
    //data.set('to_date', '2024-07-01');
    // .set('occupancy', occupancyArr.join(','))

    // Insert Property DETAILS
    this.propertyService.searchProperty(data, nextPage).subscribe(properties => {

      /* Inform child components that the results has come. do it only for the very first time when user hits the search button. so that it wont trigger when auto loading during the sroll  */
      if (nextPage === 1) {
        //this.resultsReceived = true;
      }

      /* Append the new results with the existings.  */
      /* Todo: Need to set max limit that should be displayed at any point in time to handle browser memory issue */
      //this.properties = this.properties.concat(properties.result);
      //this.users = [...this.users, users.data];
      //this.metaData = properties.meta;
      if (properties) {
        //this.showMobileForm = false;
        /* Inform layout compoent that we have found a new result set. Keep an eye on the scrolling end position */
        this.scrollHandleService.captureScrollPositon();
        //this.propertyService.getScrollingElement();
        //this.closePanel();
      }

      //this.router.navigate(['user/details/update']);

    });
  }

  setFormFields() {
    // this.searchResultFormGroup = this.fb.group({
    //   map_view:[false],
    //   sort_price:[''],

    // })
  }
  onSubmit() {

    console.log(this.searchResultFormGroup.value);

    // this.propertyService.saveBasicDetails(this.basicDetailFormGroup.value).subscribe(result => {


    // });



  }
}
