import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IScreensize } from '../../app';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';

import { PropertyService } from '../../shared/services/property.service';
import { CartBottomSheetComponent } from '../cart-bottom-sheet/cart-bottom-sheet.component';
import { CartComponent } from '../cart/cart.component';
//import { RelatedPropertiesComponent } from '../related-properties/related-properties.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { AmPmPipe } from '../../shared/master-data/pipes/am-pm.pipe';
import { BedTypePipe } from '../../shared/master-data/pipes/bed-types.pipe';
import { WashroomTypesPipe } from '../../shared/master-data/pipes/washroom-types.pipe';
import { YesNoPipe } from '../../shared/master-data/pipes/yes-no.pipe';
import { BrowserStorageService } from '../../shared/services/storage.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { ExtraBedComponent } from '../extra-bed/extra-bed.component';
import { PhotoViewerComponent } from '../photo-viewer/photo-viewer.component';
import {
  IBottomSheetCartData,
  IPropertyDetails,
  IPropertyPhotoDetails,
  ISearchDisplayValues,
  ISearchFieldsValues,
  ISelectedRoom,
  IViewBedType,
  IViewCategories,
  IViewRooms
} from '../property';

@Component({
  selector: 'app-view-property',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatCardModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    CartComponent,
    BedTypePipe,
    WashroomTypesPipe,
    YesNoPipe,
    AmPmPipe,
    SpinnerComponent
  ],
  templateUrl: './view-property.component.html',
  styleUrl: './view-property.component.scss',
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0%)' }))
      ])
    ])
  ]
})
export class ViewPropertyComponent implements OnInit {
  public token!:string | null;
  public thumbnailUrl = environment.imageUrl + 'thumbnail/';
  public mainPhotoUrl = environment.imageUrl + 'main/';
  public showSpinner: boolean = false;
  public propertyDetails!: IPropertyDetails;
  public propertyPhotoDetails: IPropertyPhotoDetails[] = [];
  public property_id: number = 0;
  public screenInfo!: IScreensize;
  
  // This will be used to display the room details on the screen. This will not have category id
  public currentRoomDetailsForDisplay!: IViewRooms;
  // This will be used to add to the cart when user selects the room
  public currentRoomDetailsForCart!: ISelectedRoom;
  public selectedRooms: ISelectedRoom[] = [];
  public searchJson!: ISearchFieldsValues;
  public adultCount: number = 0;
  public childrenCount: number = 0;
  public searchValuesArr!: ISearchDisplayValues;

  // This is used to show the count in the toolbar for mobile view
  public totalNoOfRoomsSelected = 0;
  // The below vars are used to store the date in DD-MM-YYYY format to be displayed in the screen
  public displayFromDate!: string;
  public displayToDate!: string;
  public noOfDays: number = 0;
  currentIndex: number = 0;
  thumbnailOffset: string = '0';
  private confirmAction = false;
  public dialogResult!: number;
  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private browserStorageService: BrowserStorageService,
    private readonly route: ActivatedRoute,
    private screensizeDetectService: ScreensizeDetectService,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,) {

    const property_id = this.route.snapshot.paramMap.get('propertyId');
    if (property_id) {
      this.property_id = +property_id;

    }

    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
    this.token = this.browserStorageService.get('token');
  }

  ngOnInit() {
    this.searchValuesArr = this.browserStorageService.getSearchValuesFromLocalStorage();
    this.displayFromDate = moment(this.searchValuesArr.from_date, "YYYY-MM-DD").format('DD-MM-YYYY');
    this.displayToDate = moment(this.searchValuesArr.to_date, "YYYY-MM-DD").format('DD-MM-YYYY');
    this.noOfDays = this.getNumberOfDays();
    this.getPropertyDetails();
    this.getPropertyPhotoDetails();
  }


  getPropertyDetails() {
    let data = new HttpParams()
    // .set('private_falls', private_falls)
    if (this.property_id) {
      data = data.set('property_id', this.property_id)
    }
    data = data.set('from_date', this.searchValuesArr.from_date);
    data = data.set('to_date', this.searchValuesArr.to_date)
    data = data.set('occupancy', this.searchValuesArr.occupancy)
    data = data.set('children', this.searchValuesArr.children_count)
    data = data.set('children_age', this.searchValuesArr.children_age)

    this.showSpinner = true;
    this.propertyService.getPropertyDetails(data).subscribe(result => {
      this.propertyDetails = result;
      // check if any rooms available in this property
      if (this.propertyDetails.bed_types.length) {
        // Load the details for index 0;
        this.getCurrentRoomDetailsForActiveTab(null);

      }
      this.showSpinner = false;
    });

  }

  getPropertyPhotoDetails() {
    this.propertyService.getPropertyPhotoDetails(this.property_id).subscribe(result => {
      this.propertyPhotoDetails = result.data;
    });
  }

  /*
    Get the room details for the current bed type and category
    tab Index will be available in event object
 */
  getCurrentRoomDetailsForActiveTab(event: MatTabChangeEvent | null) {
    let index = 0; // This will be set during initialization
    if (event !== null) {
      index = event.index
    }

    // Get the first category for the bedtype matches the selected tab index
    const bedType = this.propertyDetails.bed_types[index];
    const category = this.propertyDetails.bed_types[index].categories[0];
    // Get the first room details from the selected category
    this.currentRoomDetailsForDisplay = category.rooms[0];
    this.currentRoomDetailsForDisplay.total_available_rooms = category.rooms.length;
    // Assign the current room details with required details for cart
    this.currentRoomDetailsForCart = {
      bed_type_id: bedType.bed_type_id,
      category_id: category.category_id,
      quantity: 1,
      price: this.currentRoomDetailsForDisplay.price,
      discounted_price: this.currentRoomDetailsForDisplay.discounted_price,
      is_gst_included: this.currentRoomDetailsForDisplay.is_gst_included,
      available_room_ids: category.rooms.map(room => room.room_id) // Get all the availabe roomids for the current bed type and category. We need to take the room id based on the quantity and send it to backend
    }
  }
  /*
    Get the room details for the current bed type and category. 

    This will be called when the user clicks on the category name 
    (Category name will be displayed when there are more than one category available for the selected bed type)
  */
  getCurrentRoomDetails(bed_type_id: number, category_id: number) {


    //Both Filters always return only one row 

    const filteredBedType: IViewBedType = this.propertyDetails.bed_types.filter(bedType => bedType.bed_type_id === bed_type_id)[0];
    const filteredCategory: IViewCategories = filteredBedType.categories.filter(category => category.category_id === category_id)[0];
    this.currentRoomDetailsForDisplay = filteredCategory.rooms[0];
    // Assign the total no of rooms available
    this.currentRoomDetailsForDisplay.total_available_rooms = filteredCategory.rooms.length;
  }

  /* Extra bed popup */

  showExtraBedConfirmationDialog() {

    const dialogRef = this.dialog.open(ExtraBedComponent, {
      width: '500px',
      minHeight: '250px',
      maxHeight: '95vh',

      data: {
        title: "Additional Requirement",
        message_bed: 'Do You Want Extra Bed?',
        message_ac: 'Do You Want AC?',
        // showCheckbox: true,
        countOfExtraBed: this.currentRoomDetailsForDisplay.no_of_extra_bed,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result;
      this.addRoomDetailsToCart()
      console.log(this.dialogResult)

    });

  }

  addRoomDetailsToCart() {

    console.log(this.searchValuesArr);

    // Check if selected room is already added to the cart. 
    const rooms = this.selectedRooms.filter(room => (room.bed_type_id === this.currentRoomDetailsForCart.bed_type_id && room.category_id === this.currentRoomDetailsForCart.category_id))
    // Room has been already added. So increase the quantity
    if (rooms.length) {
      this.selectedRooms = this.selectedRooms.map(room => {
        if (room.bed_type_id === this.currentRoomDetailsForCart.bed_type_id && room.category_id === this.currentRoomDetailsForCart.category_id) {
          room.quantity += 1;
          this.totalNoOfRoomsSelected += 1;
          return room;
        }
        // control wont come here as it will surely match the above condition as already found that there is room added
        return room;
      })
    }
    else {
      // Room has not been already added yet to the cart. so push it.
      // If you use push method, ngonchanges wont be triggered.  because the reference to the array doesn't change. Angular only detects changes to the reference itself, not to the contents of the array.
      this.selectedRooms = [...this.selectedRooms, this.currentRoomDetailsForCart];
      this.totalNoOfRoomsSelected += 1;
    }


  }

  checkIfRoomsNotAvailable() {

    // Check if selected room is already added to the cart. 
    const rooms = this.selectedRooms.filter(room => (room.bed_type_id === this.currentRoomDetailsForCart.bed_type_id && room.category_id === this.currentRoomDetailsForCart.category_id));
    // Check if we have added maximum number of quantity equal to the available no of rooms
    if (rooms.length && rooms[0].quantity === rooms[0].available_room_ids.length) {
      return true;
    } else {
      // Room has not been already added yet to the cart. so dont disable it
      return false;
    }
  }

  getAvailableRoomCountForBedType() {

    // Check if selected room is already added to the cart. 
    const rooms = this.selectedRooms.filter(room => (room.bed_type_id === this.currentRoomDetailsForCart.bed_type_id && room.category_id === this.currentRoomDetailsForCart.category_id));
    // Check if we have already added atleast one room. If so, get the available no of rooms from selectedRooms array.
    if (rooms.length) {
      return rooms[0].available_room_ids.length - rooms[0].quantity;
    } else {
      // No room has been added yet to the cart. so return the total available rooms 
      return this.currentRoomDetailsForCart.available_room_ids.length;
    }
  }

  updateRoomSelections(bed_type_id: number) {
    // Get tho of rooms that has been removed from selectedRooms array. array count will always be 1
    const roomTobeRemoved = this.selectedRooms.filter(room => room.bed_type_id === bed_type_id);
    const quantity = roomTobeRemoved[0].quantity;

    // Remove all the rooms for the bed_type_id
    this.selectedRooms = this.selectedRooms.filter(room => room.bed_type_id !== bed_type_id)
    this.totalNoOfRoomsSelected -= quantity;
    // Reset the quantity so that only one will be add if the user select this room again
    this.currentRoomDetailsForCart.quantity = 1;
  }

  openBottomSheet(propertyId: number): void {
    console.log('open  bottom sheet');
    const dataToBeSent: IBottomSheetCartData = {
      selectedRooms: this.selectedRooms,
      searchValuesArr: this.searchValuesArr,
      propertyId: propertyId
    }
    console.log('dataToBeSent');
console.log(dataToBeSent)

    const bottomSheet = this._bottomSheet.open(CartBottomSheetComponent,
      { data: dataToBeSent }
    );
    bottomSheet.afterDismissed().subscribe( bedTypeId => {
      // Todo: Need to show the loader
      // Do the search here
      console.log('bottom sheet closed');

    });
  }

  get currentImage(): string {
    const currentImage = this.propertyPhotoDetails[this.currentIndex];
    return currentImage.path + currentImage.name + '.' + currentImage.size;
  }

  showImage(index: number): void {
    this.currentIndex = index;
  }

  prevImage(): void {
    if (this.currentIndex > 0) {
      const imageWidth = this.screenInfo.screenSize !== 'xs' ? 127 : 72;
      // 162 = 115 (image width) + 4 (padding) + 4 (border) + 4 (margin)
      this.thumbnailOffset = `-${(this.currentIndex - 1) * imageWidth}px`;
      this.currentIndex--;
    }
  }

  nextImage(): void {
    if (this.currentIndex < this.propertyPhotoDetails.length - 1) {
      const imageWidth = this.screenInfo.screenSize !== 'xs' ? 127 : 72;
      this.thumbnailOffset = `-${(this.currentIndex + 1) * imageWidth}px`;
      this.currentIndex++;
    }
  }

  getNumberOfDays() {
    const fromDate = moment(this.searchValuesArr.from_date, "YYYY-MM-DD");
    const toDate = moment(this.searchValuesArr.to_date, "YYYY-MM-DD");
    return toDate.diff(fromDate, 'days');

  }

  
  /* Image Dialog */

  openImageDialog(photoId: number, propertyId: number) {

    const dialogRef = this.dialog.open(PhotoViewerComponent, {
      height: '99vh',
      width: '98vw',
      maxWidth: '95vw',
      maxHeight: '98vw',
      hasBackdrop: true,
      panelClass: 'jk-pv-mat-dialog-container',
      data: {
        defaultPhotoId: photoId,
        propertyId: propertyId
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      //dialogSub.unsubscribe();

    });
  }

}
