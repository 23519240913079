<div class="jk-rl-review-container jk-xs">

    <div class="jk-review-list">
        <div class="jk-review-card">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Bala</mat-card-title>
                    <mat-card-subtitle>1 day ago</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <h2>{{propertyDetails.property_name}}</h2>
                    <p>
                        {{propertyDetails.property_description}}
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <a href=""> <mat-icon aria-hidden="false" aria-label="Example home icon"
                            fontIcon="thumb_up"></mat-icon></a>
                    <a href=""><mat-icon aria-hidden="false" aria-label="Example home icon"
                            fontIcon="thumb_down"></mat-icon></a>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="jk-review-card">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Aadi</mat-card-title>
                    <mat-card-subtitle>1 day ago</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="star"></mat-icon>
                    <h2>{{propertyDetails.property_name}}</h2>
                    <p>
                        {{propertyDetails.property_description}}
                    </p>
                </mat-card-content>

                <mat-card-actions>
                    <a href=""> <mat-icon aria-hidden="false" aria-label="Example home icon"
                            fontIcon="thumb_up"></mat-icon></a>
                    <a href=""><mat-icon aria-hidden="false" aria-label="Example home icon"
                            fontIcon="thumb_down"></mat-icon></a>
                </mat-card-actions>

            </mat-card>
        </div>
    </div>


</div>