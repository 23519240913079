<div class="jk-app mat-typography" [ngClass]="screenInfo.cssClass">
    <!-- <mat-toolbar class="jk-main-header  mat-elevation-z6" *ngIf="!searchResultsFound || (scrollTop < 100 && searchResultsFound)"> -->
    <!-- Dont display the header for search results screen -->
    <!-- <mat-toolbar class="jk-main-header  mat-elevation-z6" *ngIf="!searchResultsFound"> -->
    <!-- <div class="jk-top-banner-container">
        <img class="jk-top-banner" src="{{mainUrl + 'bg-popular-room.jpeg' }}" /> -->

    <!-- @if(!environment.production){
    <div class="jk-env jk-env-{{environment.envName}}"> {{ environment.envName }}</div>
    } -->
    <mat-toolbar color="primary" class="">
        <mat-toolbar-row>
            <a class="jk-logo-link" [routerLink]="['/landing']">
                <div class="jk-logo-container">

                    <div class="jk-logo">

                    </div>

                    <div class="jk-logo-sub">COURTALLAM ROOMS</div>

                </div>
            </a>
            <span class="jk-spacer"></span>

            <!-- Menu for larger devices other than mobile -->
            <!-- <div fxFlex *ngIf="screenInfo.screenSize !== 'xs'">

                <a mat-button [routerLink]="['/user/home']">
                    <mat-icon>dashboard</mat-icon>
                    {{'USER.MY_DASHBOARD' | translate }}
                </a>

                <a mat-button [routerLink]="['/user/search']">
                    <mat-icon>person_search</mat-icon>
                    {{'USER.SEARCH' | translate }}
                </a>
            </div> -->

            <div *ngIf="screenInfo.screenSize !== 'xs'" class="jk-name">
                <!-- {{userName}} -->
            </div>

            <button [mat-menu-trigger-for]="userMenu" mat-icon-button class="jk-ml-xs"
                *ngIf="screenInfo.screenSize !== 'xs'">
                <mat-icon>person</mat-icon>
            </button>
            <mat-menu #userMenu="matMenu" x-position="before">
                @if(token){
                <a mat-menu-item [routerLink]="['/user/view-my-profile']">
                    <mat-icon>person_pin</mat-icon>
                    {{'USER.VIEW_PROFILE' | translate }}
                </a>
                <a mat-menu-item [routerLink]="['/user/booking-details']">
                    <mat-icon>history</mat-icon>
                    {{'BOOKING_HISTORY' | translate }}
                </a>
                <a mat-menu-item [routerLink]="['/new-user/logout']">
                    <mat-icon>exit_to_app</mat-icon>
                    {{'SIGN_OUT' | translate }}
                </a>
                }@else{
                <a mat-menu-item [routerLink]="['/new-user/signup']">
                    <mat-icon>person_add</mat-icon>
                    {{'SIGNUP' | translate }}
                </a>

                <a mat-menu-item [routerLink]="['/new-user/login']">
                    <mat-icon>login</mat-icon>
                    {{'LOGIN' | translate }}
                </a>
                }

            </mat-menu>
            <!-- <button class="mobile-hidden" appToggleFullscreen mat-icon-button [fxHide.xs]="true">
            <mat-icon>fullscreen</mat-icon>
        </button> -->
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- </div> -->
    <mat-sidenav-container class="jk-app-inner">

        <!-- <mat-sidenav #sidemenu class="jk-sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()" (opened)="sidePanelOpened = true" (closed)="sidePanelOpened = false">
            <nav-list></nav-list>
        </mat-sidenav> -->

        <!-- <div [@routerAnimations]="getState(o)" class="jk-layout-body-container">-->
        <mat-sidenav-content (scroll)="handleScroll($event)">
            <div class="jk-layout-body-container">
                <router-outlet #o="outlet"></router-outlet>
            </div>
            <footer class="jk-lp-footer" color="primary">
                <ul>
                    <li><a [routerLink]="['/new-user/about-us']">About Us</a></li>
                    <li><a [routerLink]="['/new-user/privacy-policy']">Privacy Policy</a></li>
                    <li><a [routerLink]="['/new-user/booking-policy']">Booking Policy</a></li>
                    <li><a href="/new-user/booking-policy#cancellation-policy">Cancellation Policy</a></li>
                    <li><a [routerLink]="['/new-user/terms-conditions']">Terms & Conditions</a></li>
                    <!-- <li><a [routerLink]="['/new-user/faqs']">FAQs</a></li> -->
                    <li><a [routerLink]="['/new-user/contact-us']">Contact Us</a></li>
                </ul>
                <div class="jk-lp-footer-sub">
                    <div class="jk-lp-cr">&copy; 2024 CourtallamRooms.com, All rights reserved.</div>
                    <div class="jk-lp-cro">CourtallamRooms.com is part of Thamira Technologies LLP.</div>
                </div>
            </footer>
        </mat-sidenav-content>

    </mat-sidenav-container>


    <!-- Show the toolbar only for mobile devices excluding search results page as we need to override active menu and introduce new funcationality that search filter would come when you click on search icon. 
    So we are introducing new toolbar inside search results page with new funcationlity added-->
    <!-- <mat-toolbar class="jk-main-header  mat-elevation-z6 jk-bottom" *ngIf="screenInfo.screenSize === 'xs' && !hideFooterMenuForMobile"> -->
    <mat-toolbar class="jk-main-header  mat-elevation-z6 jk-bottom" *ngIf="screenInfo.screenSize === 'xs'">


        <div class="jk-lo-section-container">

            <div class="jk-lo-widget">
                <a [routerLink]="['/landing']">
                    <mat-icon>dashboard</mat-icon>
                    <div>{{'USER.MY_DASHBOARD' | translate }}</div>
                </a>
            </div>

            <div class="jk-lo-widget">
                <a [routerLink]="['/user/search']" routerLinkActive="jk-active-menu">
                    <mat-icon>person_search</mat-icon>
                    <div>{{'USER.SEARCH' | translate }}</div>
                </a>
            </div>

            <div class="jk-lo-widget" [mat-menu-trigger-for]="userMenuXs" mat-icon-button class="jk-ml-xs">
                <mat-icon>person</mat-icon>
            </div>
        </div>


        <mat-menu #userMenuXs="matMenu" x-position="before">

            @if(token){
                <a mat-menu-item [routerLink]="['/user/view-my-profile']">
                    <mat-icon>person_pin</mat-icon>
                    {{'USER.VIEW_PROFILE' | translate }}
                </a>
                <a mat-menu-item [routerLink]="['/user/booking-details']">
                    <mat-icon>history</mat-icon>
                    {{'BOOKING_HISTORY' | translate }}
                </a>
                <a mat-menu-item [routerLink]="['/new-user/logout']">
                    <mat-icon>exit_to_app</mat-icon>
                    {{'SIGN_OUT' | translate }}
                </a>
                }@else{
                <a mat-menu-item [routerLink]="['/new-user/signup']">
                    <mat-icon>person_add</mat-icon>
                    {{'SIGNUP' | translate }}
                </a>
    
                <a mat-menu-item [routerLink]="['/new-user/login']">
                    <mat-icon>login</mat-icon>
                    {{'LOGIN' | translate }}
                </a>
                }
            <!-- <button (click)="changePassword()" mat-menu-item *ngxPermissionsOnly="['CHANGE_PASSWORD']">
                <mat-icon>lock_open</mat-icon>
                {{'CHANGE_PASSWORD' | translate }}
            </button>
            <button (click)="editProfile()" mat-menu-item *ngxPermissionsOnly="['EDIT_PROFILE']">
                <mat-icon>edit</mat-icon>
                {{'EDIT_PROFILE' | translate }}
            </button> -->
        </mat-menu>

    </mat-toolbar>

</div>