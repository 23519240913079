import { HttpContextToken, HttpErrorResponse, HttpHandlerFn, HttpHeaders, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, throwError } from "rxjs";
import { NotificationService, NotificationType } from "../../shared/services/notification.service";
import { BrowserStorageService } from "../../shared/services/storage.service";
import { TranslateService } from "@ngx-translate/core";

export const SKIP_TOKEN = new HttpContextToken<boolean>(() => false);

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  // Inject the current `AuthService` and use it to get an authentication token:
  const authToken = inject(BrowserStorageService).get('token');


  /* Check if the SKIP_TOKEN IS PASSED FROM THE GET REQUEST. If yes, dont include token */
  if (req.context.get(SKIP_TOKEN)) {
    return next(req);
  }

  // Clone the request to add the authentication header.
  const newReq = req.clone({
    //req.headers.append('X-Authentication-Token', authToken),
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${authToken}`
    })
  });
  return next(newReq);
}


export function errorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const notificationService = inject(NotificationService);
  const translate = inject(TranslateService);
  return next(req)
    .pipe(
      catchError((error: HttpErrorResponse) => {

        //ActBasedOnError(error);
        let errMsg = '';

        switch (error.status) {
          case 500: {

            translate.get('HTTP_500_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });

            notificationService.displayNotification(errMsg, NotificationType.Error);
            break;
          }
          case 400: {
            translate.get('HTTP_400_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });

            notificationService.displayNotification(errMsg, NotificationType.Warn);
            break;
          }
          case 401: {
            translate.get('HTTP_401_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });

            notificationService.displayNotification(errMsg, NotificationType.Error);
            //router.navigate(['/new-user/login']);
            break;
          }
          case 403: {
            translate.get('HTTP_403_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });

            //router.navigate(['/error-page']);
            break;
          }
          case 413: {
            translate.get('HTTP_413_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });

            notificationService.displayNotification(errMsg, NotificationType.Error);
            break;
          }
          case 422: {
            console.log(error.error.errors);
            translate.get('HTTP_422_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });
            //console.log(error);
            notificationService.displayNotification(error.error.errors, NotificationType.Error);
            break;
          }
          case 429: {
            //console.log(error.error.errors);
            translate.get('HTTP_429_ERROR_MSG').subscribe((tranlatedText: string) => {
              errMsg = tranlatedText;
            });

            // setTimeout(() => {
            //   console.log('reloading');
            //   console.log(router.url);

            //   router.navigate([router.url]);
            // }, 3000);

            notificationService.displayNotification(error.error.errors, NotificationType.Info);
            break;
          }
          default: {
            // translate.get('HTTP_DEFAULT_ERROR_MSG').subscribe((tranlatedText: string) => {
            //   errMsg = tranlatedText;
            // });

            notificationService.displayNotification(errMsg, NotificationType.Error);
            break;
          }
        }


        return throwError(() => error);
      })
    );
}