import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { IScreensize } from '../../app';
import { PropertyService } from '../../shared/services/property.service';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';
import { UserService } from '../../shared/services/user.service';
import { IPropertyPhotoDetails } from '../property';

export interface photoViewerData {
  photoId: number;
  propertyId: number;
}

@Component({
  selector: 'app-photo-viewer',
  standalone: true,
  templateUrl: './photo-viewer.component.html',
  imports: [
    CommonModule
  ],
  styleUrl: './photo-viewer.component.scss'
})


export class PhotoViewerComponent implements OnInit {

  public screenInfo!: IScreensize;
  public thumbnailUrl = environment.imageUrl + 'thumbnail/';
  public mainPhotolUrl = environment.imageUrl + 'main/';
  photos: IPropertyPhotoDetails[] = [];
  activePhoto!: IPropertyPhotoDetails;
  showComment: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: photoViewerData,
    private userService: UserService,
    private propertyService: PropertyService,
    private dialogRef: MatDialogRef<PhotoViewerComponent>,
    private screensizeDetectService: ScreensizeDetectService
  ) {
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
  }

  ngOnInit(): void {

    this.getPropertyPhotoDetails(this.data.propertyId);

  }

  getPropertyPhotoDetails(propertyId: number) {
    this.propertyService.getPropertyPhotoDetails(propertyId).subscribe(result => {
      this.photos = result.data;
      this.setActivePhoto(this.photos[0]);
    });
  }


  setActivePhoto(photo: IPropertyPhotoDetails) {
    this.activePhoto = photo;
  }

  showComments() {
    this.showComment = !this.showComment;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
