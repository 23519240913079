import { Component } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { PropertyService } from '../../shared/services/property.service';
import { IUserDetail } from '../../property/property';

@Component({
  selector: 'app-view-my-profile',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    MatButtonModule],
  templateUrl: './view-my-profile.component.html',
  styleUrl: './view-my-profile.component.scss'
})
export class ViewMyProfileComponent {
  public userDetails!: IUserDetail;

  constructor(

    private propertyService: PropertyService

  ) {
    this.getUserDetails();
    
  }


  getUserDetails(){
    this.propertyService.getUserDetails().subscribe(result => {
      this.userDetails = result;
    });
  }

}
