import { HttpClientModule, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterOutlet } from '@angular/router';
import { PropertyService } from '../../../shared/services/property.service';
import { IPropertyDetails } from '../../property';
@Component({
  selector: 'app-review-list',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    MatButtonModule,
    MatCardModule,
    HttpClientModule,
    MatIconModule],
  templateUrl: './review-list.component.html',
  styleUrl: './review-list.component.scss'
})
export class ReviewListComponent {
  public propertyDetails!: IPropertyDetails;
  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService) {
  }

  ngOnInit() {
    this.getPropertyDetails();
  }

  getPropertyDetails() {
    const property_id = 11;
    let params = new HttpParams()
    this.propertyService.getPropertyDetails(params).subscribe(result => {
      this.propertyDetails = result;

    });

  }
}
