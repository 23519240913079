import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IBookingDetail, IBookingDetailForRoom, ICancelMsg, IListUser, IPropertyDetails, IPropertyPhotoDetails, IReview, ISearchPaginationResults, ISearchPropertyDetails, IUserDetail } from '../../property/property';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  private listPropertyUrl = environment.apiUrl + 'propertyDetails'
  private searchServiceUrl = environment.apiUrl + 'propertiesSearch'
  private viewPropertyUrl = environment.apiUrl + 'roomSearch';
  private propertyPhotoUrl = environment.apiUrl + 'getPropertyPhotos';
  private reviewUrl = environment.apiUrl + 'propertyDetails';
  private relatedPropertyUrl = environment.apiUrl + 'propertyDetails';
  private registrationUrl = environment.apiUrl + 'register'
  private fallHtmlPath = '../../../assets/static-pages/falls.html';
  private templeHtmlPath = '../../../assets/static-pages/temples.html';
  private restaurantHtmlPath = '../../../assets/static-pages/restaurants.html';
  private viewbookingDetailsUrl = environment.apiUrl + 'myBookings';
  private viewbookingDetailsForRoomUrl = environment.apiUrl + 'viewBookingDetail';
  private cancelRoomWithMasterBookingIdUrl = environment.apiUrl + 'getBookingDetails'
  private cancelAllRoomUrl = environment.apiUrl + 'cancelAllRooms'
  private cancelOneRoomUrl = environment.apiUrl + 'cancelOneRoom'
  private deleteBookingsByMasterIdUrl = environment.apiUrl + 'deleteBookingsByMasterId'
  private userDetailsUrl = environment.apiUrl + 'viewMyProfile'
  constructor(private http: HttpClient) { }






  getRelatedProperties(): Observable<{ data: IPropertyDetails[] }> {
    return this.http.get<any>(this.relatedPropertyUrl);
  }

  getReviews(property_id: number): Observable<{ data: IReview }> {
    return this.http.get<any>(this.reviewUrl + '/' + property_id);
  }

  getAllPropertyDetails(): Observable<ISearchPaginationResults> {
    return this.http.get<any>(this.listPropertyUrl);
  }

  getPropertyDetails(params: HttpParams): Observable<IPropertyDetails> {
    //return this.http.get<any>(this.viewPropertyUrl + '/' + property_id);
    return this.http.get<any>(this.viewPropertyUrl, { params: params });
  }


  getPropertyPhotoDetails(property_id: number): Observable<{ data: IPropertyPhotoDetails[] }> {
    return this.http.get<any>(this.propertyPhotoUrl + '/' + property_id);
  }

  searchProperty(data: HttpParams, currentPage: number): Observable<{ data: ISearchPropertyDetails[] }> {
    const searchServiceUrl = this.searchServiceUrl + '?page=' + currentPage;
    return this.http.get<any>(searchServiceUrl, { params: data });
  }
  saveRegistration(userDetails: IListUser): Observable<any> {
    return this.http.post<any>(this.registrationUrl, userDetails);
  }
  loadFalls(): Observable<any> {
    return this.http.get<any>(this.fallHtmlPath);
  }

  loadTemples(): Observable<any> {
    return this.http.get<any>(this.templeHtmlPath);
  }

  loadRestaurants(): Observable<any> {
    return this.http.get<any>(this.restaurantHtmlPath);
  }
  getViewBookingDetail(): Observable<IBookingDetail[]> {
    return this.http.get<any>(this.viewbookingDetailsUrl);
  }
  getBookingDetailsForRoom(master_booking_id: number): Observable<IBookingDetailForRoom[]> {
    // const searchServiceUrl = this.viewbookingDetailsForRoomUrl + '?master_booking_id=' + data;
    // return this.http.get<any>(searchServiceUrl, { params: data });
    return this.http.get<any>(this.viewbookingDetailsForRoomUrl + '/' + master_booking_id);
  }
  cancelAllRoom(master_booking_id: number): Observable<{ data: IPropertyDetails[] }> {
    return this.http.get<any>(this.cancelAllRoomUrl + '/' + master_booking_id);
  }

  cancelOneRoom(booking_id: number): Observable<ICancelMsg> {
    return this.http.get<any>(this.cancelOneRoomUrl + '/' + booking_id);
  }

  deleteBookingsByMasterId(master_booking_id: number, data?:any): Observable<any> {
    return this.http.put<any>(this.deleteBookingsByMasterIdUrl + '/' + master_booking_id, data);
  }
  getUserDetails(): Observable<IUserDetail> {
    return this.http.get<any>(this.userDetailsUrl);
  }
}
