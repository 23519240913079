<div class="jk-form-container" [ngClass]="screenInfo.cssClass">
    
    <!-- Show the search form in landing page for all the screens. For the rest of the screen show only for big screens-->
    @if(landingPage || screenInfo.screenSize !== 'xs'){
        
    <div [ngClass]="{ 'jk-form-fixed-container':!landingPage}">
        <!-- <div class="jk-form-container" [ngClass]="{'test': true, screenInfo.cssClass: true}"> -->

        <!-- Show the form  for all the devices except mobile. If mobile, showMobileForm should be true -->
        <form [formGroup]="formSearchGroup" autocomplete="off">
            <div class="jk-form-fields-container">
                <!-- <div [ngClass]="{'jk-s-sm-field-container': screenInfo.screenSize === 'xs' }" > -->
                <div class="jk-form-field-container jk-ff-property-type">
                    <mat-form-field class="">
                        <mat-label>Property Type</mat-label>
                        <mat-select formControlName="property_type_id">
                            <mat-option value="">All</mat-option>
                            <!-- Even though the propertyTypes is set empty array during initialization it was not available at some point -->
                            @if(propertyTypes.length){
                            <!-- {{propertyTypes}} -->
                            @for (property of propertyTypes; track property.property_type_id) {

                            <mat-option [value]="property.property_type_id">{{property.property_type}}</mat-option>
                            }
                            }
                        </mat-select>
                        @if (formSearchGroup.controls['property_type_id'].errors?.['required']) {
                        <mat-error>Property type is required</mat-error>
                        }

                    </mat-form-field>
                </div>

                <div class="jk-form-field-container jk-ff-date">
                    <mat-form-field>
                        <mat-label>Check-in Date | Check-out Date</mat-label>
                        <mat-date-range-input [rangePicker]="picker" [min]="yesterday">
                            <input matStartDate formControlName="from_date" placeholder="Start date" readonly
                                (focus)="picker.open()">
                            <input matEndDate formControlName="to_date" placeholder="End date" readonly
                                (focus)="picker.open()">
                        </mat-date-range-input>

                        <!-- (dateChange)="defaultNextDate()" -->
                        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <!-- @if (range.controls.start.hasError('matStartDateInvalid')) {
                      <mat-error>Invalid start date</mat-error>
                    }
                    @if (range.controls.end.hasError('matEndDateInvalid')) {
                      <mat-error>Invalid end date</mat-error>
                    } -->
                    </mat-form-field>
                </div>


                <div class="jk-form-field-container jk-ff-room">

                    <!-- Room {{noOfRoom}},Adult {{adultCount}},Children {{childrenCount}} -->
                    <!-- <div (focus)="showRoomDetail()">Room{{noOfRoom}},Adult{{adultCount}},Children{{childrenCount}}</div> -->
                    <mat-form-field>
                        <mat-label>Occupancy</mat-label>
                        <input type="text" matInput placeholder="" (focus)="showRoomDetail()"
                            formControlName="room_details" readonly>
                    </mat-form-field>

                    @if(showRoomDetails){
                    <div class="jk-sf-room-details-container">
                        <div class="jk-room-details-header">
                            <div class="jk-rh-room-label">Rooms</div>
                            <div class="jk-rh-sub-container">
                                <div>Adults<span>Ages 18 or above</span></div>

                                <div>Childrens<span>Ages 0-7</span></div>
                            </div>

                        </div>
                        <div formArrayName="roomDetailsFormArray" class=" jk-sf-chi">
                            <div *ngFor="let formArrayGroup of getRoomDetailsFormArray().controls;let i=index">

                                <div class="jk-room-details" [formGroupName]="i">
                                    <div class="jk-room-label">Room {{i+1}} </div>
                                    <div class="jk-button-row-container">
                                        <div class="jk-button-row">
                                            <div class="jk-button-container jk-adult-container">
                                                <div class="jk-increment-btn" (click)="decrementAdultCount(i)">-
                                                </div>
                                                <input type="text" matInput formControlName="adult_count" readonly>
                                                <div class="jk-increment-btn" (click)="incrementAdultCount(i)">+
                                                </div>
                                            </div>
                                            <div class="jk-button-container jk-children-container">

                                                <div class="jk-increment-btn" (click)="decrementChildrenCount(i)">-
                                                </div>


                                                <input type="text" matInput formControlName="children_count" readonly>
                                                <div class="jk-increment-btn"
                                                    [ngClass]="{'jk-disabled': getFormGroupOfRoomDetailsFormArray(i).get('children_count')?.value === 2}"
                                                    (click)="incrementChildrenCount(i)">+
                                                </div>
                                            </div>
                                        </div>

                                        <div formArrayName="childrenAgeFormArray" class="jk-children-age-container">
                                            <!-- Jag - {{getChildrenAgeFormArray(i).controls.length}} - end -->
                                            <div
                                                *ngFor="let formArrayGroup of getChildrenAgeFormArray(i).controls;let j=index">

                                                <div class="jk-children-age" [formGroupName]="j">

                                                    <mat-form-field>
                                                        <mat-label>{{'USER.AGE' | translate}}</mat-label>
                                                        <mat-select formControlName="age">
                                                            @for (childAge of childrensAge; track childrensAge) {
                                                            <mat-option [value]="childAge">{{childAge}}</mat-option>
                                                            }
                                                        </mat-select>

                                                    </mat-form-field>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="jk-add-room-btn-container">
                            @if(getRoomDetailsFormArray().controls.length > 1){
                            <div> <button mat-raised-button color="primary" (click)="deleteRoom(-1)">Delete
                                    Room</button></div>
                            }@else{
                            <div> <button mat-raised-button color="primary" (click)="deleteRoom(-1)" disabled>Delete
                                    Room</button></div>
                            }
                            <div><button mat-raised-button color="primary" (click)="addRoomsRow(-1)">Add Room</button>
                            </div>
                        </div>

                    </div>
                    }
                </div>
                <!-- @if(isSubmitDisabled===false){ -->
                <div class="jk-form-button-container">
                    <button mat-raised-button color="primary" type="submit" (click)="searchProperties()"
                        [disabled]="!formSearchGroup.valid || !showButton  ">{{
                        'USER.SEARCH' | translate }}
                        <app-spinner *ngIf="showSpinner"></app-spinner>
                    </button>
                </div>
                <!-- } -->
            </div>

        </form>
        <!-- @if( isSubmitDisabled){ -->
        @if(formSearchGroup.errors?.['equalDate'] ){
        <div [ngClass]="landingPage ? 'jk-lp-error-msg': 'jk-error-message'">
            <span> * Please Choose Different Date</span>
        </div>
        }
        <!-- } -->
    </div>
    }
</div>