<div class="jk-tc-container">
    <div class="jk-tc-terms">
        <h1>Terms Of Use</h1>
        <p>These terms constitute a legally binding agreement between Users (“User(s), you, your, customer(s),
            yourself”)
            and <b>Thamira Technologies LLP</b>, and/or its subsidiaries and affiliates (“CourtallamRooms, we, us, our”). They
            govern the use of the website, mobile application, call centers (collectively referred to as
            “CourtallamRooms
            Platform”), enabling the User to connect with us in relation to the services offered through the
            CourtallamRooms
            Platform ("Services").</p>
        <p>Please read these terms carefully before accessing, using, obtaining, or availing any products or Services by
            CourtallamRooms. If you do not agree with these Terms of Use, you may refrain from using the CourtallamRooms
            Platform and/or Services. These conditions must be read in conjunction with any other applicable terms and
            conditions governing the use of the CourtallamRooms Platform and Services.</p>
        <p>CourtallamRooms reserves the right to modify these Terms of Use at any time at its sole discretion. It will
            be
            your responsibility to keep yourself updated with the Terms of Use from time to time; your continued usage
            of
            the CourtallamRooms Platform and Services would be deemed to be an acceptance of these terms and the
            modifications thereto.</p>
    </div>
    <div class="jk-tc-list">
        <div class="lists">
            <ol>
                <li>
                    <div class="jk-tc-scope">
                        <h2>Scope of Services</h2>
                        <p>CourtallamRooms, through the CourtallamRooms Platform, markets CourtallamRooms partnered
                            properties,
                            managed and/or owned by third parties.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-eligibility">
                        <h2>Eligibility to use</h2>
                        <p>You must be at least eighteen (18) years of age or above and capable of entering, performing,
                            and
                            adhering to these terms. While individuals under the age of 18 may utilize/browse the
                            CourtallamRooms
                            Platform, they shall do so only with the involvement, guidance, and supervision of their
                            parents
                            and/or
                            legal guardians, under such parent/legal guardian’s registered account. CourtallamRooms
                            reserves
                            the
                            right to terminate User’s access and refuse to provide User with access to the
                            CourtallamRooms
                            Platform
                            if CourtallamRooms discovers that User is under the age of 18 years.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-registration">
                        <h2>Using the CourtallamRooms Platform: Account Registration and use</h2>
                        <p>CourtallamRooms makes the Services available to you through the CourtallamRooms Platform upon
                            you
                            providing CourtallamRooms certain required User information and having created an account
                            ("Account")
                            through CourtallamRooms ID and password or other log-in ID and password (collectively, the
                            "Account
                            Information"). The CourtallamRooms Platform requires you to register as a User by creating
                            an
                            Account in
                            order to utilize the Services provided. You will be responsible for maintaining the
                            confidentiality of
                            the Account Information and are fully responsible for all activities that occur under Your
                            Account. You
                            agree to (a) immediately notify CourtallamRooms of any unauthorized use of its Account
                            Information or
                            any other breach of security, and (b) ensure that you exit/log out from the account at the
                            end
                            of each
                            session. CourtallamRooms cannot and shall not be liable for any loss or damage arising from
                            your
                            failure
                            to comply with this clause. You may be held liable for losses incurred by CourtallamRooms or
                            any
                            other
                            customer or visitor to the CourtallamRooms Platform due to authorized or unauthorized use of
                            your
                            account as a result of your failure in keeping its Account Information secure and
                            confidential.
                        </p>
                        <p>CourtallamRooms also allows restricted access to the CourtallamRooms Platform for
                            unregistered
                            users. At
                            the time of registration, you shall ensure that the Account Information provided by you in
                            the
                            CourtallamRooms Platform registration form is complete, accurate, and up-to-date. Use of
                            another
                            user’s
                            account information is expressly prohibited.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-services">
                        <h2> CourtallamRooms Services</h2>
                        <div class="jk-tc-services-available">
                            <ol type="a">
                                <li>
                                    <p>CourtallamRooms is primarily responsible for providing a comfortable room stay
                                        and
                                        ensuring the
                                        acceptability of the services as promised by CourtallamRooms to its customers.
                                        CourtallamRooms
                                        has
                                        the right to direct the Hotel owners to provide the room stay services to
                                        CourtallamRooms’s
                                        customers.</p>
                                </li>
                                <li>
                                    <p> CourtallamRooms understands the new age Customers' requirements and accordingly
                                        offers a
                                        wide
                                        variety of hotels at its platform to service its customer.</p>
                                </li>
                                <li>
                                    <p> In case Customers are not able to select the properties from our website, you
                                        can
                                        call
                                        our
                                        customer
                                        support desk and our team will help you find suitable accommodation within your
                                        preferred budget
                                        and
                                        amenities.</p>
                                </li>
                                <li>
                                    <p> If any check-in is denied for a confirmed booking, CourtallamRooms shall ensure
                                        that
                                        the
                                        User is
                                        provided with an alternate accommodation of comparable standards. In an event
                                        where
                                        the
                                        alternate
                                        accommodation could not be provided, CourtallamRooms shall arrange for the
                                        refund of
                                        any
                                        pre-booking
                                        amount collected from the User.</p>
                                </li>
                                <li>
                                    <p> CourtallamRooms also promises its Users a comfortable room stay experience at
                                        all
                                        its
                                        CourtallamRooms partnered rooms with standard amenities mentioned on the
                                        website:
                                    </p>
                                </li>
                                <li>
                                    <p> You can reach out to us by:</p>
                                    <div class="jk-tc-reach">
                                        <ol type="a">
                                            <li>
                                                <p> Writing to <strong>{{email}}</strong></p>
                                            </li>
                                            <li>
                                                <p>Calling us on our guest support helpline. Our guest support team
                                                    will
                                                    ensure
                                                    the
                                                    best
                                                    and resolve your concerns.</p>
                                            </li>
                                        </ol>
                                    </div>
                                </li>
                                <li>
                                    <p>CourtallamRooms stays involved with the Users right from the time when booking is
                                        made
                                        through
                                        the
                                        CourtallamRooms platform and throughout the hotel stays, ensuring that the User
                                        gets
                                        a
                                        comfortable
                                        room stay experience.</p>
                                </li>

                            </ol>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-terms">
                        <h2>Terms specifically applicable to Customer:</h2>
                        <p>Users availing Services and CourtallamRooms Products via the CourtallamRooms Platform
                            (hereinafter
                            referred to as “Customers”) shall be governed by the following terms and conditions:</p>
                        <div class="jk-tc-customer-terms">
                            <ol type="A">
                                <li>
                                    <div class="jk-tc-reservation">
                                        <h3><span>Reservation or booking of Services</span></h3>
                                        <p>The process of booking Services from this CourtallamRooms Platform may
                                            require
                                            you to
                                            disclose
                                            your personal and confidential information. To prevent any possibility of
                                            unauthorized
                                            access to
                                            your confidential information such as name, address, etc., you shall not
                                            use/access
                                            this
                                            site
                                            from unsecure computers, unsecure communication links, unsecure mobile
                                            phones,
                                            or
                                            locations such
                                            as Internet-Cafe(s), Cyber-Cafe(s), and other commercially available
                                            internet-enabled
                                            computers
                                            or computer services.</p>
                                        <p>The User agrees, acknowledges, and confirms that before placing any order on
                                            the
                                            CourtallamRooms
                                            Platform, the User shall check the Service description and price carefully,
                                            and
                                            by
                                            placing an
                                            order for a Service, you agree to be bound by these terms, including the
                                            Services’
                                            description.
                                            You shall only place the order after fully satisfying yourself with the
                                            price,
                                            description, look
                                            as has been displayed on CourtallamRooms Platform. Relying on declarations,
                                            confirmations,
                                            information, and obligations made/undertaken by the User in accordance with
                                            the
                                            terms,
                                            and
                                            believing the same to be true and acting on the faith thereof,
                                            CourtallamRooms
                                            has
                                            agreed to
                                            provide the Services to the User as per the terms mentioned herein. In
                                            particular,
                                            the
                                            User does
                                            hereby agree, promise, declare, confirm, covenant, undertake and represent &
                                            warrant
                                            to
                                            CourtallamRooms:</p>
                                        <div class="jk-tc-booking">
                                            <ol type="a">
                                                <li>
                                                    <p>The User is not under any legal or other deficiency which
                                                        prevents/may
                                                        prevent
                                                        the User
                                                        from: (i) entering into a valid contract under the applicable
                                                        laws; and
                                                        (ii)
                                                        making
                                                        valid
                                                        payment to CourtallamRooms for Services ordered by the User.</p>
                                                </li>
                                                <li>
                                                    <p> In the event of detecting any suspicious activity from User’s
                                                        account,
                                                        CourtallamRooms
                                                        reserves the right to cancel all pending and future orders owing
                                                        to any
                                                        such
                                                        incident
                                                        without any liability to the User.</p>
                                                </li>
                                                <li>
                                                    <p> In a credit card transaction; you must use your own credit card
                                                        for
                                                        which
                                                        User
                                                        has full
                                                        right & authority to validly use such credit card for making
                                                        payment to
                                                        CourtallamRooms.
                                                        CourtallamRooms shall not be liable for any credit card fraud.
                                                        The
                                                        liability
                                                        to
                                                        use a
                                                        card
                                                        fraudulently will be on the User, and the onus to 'prove
                                                        otherwise'
                                                        shall be
                                                        exclusively
                                                        on
                                                        the User.</p>
                                                </li>
                                                <li>
                                                    <p> While using the CourtallamRooms Platform User shall at all times
                                                        strictly
                                                        comply
                                                        with
                                                        the
                                                        payment procedure and the terms mentioned herein in their
                                                        entirety.</p>
                                                </li>
                                                <li>
                                                    <p> Your use of the Services shall be deemed that you are fully
                                                        satisfied
                                                        with
                                                        the
                                                        description,
                                                        look, and design of the accommodation and usage fee of the
                                                        accommodation
                                                        as
                                                        has
                                                        been
                                                        displayed on CourtallamRooms Platform.</p>
                                                </li>
                                                <li>
                                                    <p> The User agrees and acknowledges that in the CourtallamRooms
                                                        Platform
                                                        all
                                                        Services are
                                                        offered only at the sole discretion of CourtallamRooms.</p>
                                                </li>


                                            </ol>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="jk-tc-payment-usage-fee">
                                        <h3><span>Payment & Usage Fee</span></h3>
                                        <p>You hereby agree and understand that you will have to pay the usage fee as
                                            mentioned
                                            on
                                            the
                                            CourtallamRooms Platform for the concerned Services via the payment mode
                                            provided on
                                            the
                                            CourtallamRooms Platform post which User’s requested reservation will be
                                            confirmed.
                                            Once
                                            the
                                            reservation has been confirmed, CourtallamRooms will debit the usage fee
                                            from
                                            the
                                            payment mode
                                            selected by the User. The User agrees and acknowledges that the payment
                                            procedure
                                            may
                                            call for
                                            and require additional verification of or information from the User, and the
                                            User
                                            undertakes to
                                            provide complete, correct and proper information.</p>
                                        <p>CourtallamRooms uses third party payment providers to receive payments from
                                            User.
                                            CourtallamRooms
                                            is not responsible for delays or erroneous transaction execution or
                                            cancellation
                                            of
                                            reservation
                                            due to payment issues. CourtallamRooms takes utmost care to work with third
                                            party
                                            payment
                                            providers but does not control their systems, processes, technology, and
                                            workflows,
                                            hence cannot
                                            be held responsible for any fault at the end of payment providers.</p>
                                        <p>User making payments for Services provided via the CourtallamRooms Platform
                                            would
                                            be
                                            making
                                            payments to the entities mentioned as per the link mentioned in the tab
                                            named
                                            Payment
                                            for
                                            Services.</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="jk-tc-responsibility">
                                        <h3><span>Responsibility</span></h3>
                                        <p>You are requested to kindly take due care of all your personal valuables and
                                            belongings.
                                            CourtallamRooms and its employees shall NOT be responsible for any loss,
                                            theft,
                                            or
                                            damage to
                                            your personal valuables and belongings.</p>
                                        <p>User/Guest verification on the Internet is difficult, and we do not assume
                                            any
                                            responsibility for
                                            the confirmation of any Guest’s identity. For transparency and fraud
                                            prevention
                                            purposes, and as
                                            permitted by applicable laws, we may, but have no obligation to:</p>
                                        <div class="jk-tc-checking">
                                            <ol type="a">
                                                <li>
                                                    <p>Ask Guests to provide a form of government identification or
                                                        other
                                                        information.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>Undertake additional checks designed to help verify the
                                                        identities or
                                                        backgrounds
                                                        of
                                                        Guests.</p>
                                                </li>
                                                <li>
                                                    <p> Screen Guests against third-party databases or other sources and
                                                        request
                                                        reports
                                                        from
                                                        service providers.</p>
                                                </li>
                                                <li>
                                                    <p> Where we have sufficient information to identify a Guest, obtain
                                                        reports
                                                        from
                                                        public
                                                        records
                                                        of criminal convictions or sex offender registrations or an
                                                        equivalent
                                                        version
                                                        of
                                                        background
                                                        or registered sex offender checks in your local jurisdiction (if
                                                        available).
                                                    </p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-usage">
                        <h2>Usage Terms</h2>
                        <p>The CourtallamRooms Platform provides information, materials, and services, but it may
                            unintentionally contain inaccuracies, typographical errors, or outdated information.
                            CourtallamRooms does not guarantee the accuracy, completeness, or correctness of data,
                            information, products, or services on the platform. The company disclaims any warranties,
                            whether express or implied, regarding the quality, reliability, availability, timeliness, or
                            uninterrupted operation of its services and products.</p>
                        <p>CourtallamRooms is not liable for delays or the inability to use the platform, unrelated
                            functionalities, provision or failure to provide functionalities, or any information
                            obtained
                            through the platform. The company is also not responsible for periodic maintenance-related
                            non-availability or unplanned suspensions due to technical reasons or beyond its control.
                            Users
                            download material or data at their own risk, and CourtallamRooms accepts no liability for
                            errors
                            or omissions in the information provided.</p>
                        <p>Access to the CourtallamRooms Platform may be subject to conditions like completing a
                            verification process, meeting specific criteria, ratings, reviews, or a user's booking and
                            cancellation history. Continuous and uninterrupted availability of the platform cannot be
                            guaranteed due to the nature of the Internet. CourtallamRooms may restrict access or
                            features
                            for capacity, security, maintenance, and may introduce new services.</p>
                        <p>Features enabling user authorization for account actions or third-party bookings do not
                            require
                            credential sharing. CourtallamRooms emphasizes that no third party is authorized to request
                            user
                            credentials, and users should not seek the credentials of others. The platform is dynamic,
                            subject to enhancements, modifications, and the introduction of new services by
                            CourtallamRooms.
                        </p>
                        <p>In summary, CourtallamRooms sets usage terms, disclaims warranties, and limits liability. The
                            platform's availability is not guaranteed, and the company may introduce new services and
                            features, but users should avoid sharing credentials with third parties.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-prohibited">
                        <h2>Prohibited Content:</h2>
                        <p>To use CourtallamRooms, Users must avoid unlawful or unauthorized actions. Violating terms
                            leads
                            to immediate termination, with CourtallamRooms having the right to block access without
                            notice.
                            Users should provide accurate registration data, avoiding fraud or stolen cards. Content
                            must
                            respect third-party rights and exclude harmful elements. Prohibited activities include
                            unauthorized use, deceptive practices, and disrupting security. Users cannot impersonate,
                            sell
                            profiles, or harm others. Competing with CourtallamRooms for revenue or violating platform
                            measures is forbidden. Users must follow ethical conduct, refraining from actions against
                            terms.
                            Non-compliance may lead to access denial or termination.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-communications">
                        <h2>Communications:</h2>
                        <p>When you use the CourtallamRooms Platform, you agree and understand that you are
                            communicating
                            with CourtallamRooms through electronic records and you consent to receive communications
                            via
                            electronic records from CourtallamRooms periodically and as and when required. </p>
                        <p>CourtallamRooms may communicate with you by email or by such other mode of communication,
                            electronic or otherwise. You specifically agree that CourtallamRooms shall not be
                            responsible
                            for unauthorized access to or alteration of your transmissions or data, any material or data
                            sent or received or not sent or received. Further, CourtallamRooms will make best efforts to
                            safeguard the confidentiality of your personally identifiable information available with it,
                            but
                            transmissions made by means of the internet cannot be guaranteed or made absolutely secure.
                        </p>
                        <p>By using this CourtallamRooms Platform, you agree that CourtallamRooms shall not be liable
                            for
                            disclosure of your information due to errors in transmission or unauthorized acts of third
                            parties. Without prejudice to the foregoing, you agree that CourtallamRooms shall not be
                            held
                            liable or responsible for 'phishing attacks' on you. You may accept or decline the cookies
                            when
                            you access CourtallamRooms Platform. It is the User’s responsibility to set his / her browser to
                            alert
                            him / her to accept or to reject cookies.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-links">
                        <h2>Third Party Links:</h2>
                        <p>This CourtallamRooms Platform may, at CourtallamRooms sole discretion, contain links to
                            CourtallamRooms Platforms owned and maintained by persons or entities other than
                            CourtallamRooms. CourtallamRooms may also provide links to the other sites for the purpose
                            of
                            enabling the Customer to make payment to CourtallamRooms. Any of the foregoing links do not
                            constitute an endorsement by CourtallamRooms of any such sites and are provided only as a
                            convenience. CourtallamRooms is not responsible for the content or links displayed on such
                            sites. CourtallamRooms is not responsible for the privacy practices of such sites which
                            CourtallamRooms does not own, manage or control. CourtallamRooms does not regularly review,
                            and
                            makes no warranty or representation regarding materials posted, or Services or services
                            offered,
                            on the sites to which this CourtallamRooms Platform may be linked and CourtallamRooms shall
                            not
                            be responsible for any deficiency thereof. CourtallamRooms does not endorse any or all of
                            the
                            materials, Services, and services available on such linked sites, and CourtallamRooms
                            expressly
                            disclaims responsibility for the contents of any linked site, the accuracy of any
                            information
                            contained in a linked site, and the quality of the Services and services offered at any
                            linked
                            site. Any decision to view the contents of any linked site is solely the responsibility of
                            User
                            and is made at User’s own risk.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-intellectual-property">
                        <h2>Intellectual Property:</h2>
                        <p>CourtallamRooms Platform's content, including visuals, text, logos, and more, is protected
                            and
                            owned by CourtallamRooms. Users contributing content, termed "CourtallamRooms Intellectual
                            Property," grant CourtallamRooms a perpetual, royalty-free license to use, reproduce, and
                            distribute it. Users waive claims to the contrary. By providing content, users confirm
                            ownership
                            and assert that its use by CourtallamRooms won't infringe on third-party rights. This
                            agreement
                            safeguards CourtallamRooms's right to utilize user-contributed content while assuring users
                            that
                            their rights are respected. Users are restricted from modifying, reproducing, or
                            commercially
                            exploiting CourtallamRooms IP. This protects the platform's creative assets, promoting fair
                            use
                            and collaboration while maintaining CourtallamRooms's authority over the content.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-privacy">
                        <h2>Privacy:</h2>
                        <p>Please refer to the privacy policy which will also govern your use of CourtallamRooms
                            Platform
                            and/or Services which can be accessed here: <a
                                [routerLink]="['/new-user/privacy-policy']">Privacy
                                Policy Link</a></p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-indemnification">
                        <h2>Indemnification:</h2>
                        <p>Without prejudice to and in addition to any other remedies, reliefs or legal recourses
                            available
                            to CourtallamRooms herein or any applicable laws or otherwise, User agrees to indemnify,
                            defend
                            and hold CourtallamRooms harmless including but not limited to its affiliate, agents and
                            employees from and against any and all losses, liabilities, claims, damages, demands, costs
                            and
                            expenses (including legal fees and disbursements in connection therewith and interest
                            chargeable
                            thereon) asserted against or incurred by CourtallamRooms that arise out of or related to
                            your
                            use or misuse of the Website, any violation by you of these terms and conditions, or any
                            breach
                            of representations, warranties and covenants made by you herein.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-no-warranty">
                        <h2>No Warranty:</h2>
                        <p>CourtallamRooms provides services "as is" without warranties, express or implied. There's no
                            guarantee that the platform or services will meet user requirements, be uninterrupted,
                            secure,
                            or error-free. CourtallamRooms is not liable for any delays in access. Any advice obtained
                            from
                            CourtallamRooms doesn't create additional warranties. In case of online payment disputes,
                            CourtallamRooms is the primary contact for management, refunds, and customer support. Users
                            can
                            seek CourtallamRooms's help to resolve disputes with Partners, though
                            CourtallamRooms
                            isn't a party to disputes unrelated to its services. This clarifies CourtallamRooms's role
                            and
                            responsibility in handling payment-related issues.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-limitation">
                        <h2>Limitation of Liability:</h2>
                        <p>CourtallamRooms is not liable for various damages, including direct, indirect, punitive, and
                            consequential damages, arising from platform use. Even if advised of potential damages,
                            CourtallamRooms liability is limited to the amount equivalent to the paid product or
                            service.
                            You waive claims or causes of action related to the platform, communication, or terms after
                            one
                            year from the incident. If CourtallamRooms is liable under the law, recovery is made from
                            fees/revenue, remitted to the Partner. This arrangement safeguards CourtallamRooms
                            from
                            extensive liability, defining clear limitations and ensuring fair compensation. Users are
                            encouraged to resolve disputes promptly, maintaining the platform's integrity and user
                            trust.
                        </p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-modification">
                        <h2>Modification of these Terms:</h2>
                        <p>CourtallamRooms reserves the right to modify these Terms at any time in accordance with this
                            provision.</p>
                    </div>
                </li>
                <li>
                    <div class="jk-tc-provisions">
                        <h2>General Provisions:</h2>
                        <p>This agreement is governed by Indian laws, jurisdiction. Users accept this jurisdiction.
                            CourtallamRooms failure, delay, or forbearance in exercising rights under this agreement
                            doesn't waive them, and partial exercises don't prevent future enforcement. Both parties
                            agree
                            that individual provisions in this agreement are severable. If one or more provisions become
                            unenforceable, it doesn't impact the enforceability of other provisions or the overall
                            agreement. This ensures clarity on legal jurisdiction, waiver considerations, and the
                            agreement's structural integrity in case of unenforceable provisions.</p>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</div>