import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { IScreensize } from '../../../app';
import { PropertyService } from '../../../shared/services/property.service';
import { ScreensizeDetectService } from '../../../shared/services/screensize-detect.service';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { IPropertyTypes, ISearchFieldsValues, ISearchFilteredItems, ISearchSelectedItems, ISearchSelectedItemsKeys } from '../../property';

import { JsonPipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MasterDataService } from '../../../shared/services/master-data.service';

import { FormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import moment from 'moment';
import { BrowserStorageService } from '../../../shared/services/storage.service';


export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};



@Component({
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        TranslateModule,
        RouterLink,
        RouterOutlet,
        MatIconModule,
        MatSelectModule,
        SpinnerComponent,
        ReactiveFormsModule,
        MatButtonModule,
        JsonPipe,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDividerModule,
        FormsModule,
        MatSlideToggleModule],
    styleUrl: './search-form.component.scss',

    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]

})




export class SearchFormComponent implements OnInit {
    public show: boolean = false;
    public showButton: boolean = true;
    @Output() searchFiltersReceived = new EventEmitter<any>();
    @Input() resultsReceived: boolean = false;
    @Input() landingPage?: boolean = false;
    public isSubmitDisabled!: boolean;
    //disabled the yesterday date in range datepicker
    @Input() min: any;
    public yesterday = new Date();

    // MASTER DATA


    public propertyTypes: IPropertyTypes[] = [];
    public screenInfo!: IScreensize;
    public showMobileForm: boolean = true; // This is used to display the link to show/hide the form In mobile version
    public showSpinner: boolean = false;
    public showRoomDetails: boolean = false;
    public childrensAge = [1, 2, 3, 4, 5, 6, 7];

    MAX_ADULT_PER_ROOM = 10;
    MAX_CHILDREN_PER_ROOM = 2;

    public get userDetailsFormArray() {
        return this.formSearchGroup.controls["userDetailsFormArray"] as FormArray;
    }

    searchFilteredtems: ISearchFilteredItems = {
        propertyTypes: []
    }

    /* This will hold the selected object which can be used to generate the chips in html */
    searchSelectedItems: ISearchSelectedItems = {
        propertyTypes: []
    }

    /* This will hold only the ids of the selected items which can be used to highlight the selected options */
    searchSelectedItemsKeys: ISearchSelectedItemsKeys = { propertyTypes: [] }

    formSearchGroup!: FormGroup;
    constructor(
        private readonly fb: FormBuilder,
        private propertyService: PropertyService,
        private readonly translate: TranslateService,
        private router: Router,
        private screensizeDetectService: ScreensizeDetectService,
        private readonly masterDataService: MasterDataService,
        private browserStorageService: BrowserStorageService,

    ) {
        //disabled the yesterday date in range datepicker
        this.yesterday.setDate(this.yesterday.getDate() - 0);

        this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
            this.screenInfo = screenInfo;
        });

        this.getMasterData()


    }

    ngOnInit() {
        // this.isSubmitDisabled = false

        this.setFormGroup();
        this.setDefaultNextDate();
        this.addRoomsRow(0);
        // Dont do this for landing page. Set only for user search page
        if (this.router.url === '/user/search') {
            this.setFormValuesFromLandingPage();
        }
        this.countOfRoomDetails();
        
    }

    getMasterData() {
        this.masterDataService.propertyType$.subscribe(propertyTypes => {
            this.propertyTypes = propertyTypes;
        })

    }

    ngOnChanges(changes: SimpleChanges) {
        // only run when property "data" changed
        if (changes['resultsReceived']) {
            this.resultsReceived = changes['resultsReceived'].currentValue;
            /* Hide the spinner after the result has come for the serach query */
            if (this.resultsReceived) {
                this.showSpinner = false;
                // Hide the form if it is mobile
                this.showMobileForm = false;
            }
            this.showButton = true;

        }




    }

    // Get Room Details form array 
    public getRoomDetailsFormArray() {
        return this.formSearchGroup.controls["roomDetailsFormArray"] as FormArray;
    }
    // Get form group which is inside the room details form array
    public getFormGroupOfRoomDetailsFormArray(i: number) {
        return this.getRoomDetailsFormArray().controls[i] as FormGroup;
    }
    public getChildrenAgeFormArray(i: number) {
        return this.getFormGroupOfRoomDetailsFormArray(i).controls["childrenAgeFormArray"] as FormArray;
    }

    setFormGroup() {
        this.formSearchGroup = this.fb.group({
            property_type_id: [''],
            from_date: [new Date(), [Validators.required,]],
            to_date: [new Date(), [Validators.required,]],
            room_details: [''],
            // private_falls:[false],
            roomDetailsFormArray: this.fb.array([]),

        });
        this.formSearchGroup.setValidators(this.equalDateValidator());

    }

    public addRoomsRow(i: number) {
        this.getRoomDetailsFormArray().push(this.createRoomsRowGroup());
        this.countOfRoomDetails();

    }

    private createRoomsRowGroup(): FormGroup {
        return new FormGroup({
            adult_count: new FormControl(2, [Validators.required]),
            children_count: new FormControl(0, [Validators.required]),
            childrenAgeFormArray: this.fb.array([])
        })
    }

    private createChildrenAgeFormGroup(childCount?: number): FormGroup {
        // childCount will come when  user searches from landing page
        return new FormGroup({
            age: new FormControl((childCount) ? childCount : '', [Validators.required]),
        })
    }

    MinMaxValidator(minControl: string, maxErrorKey: string): ValidatorFn {
        return (maxControl: AbstractControl): ValidationErrors | null => {

            const maxControlValue = maxControl.value;
            let minControlValue = null;

            if (this.formSearchGroup) {
                minControlValue = this.formSearchGroup.controls[minControl].value;
            }
            // Variable should be represented within [] for a object key
            return minControlValue !== null && maxControlValue !== null && (minControlValue > maxControlValue)
                ? { [maxErrorKey]: true }
                : null;
        };
    }

    //To validate for from & to date same or not  
    equalDateValidator(): ValidatorFn {

        return (group: AbstractControl): ValidationErrors | null => {
            const formSearchGroup = group as FormGroup;
            const fromDate = moment(formSearchGroup.get('from_date')?.value).format('YYYY-MM-DD');
            const toDate = moment(formSearchGroup.get('to_date')?.value).format('YYYY-MM-DD');
            if (fromDate === toDate) {
                this.show = true;
                return { equalDate: true }

            } else {
                this.show = false;
                return null
            }
        }
    }


    public select($event: MatAutocompleteSelectedEvent,
        filterInput: HTMLInputElement, formGroupName: string, attributeName: 'propertyTypes') {
        this.searchSelectedItems[attributeName] = [...this.searchSelectedItems[attributeName], $event.option.value]; // Append it to selected field array1
        this.searchSelectedItemsKeys[attributeName].push($event.option.value.property_type_id);
        this.searchFilteredtems[attributeName] = this.propertyTypes;
    }

    searchProperties(nextPage: number = 1) {

        this.showButton = false;
        this.showRoomDetails = false;
        // if (this.authService.isDebugMode()) {
        //     this.authService.displayConsoleMessage(this.formSearchGroup.value);s
        // }
        this.showSpinner = true;

        let propertySearchDetailsJson!: ISearchFieldsValues;

        // propertySearchDetailsJson = { ...propertySearchDetailsJson, ...propertyGenderJson }
        propertySearchDetailsJson = this.formSearchGroup.value;
        propertySearchDetailsJson.from_date = moment(this.formSearchGroup.get('from_date')?.value).format('YYYY-MM-DD');
        propertySearchDetailsJson.to_date = moment(this.formSearchGroup.get('to_date')?.value).format('YYYY-MM-DD');

        // Check if one of the fields in the form group has been modified

        // NEED TO ENABLE THE BELOW LINE.. JUST COMMENTED OUT FOR TESTING
        //if (this.formSearchGroup.dirty && this.formSearchGroup.valid) {
        if (this.formSearchGroup.valid) {

            // Check if user searches from landing page
            if (this.landingPage) {
                // Add the property landingPage in the searchJson to inform that user has started search from the landing page
                //propertySearchDetailsJson = { ...propertySearchDetailsJson, ...{ landingPage: true } }
                this.browserStorageService.set('searchJson', JSON.stringify(propertySearchDetailsJson));
                this.router.navigate(['/user/search']);
            } else {
                /* 
                
                When search form is called from search component, this will emit the json to search component. 
                But when this from used as bottomsheet, the will emit the json to searchformBottomSheet component from where we have to again pass the value to search component.
    
                */
                this.browserStorageService.set('searchJson', JSON.stringify(propertySearchDetailsJson));
                this.searchFiltersReceived.emit(propertySearchDetailsJson);

            }

        }

    }


    //To Increament the Adult Count
    incrementAdultCount(i: number) {

        let currentValue = +this.getFormGroupOfRoomDetailsFormArray(i).get('adult_count')?.value;
        if (currentValue < this.MAX_ADULT_PER_ROOM) {
            currentValue += 1;
            this.getFormGroupOfRoomDetailsFormArray(i).get('adult_count')?.setValue(currentValue);
            this.countOfRoomDetails();
        }
    }

    // To Decreament the Adult Count
    decrementAdultCount(i: number) {
        let currentValue = +this.getFormGroupOfRoomDetailsFormArray(i).get('adult_count')?.value;
        if (currentValue >= 1) {
            if (currentValue !== 1) {
                currentValue -= 1;
            }
            this.getFormGroupOfRoomDetailsFormArray(i).get('adult_count')?.setValue(currentValue);
            this.countOfRoomDetails();
        }
    }

    // To Increament the Children Count
    incrementChildrenCount(i: number) {
        let currentValue = +this.getFormGroupOfRoomDetailsFormArray(i).get('children_count')?.value;
        if (currentValue < this.MAX_CHILDREN_PER_ROOM) {
            currentValue += 1;
            this.getFormGroupOfRoomDetailsFormArray(i).get('children_count')?.setValue(currentValue);
            // if (this.getChildrenAgeFormArray(i).get('children_count')?.value ) {
            this.getChildrenAgeFormArray(i).push(this.createChildrenAgeFormGroup())
            // }
            this.countOfRoomDetails();
        }

    }

    // To Decreament the Children Count
    decrementChildrenCount(i: number) {
        let currentValue = +this.getFormGroupOfRoomDetailsFormArray(i).get('children_count')?.value;
        if (currentValue >= 1) {
            currentValue -= 1;
            this.getFormGroupOfRoomDetailsFormArray(i).get('children_count')?.setValue(currentValue);
            this.getChildrenAgeFormArray(i).removeAt(- 1);
            this.countOfRoomDetails();
        }
    }

    //To Delete the Room
    deleteRoom(i: number) {

        if (this.getRoomDetailsFormArray) {
            this.getRoomDetailsFormArray().removeAt(-1);
        }
        this.countOfRoomDetails();
    }

    //show room details
    showRoomDetail() {
        this.showRoomDetails = !this.showRoomDetails;
        // this.opens = true;
    }

    countOfRoomDetails() {
        let adultCount = 0;
        let childrenCount = 0;
        let noOfRoom = this.getRoomDetailsFormArray().length;
        let roomDetails = '';
        for (var roomDetailsFormGroup of this.getRoomDetailsFormArray().controls) {
            adultCount += +roomDetailsFormGroup.get('adult_count')?.value;
            childrenCount += +roomDetailsFormGroup.get('children_count')?.value;
        }

        // roomDetails = 'Room ' + noOfRoom + ', Adult ' + adultCount;
        // roomDetails = +noOfRoom + ' Rooms, ' + adultCount + ' Adult, ' ;
        roomDetails = +noOfRoom + ' Room' + (noOfRoom !== 1 ? 's' : '') + ', ' + adultCount + ' Adult' + (adultCount !== 1 ? 's' : '');
        if (childrenCount) {
            // roomDetails += ', Children ' + childrenCount
            //roomDetails += + childrenCount + ' Children ' 
            roomDetails += ', ' + childrenCount + ' Child' + (childrenCount !== 1 ? 'ren' : '')
        }
        this.formSearchGroup.get('room_details')?.setValue(roomDetails)
    }

    setFormValuesFromLandingPage() {

        let searchJson = this.browserStorageService.get('searchJson');
        // Check if value set in the localstorage from landing page
        if (searchJson) {
            const formGroupValue = JSON.parse(searchJson);
            this.formSearchGroup.get('room_details')?.setValue(formGroupValue.room_details);
            this.formSearchGroup.get('from_date')?.setValue(moment(formGroupValue.from_date));
            this.formSearchGroup.get('to_date')?.setValue(moment(formGroupValue.to_date));
            this.formSearchGroup.get('property_type_id')?.setValue(formGroupValue.property_type_id);

            // check the no of rows in the rooms details array and iterate over the array
            for (let i in formGroupValue.roomDetailsFormArray) {
                // Add room row if i > 0 as we have already added one row in ngoninit
                if (Number(i) > 0) {
                    this.addRoomsRow(Number(i));
                }
                this.getFormGroupOfRoomDetailsFormArray(Number(i)).get('adult_count')?.setValue(formGroupValue.roomDetailsFormArray[i].adult_count);
                this.getFormGroupOfRoomDetailsFormArray(Number(i)).get('children_count')?.setValue(formGroupValue.roomDetailsFormArray[i].children_count);

                // get the childrens count for each row
                const childCount = formGroupValue.roomDetailsFormArray[i].children_count;
                if (childCount) {
                    for (let j = 0; j < childCount; j++) {
                        const age = formGroupValue.roomDetailsFormArray[i].childrenAgeFormArray[j].age;
                        this.getChildrenAgeFormArray(Number(i)).push(this.createChildrenAgeFormGroup(age));
                    }
                }
            }
            // Need to count the room details again as we have dynaically added row using addRoomRow method which reset the room details value inside the loop.
            this.countOfRoomDetails();
            this.searchProperties();
        }
    }

    setDefaultNextDate() {
        let setTomorrowDate = '';
        let currentDate = ''
        currentDate = this.formSearchGroup.get('from_date')?.value;
        setTomorrowDate = moment(currentDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
        setTomorrowDate = moment(setTomorrowDate).format('YYYY-MM-DD');
        const toDate = this.formSearchGroup.get('to_date')?.setValue(setTomorrowDate);

    }

}