<div class="jk-vp-summary-bar" [ngClass]="screenInfo.cssClass">
    @if(showCart){
    <div class="jk-vp-cart-details">
        <div class="jk-vp-title">Book Room</div>

        <div class="jk-vp-summary-container">
            <div class="jk-vp-date-container">
                <div class="jk-vp-row jk-vp-date">
                    <label>Check-in</label>
                    <div class="jk-vp-count">{{this.displayFromDate}}</div>
                </div>
                <div class="jk-vp-row jk-vp-date">
                    <label>Check-out</label>
                    <div class="jk-vp-count">{{this.displayToDate}}</div>
                </div>
            </div>

            <div class="jk-vp-row jk-vp-row-flex jk-room-info-container">
                <div class="jk-room-info">
                    <label>Rooms</label>
                    <div class="jk-vp-count">{{this.searchValuesArr.room_count}}</div>
                </div>
                <div class="jk-room-info">
                    <label>Adults</label>
                    <div class="jk-vp-count">{{this.searchValuesArr.adult_count}}</div>
                </div>

                <div class="jk-room-info">
                    <label>Children</label>
                    <div class="jk-vp-count">{{this.searchValuesArr.children_count}}</div>
                </div>

            </div>
            @if(selectedRoomDetails.length){
            <div class="jk-vp-selected-rooms-container">
                <div class="jk-vp-selected-room-title"> Selected Rooms</div>
                <div class="jk-vp-caption">
                    <div class="jk-vp-days-header jk-vp-qty">Qty X Price</div>

                    <div class="jk-vp-days-header">For {{this.noOfDays}} Days</div>
                </div>

                @for(selectedRoomDetail of selectedRoomDetails; track selectedRoomDetail.bed_type_id){
                <div class="jk-vp-selected-rooms">
                    <div class="jk-vp-room-type-container">
                        <label class="jk-vp-room-type">{{selectedRoomDetail.bed_type_id | bedType }}
                            <!-- - {{selectedRoomDetail.category_id}} -->
                        </label>
                        <div class="jk-vp-room-count"> <span
                                class="jk-vp-count">{{selectedRoomDetail.quantity}}</span><span>
                                X {{selectedRoomDetail.discounted_price/100}}</span></div>

                    </div>

                    <div class="jk-vp-room-price">&#8377;{{(selectedRoomDetail.quantity *
                        (selectedRoomDetail.discounted_price/100
                        )*
                        this.noOfDays)}}
                    </div>
                </div>
                <div class="jk-vp-remove-icon" (click)="showConfirmationDialog(selectedRoomDetail.bed_type_id)"> Remove
                </div>

                }

                <!-- Check whether the gst is included in the price only for the first room. Assumption is that all the rooms in a property will have the same gst (either inclusive or exclusive) -->
                @if(!selectedRoomDetails[0].is_gst_included){
                <!-- @if(!selectedRoomDetail.is_gst_included){ -->
                <div class="jk-vp-selected-rooms">
                    <div class="jk-vp-room-type-container">
                        <label class="jk-vp-room-type">GST ({{this.gstPercentage}}%)</label>
                    </div>

                    <div class="jk-vp-room-price">&#8377;{{this.gst}}</div>
                </div>
                }@else {
                Gst is included in the price
                }

                <div class="jk-vp-selected-rooms">
                    <div class="jk-vp-room-type-container">
                        <label class="jk-vp-room-type">Platform and Service Charges</label>
                    </div>

                    <div class="jk-vp-room-price">&#8377;{{ this.platformCharges }}</div>
                </div>

                <div class="jk-vp-selected-rooms">
                    <div class="jk-vp-room-type-container">
                        <label class="jk-vp-room-type">Total</label>
                    </div>

                    <div class="jk-vp-room-price jk-vp-price-total">&#8377;{{this.totalPriceWithGst}}</div>
                </div>

            </div>

            <button class="jk-vp-pay-button" mat-raised-button color="primary" (click)="processPayment()"
                [disabled]="!showPayButton || getSelectedRoomCount() !== searchValuesArr.room_count">Pay
                <span>&#8377;{{this.totalPriceWithGst}}</span>
                <app-spinner *ngIf="showSpinner"></app-spinner>
            </button>
            }
            <div class="jk-vp-selected-room-hint">{{ noOfSelectedRoom }} of {{searchValuesArr.room_count}}
                room(s) selected</div>
        </div>


    </div>
    }
    @else if (showLogin) {
    <div class="jk-vp-login">
        <app-login [cartPage]="true" (userLoggedIn)="setUserAsLoggedIn()"></app-login>
        <div class="jk-vp-login-with-otp"><a [routerLink]="'/new-user/login/otp'">Login with OTP</a>
            <a [routerLink]="'/new-user/login/otp'">Forgot Password?</a>
        </div>
        <div class="jk-vp-new-customer"><mat-label>New customer? <a (click)="showRegistraionForm()">Create Your
                    Account.</a></mat-label></div>
        <div class="jk-vp-back-to-cart"> <a (click)="showCartForm()">Back to Cart</a></div>
    </div>
    }
    @else if (showRegistraion) {
    <div class="jk-vp-registration">
        <app-registration [cartPage]="true" (userRegistered)="showLoginForm()"></app-registration>
        <div class="jk-vp-sign-in"><mat-label>Already have an account? <a (click)="showLoginForm()">Sign
                    in</a></mat-label></div>
        <div class="jk-vp-back-to-cart"> <a (click)="showCartForm()">Back to Cart</a></div>

    </div>
    }@else if(showPaymentProcessingMessage){
    <div>Redirecting to Payment page</div>
    }
</div>

@if(showOverlay){
<div class="jk-overlay">
    @if(showPaymentSuccessMsg){
        Payment Received. Processing your booking. Please wait..
    }

    @if(showCancelMsg){
        Cancelling Your booking..
    }
    <app-spinner></app-spinner>
</div>

}