import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
@Component({
  selector: 'app-success-page',
  standalone: true,
  imports: [
    MatIconModule,
    RouterLink,
    RouterOutlet],
  templateUrl: './success-page.component.html',
  styleUrl: './success-page.component.scss'
})
export class SuccessPageComponent {
  public cancel!: string;
  constructor(

    private router: Router,
    private readonly route: ActivatedRoute

  ) {
    const cancel = this.route.snapshot.paramMap.get('cancel');
    console.log(cancel)
    if (cancel) {
      this.cancel = cancel;
    }
    console.log(this.cancel)
  }
  openBookingHistoryPage() {
    this.router.navigate(['/user/booking-details']);
  }
}
