import { Component } from '@angular/core';
import { RouterOutlet, RouterLink } from '@angular/router';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PropertyService } from '../../shared/services/property.service';
import { MatButtonModule } from '@angular/material/button';
import { IPropertyDetails, IReview } from '../property';
import { ReviewListComponent } from './review-list/review-list.component';


@Component({
  selector: 'app-review',
  standalone: true,
  imports: [RouterOutlet, RouterLink, ReactiveFormsModule, MatInputModule, MatFormFieldModule, CommonModule, MatButtonModule, ReviewListComponent],
  templateUrl: './review.component.html',
  styleUrl: './review.component.scss'
})
export class ReviewComponent {
  public propertyDetails!: IReview;
  public propertyId: number = 10;
  public reviewFormGroup!: FormGroup
  constructor(private fb: FormBuilder, private propertyService: PropertyService) {

    this.setFormFields();
  }


  setFormFields() {
    this.reviewFormGroup = this.fb.group({
      review: [''],
    });
  }

  getReviews() {
    const property_id = 11;

    this.propertyService.getReviews(property_id).subscribe(result => {
      console.log(result.data);
      this.propertyDetails = result.data;

    });

  }


  onSubmit() {
    this.getReviews();
    console.log(this.reviewFormGroup.value);
  }



}
