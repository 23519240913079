import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICancelReasons, ICountry, IFloors, IState } from '../master-data/master-data';
// import { MasterDataDisplayColumns } from '../../admin/admin';

@Injectable({ providedIn: 'root' })
export class MasterDataService {

  private bedTypeSource = new BehaviorSubject<any>({});
  bedType$ = this.bedTypeSource.asObservable();


  private propertyTypeSource = new BehaviorSubject<any>({});
  propertyType$ = this.propertyTypeSource.asObservable();


  private washroomTypeSource = new BehaviorSubject<any>({});
  washroomType$ = this.washroomTypeSource.asObservable();

  private stateSource = new BehaviorSubject<IState[]>([]);
  state$ = this.stateSource.asObservable();

  private countrySource = new BehaviorSubject<ICountry[]>([]);
  country$ = this.countrySource.asObservable();

  private floorsSource = new BehaviorSubject<IFloors[]>([]);
  floors$ = this.floorsSource.asObservable();

  private cancelReasonsSource = new BehaviorSubject<ICancelReasons[]>([]);
  cancelReasons$ = this.cancelReasonsSource.asObservable();

  private bedTypeJsonPath = '../../../assets/master-data/bed-types.json';
  private washroomTypesJsonPath = '../../../assets/master-data/washroom-types.json';
  private propertyTypesJsonPath = '../../../assets/master-data/property-types.json';
  private statesJsonPath = '../../../assets/master-data/states.json';
  private countriesJsonPath = '../../../assets/master-data/countries.json';
  private floorsJsonPath = '../../../assets/master-data/floors-name.json';
  private cancelReasonsJsonPath = '../../../assets/master-data/cancel-reasons.json';

  //Master Data Types
  // public masterDataDisplayColumns: MasterDataDisplayColumns = {
  //   bedTypes: ['bed_type_id', 'bed_type', 'is_active', 'actions'],
  //   propertyTypes: ['property_type_id', 'property_type', 'is_active', 'actions'],
  //   washRoomTypes: ['washroom_type_id', 'washroom_type', 'is_active', 'actions'],
  //   countries: ['country_id', 'country_name', 'country_code_alpha_2', 'country_code_alpha_3', 'country_isd_code', 'currency_name', 'currency_code', 'is_active', 'actions'],
  //   states: ['state_id', 'country_id', 'state_code', 'state_name', 'is_union_territory', 'is_active', 'actions'],
  //   floors: ['floor_id', 'floor_name', 'is_active', 'actions'],
  //   cancelReasons: ['cancel_reason_id', 'cancel_reason', 'is_active', 'actions'],
  // }






  constructor(private readonly http: HttpClient) {

  }


  loadBedTypes() {
    this.http.get<any>(this.bedTypeJsonPath).subscribe(data => {
      this.bedTypeSource.next(data.bedTypes);
    })
  }

  loadWashroomTypes() {
    this.http.get<any>(this.washroomTypesJsonPath, { responseType: 'json' }).subscribe(data => {
      this.washroomTypeSource.next(data.washroomTypes);
    })
  }

  loadPropertyTypes() {
    //console.log('load prop');
    this.http.get<any>(this.propertyTypesJsonPath, {responseType: 'json'}).subscribe(data => {
      this.propertyTypeSource.next(data.propertyTypes);
    })
  }

  loadStates() {
    this.http.get<any>(this.statesJsonPath).subscribe(data => {
      this.stateSource.next(data.states);
    })
  }


  loadCountries() {
    this.http.get<any>(this.countriesJsonPath).subscribe(data => {
      this.countrySource.next(data.countries);
    })
  }

  loadFloors() {
    this.http.get<any>(this.floorsJsonPath).subscribe(data => {
      this.floorsSource.next(data.floors);
    })
  }

  loadCancelReasons() {
    this.http.get<any>(this.cancelReasonsJsonPath).subscribe(data => {
      this.cancelReasonsSource.next(data.cancelReasons);
    })
  }



  loadMasterData() {

    this.loadBedTypes();
    this.loadWashroomTypes();
    this.loadPropertyTypes();
    this.loadStates();
    this.loadCountries();
    this.loadFloors();
    this.loadCancelReasons();
  }


}
