import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../../services/master-data.service';
import { IWashroomType } from '../master-data';


@Pipe({
  name: 'washroomType',
  standalone: true
})
export class WashroomTypesPipe implements PipeTransform {

  constructor(private masterDataService: MasterDataService) { }
  private washroomTypes: IWashroomType[] = [];


  transform(washroomTypesId: number | undefined): string {

    if (!washroomTypesId) {
      return "";
    }
    /* Check if the washroomTypes array has empty object */
    if (this.washroomTypes.length == 0) {
      this.masterDataService.washroomType$.subscribe(washroomTypes => {
        this.washroomTypes = washroomTypes;
      })
    }
    const washroomTypes = this.washroomTypes.filter(washroomTypes => washroomTypes.washroom_type_id == washroomTypesId)
    return washroomTypes[0].washroom_type || '';
  }

}
