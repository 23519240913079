<div class="jk-au-container">
    <div class="jk-au-about">
        <h1>About CourtallamRooms</h1>
        <p>At CourtallamRooms, we are dedicated to offering our customers an exceptional booking experience and ensuring
            comfortable stays at our partnered accommodations. Our platform serves as a gateway to a diverse range of
            properties, each carefully selected to meet our high standards of quality and convenience.</p>
    </div>
    <div class="jk-au-who-we-are">
        <h2>Who We Are</h2>
        <p>CourtallamRooms is operated by Thamira Technologies LLP, along with its affiliates. We take pride in
            providing a
            user-friendly platform accessible through our website, mobile application, and call center services. Our
            goal is
            to streamline the booking process and provide prompt assistance to our customers.</p>
    </div>
    <div class="jk-au-services-offered">
        <h2>Services Offered</h2>
        <p>Through our platform, users gain access to a wide array of properties managed by third-party owners. We
            partner
            with reputable establishments to offer a variety of accommodation options, ensuring that our customers find
            the
            perfect fit for their needs and preferences. From cozy guesthouses to luxurious resorts, we strive to cater
            to
            every type of traveler.</p>
    </div>
    <div class="jk-au-registration">
        <h2>User Eligibility and Account Registration</h2>
        <p>To utilize our services, users must be at least eighteen years old and capable of adhering to our terms and
            conditions. While individuals under eighteen can browse our platform with parental guidance, account
            creation is
            reserved for those meeting the age requirement. We prioritize user privacy and security, requiring users to
            maintain the confidentiality of their login credentials.</p>
    </div>
    <div class="jk-au-booking-process">
        <h2>Booking Process and Service Assurance</h2>
        <p>We understand the importance of a comfortable stay, and we strive to ensure that our customers' expectations
            are
            met. Our platform provides detailed information about each property, including standard amenities and
            services
            offered. In the rare event of a denied check-in, we work diligently to arrange alternative accommodations or
            provide refunds as necessary. Our dedicated customer support team is available from 9 AM to 9 PM during peak
            seasons and from 10 AM to 6 PM during off-peak seasons to address any concerns
            and
            ensure a seamless experience for our customers, you can reach us at 74185 111 02, 74185 222 43.</p>
        <p>At CourtallamRooms, our mission is to exceed our customers' expectations by providing exceptional service,
            quality accommodations, and unmatched convenience. Whether you're planning a weekend getaway, a business
            trip,
            or a family vacation, we are here to assist you every step of the way. Thank you for choosing
            CourtallamRooms,
            and we look forward to helping you find the perfect accommodation for your next adventure.</p>
    </div>
</div>