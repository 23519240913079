import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IScreensize } from '../../app';
import { PropertyService } from '../../shared/services/property.service';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';
import { ScrollHandleService } from '../../shared/services/scroll-handle.service';
import { BrowserStorageService } from '../../shared/services/storage.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { SearchResultsComponent } from '../../user/search-results/search-results.component';
import { IPaginationMetaData, ISearchFieldsValues, ISearchPropertyDetails } from '../property';
import { ResultsComponent } from './results/results.component';
import { SearchFormComponent } from './search-form/search-form.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-search',
    standalone: true,
    imports: [
        CommonModule,
        MatExpansionModule,
        TranslateModule,
        RouterLink,
        MatIconModule,
        MatMenuModule,
        SearchFormComponent,
        SearchResultsComponent,
        ResultsComponent,
        MatToolbarModule,
        SpinnerComponent
    ],
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]

})
export class SearchComponent implements OnInit {
    public token!:string | null;
    public panelOpenState: boolean = true;
    public hideToggle: boolean = true;
    public screenInfo!: IScreensize;
    public properties: ISearchPropertyDetails[] = [];
    private metaData!: IPaginationMetaData;
    public endSearchResult = false;
    /** This flag will be used to inform child components that the results has reached to front end or not so that we can post results actions suces hiding loader etc.,  */
    public resultsReceived: boolean = false;

    private propertySearchDetailsJson!: ISearchFieldsValues;
    constructor(

        private propertyService: PropertyService,
        private screensizeDetectService: ScreensizeDetectService,
        private scrollHandleService: ScrollHandleService,
        private browserStorageService: BrowserStorageService
        //private _bottomSheet: MatBottomSheet
    ) {
        this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
            this.screenInfo = screenInfo;
        });

        this.scrollHandleService.triggerSearch$.subscribe(triggerSearch => {
            if (triggerSearch) {

                if (this.metaData.current_page < this.metaData.last_page) {
                    this.doSearch(this.metaData.current_page + 1);
                } else {
                    this.endSearchResult = true;
                }
            }
        })
        this.token = this.browserStorageService.get('token');
    }

    ngOnInit() { }

    searchFiltersReceived(propertySearchDetailsJson: ISearchFieldsValues) {
        this.propertySearchDetailsJson = propertySearchDetailsJson;
        this.properties = [];
        this.doSearch(1);

    }

    doSearch(nextPage: number) {
        /** reset the value to false other wise simplechanges wont be trigged if the property clicks submit button for second time via modify search */
        if (nextPage === 1) {
            this.resultsReceived = false;
        }


        // let data = {
        //     property_type_id: 2,
        //     from_date: '2024-07-01',
        //     to_date: '2024-07-03',
        //     occupancy: 2,

        // }

        const occupancyArr = [];
        const childrenArr = [];
        const ageArr = [];
        const property_type_id = this.propertySearchDetailsJson.property_type_id;
        const fromDate = (typeof this.propertySearchDetailsJson.from_date === 'object') ? this.propertySearchDetailsJson.from_date.toISOString() : this.propertySearchDetailsJson.from_date;
        const toDate = (typeof this.propertySearchDetailsJson.to_date === 'object') ? this.propertySearchDetailsJson.to_date.toISOString() : this.propertySearchDetailsJson.to_date;


        // const private_falls = this.propertySearchDetailsJson.private_falls;

        for (var roomDetail of this.propertySearchDetailsJson.roomDetailsFormArray) {

            occupancyArr.push(roomDetail.adult_count);
            childrenArr.push(roomDetail.children_count);
            if (roomDetail.childrenAgeFormArray.length > 0) {
                //childrenArr.push(children.length);
                for (var children of roomDetail.childrenAgeFormArray) {
                    ageArr.push(children.age);
                }
            } else {
                //childrenArr.push(0);
                ageArr.push(0);
            }

        }
        let data = new HttpParams()
        // .set('private_falls', private_falls)
        if (property_type_id) {
            data = data.set('property_type_id', property_type_id)
        }
        data = data.set('from_date', fromDate)
        data = data.set('to_date', toDate)
        data = data.set('occupancy', occupancyArr.join(','));

        if (childrenArr.length > 0) {
            data = data.set('children_count', childrenArr.join(','))
        }
        if (ageArr.length > 0) {
            data = data.set('children_age', ageArr.join(','));
        }

        // Insert Property DETAILS
        //this.propertyService.searchProperty(this.propertySearchDetailsJson, nextPage).subscribe(properties => {
        this.propertyService.searchProperty(data, nextPage).subscribe(properties => {

            /* Inform child components that the results has come. do it only for the very first time when property hits the search button. so that it wont trigger when auto loading during the sroll  */
            this.resultsReceived = true;

            this.properties = properties.data;
            //}

            /* Append the new results with the existings.  */
            /* Todo: Need to set max limit that should be displayed at any point in time to handle browser memory issue */
            //this.properties = this.properties.concat(properties.result);
            //this.properties = this.properties.concat(properties.data);

            //this.properties = [...this.properties, properties.data];
            //this.metaData = properties.meta;
            if (properties.data?.length) {
                //this.showMobileForm = false;
                /* Inform layout compoent that we have found a new result set. Keep an eye on the scrolling end position */
                //  this.scrollHandleService.captureScrollPositon();
                //this.propertyService.getScrollingElement();
                //                this.closePanel();
            }

            //this.router.navigate(['property/details/update']);

        });
    }







    /** Expansion Panel */

    openPanel() {
        this.panelOpenState = true;
        /* Hide toggle only if no result */
        // this.hideToggle = (this.properties.length) ? false : true;
    }

    closePanel() {
        this.panelOpenState = false;
        this.hideToggle = false;
    }

    openBottomSheet(): void {
        // const bottomSheet = this._bottomSheet.open(SearchFormBottomSheetComponent);
        // bottomSheet.afterDismissed().subscribe(propertySearchDetailsJson => {
        //     // Todo: Need to show the loader
        //     // Do the search here

        //     if (propertySearchDetailsJson) {
        //         this.propertySearchDetailsJson = propertySearchDetailsJson;
        //         this.properties = [];
        //         this.doSearch(1);
        //     }


        // });
    }
}
