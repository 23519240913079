<div class="jk-form-container">
    <div *ngIf="displayLogoutSuccessMsg">
        <span>You have been signed out  successfully!.</span>
        <!-- <span class="jk-link" (click)="logoutFromAllDevices()">Click here</span><span> if you want to signout from all other devices.</span> -->
    </div>

    <div *ngIf="displayLogoutAllSuccessMsg">
        <span>You have been signed out from all the devices successfully.</span>
        <span class="jk-link">Click here</span><span> if you want to signout from all other devices.</span>
    </div>
</div>