import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
//import { Razorpay } from 'razorpay';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { IScreensize } from '../../app';
import { LoginComponent } from '../../components/login/login.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { BedTypePipe } from '../../shared/master-data/pipes/bed-types.pipe';
import { PropertyService } from '../../shared/services/property.service';
import { RazorPayService } from '../../shared/services/razorpay.service';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';
import { BrowserStorageService } from '../../shared/services/storage.service';
import { UserService } from '../../shared/services/user.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { RegistrationComponent } from '../../user/registration/registration.component';
import { IBrrRoomDetails, ISearchDisplayValues, ISearchFieldsValues, ISelectedRoom } from '../property';
import { IrpCheckoutResponse, IrpOrderResponse } from '../razorPay';
declare var Razorpay: any;
@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    LoginComponent,
    RegistrationComponent,
    MatInputModule,
    RouterLink,
    RouterOutlet,
    BedTypePipe,
    SpinnerComponent,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss'
})
export class CartComponent {

  @Input() selectedRoomDetails!: ISelectedRoom[];
  @Input() property_id!: number;
  // Read the search values from view details page as the this values may be changed in the user.
  @Input() searchValuesArr!: ISearchDisplayValues;
  @Output() updateRoomSelections = new EventEmitter<number>();

  public showPayButton!: boolean
  public screenInfo!: IScreensize;
  public showOverlay: boolean = false;
  public showPaymentSuccessMsg: boolean = false;
  public showCancelMsg: boolean = false;
  public totalPriceWithoutGst: number = 0;
  public totalPriceWithGst: number = 0;
  public gst: number = 0;
  //public isInclusiveOfGst: boolean = false; // This has to come from the backend
  public gstPercentage = 12;// This has to come from the backend
  public platformCharges = 25;// This has to come from the backend
  public showSpinner: boolean = false;
  // one of the below three should be true at any time
  public showCart: boolean = true;
  public showLogin: boolean = false;
  public showRegistraion: boolean = false;
  public disablePayButton: boolean = false;

  public isUserLoggedIn = false;

  public showPaymentProcessingMessage: boolean = false;
  //public searchValuesArr!: ISearchDisplayValues;

  public displayFromDate!: string;
  public displayToDate!: string;
  public noOfDays: number = 0;
  public noOfSelectedRoom: number = 0;
  public roomDetailsArr: IBrrRoomDetails[] = []; // This will hold the room details selected by the user to be stored in backend
  public dialogResult!: boolean;
  private confirmAction = false;
  constructor(
    private screensizeDetectService: ScreensizeDetectService,
    private browserStorageService: BrowserStorageService,
    private propertyService: PropertyService,
    private razorPayService: RazorPayService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    //private bedTypePipe: BedTypePipe


  ) {
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });
  }

  ngOnInit() {

    this.showPayButton = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngoninit changes');
    // only run when property "data" changed
    if (changes['selectedRoomDetails']) {
      //this.searchValuesArr = this.browserStorageService.getSearchValuesFromLocalStorage();
      // this.searchValuesArr.from_date = moment(this.searchValuesArr.from_date, "YYYY-MM-DD");
      // this.searchValuesArr.to_date = moment(this.searchValuesArr.to_date, "YYYY-MM-DD");
      this.displayFromDate = moment(this.searchValuesArr.from_date, "YYYY-MM-DD").format('DD-MM-YYYY');
      this.displayToDate = moment(this.searchValuesArr.to_date, "YYYY-MM-DD").format('DD-MM-YYYY');
      this.selectedRoomDetails = changes['selectedRoomDetails'].currentValue;
      console.log(this.selectedRoomDetails)
      this.totalPriceWithGst = 0;
      this.totalPriceWithoutGst = 0;
      this.gst = 0;
      this.noOfDays = this.getNumberOfDays();
      this.noOfSelectedRoom = this.getSelectedRoomCount();
      console.log('noOfDays');
      console.log(this.noOfDays);
      for (var selectedRoomDetail of this.selectedRoomDetails) {

        this.totalPriceWithoutGst += (selectedRoomDetail.quantity * selectedRoomDetail.discounted_price * this.noOfDays);
        console.log(this.totalPriceWithoutGst)
        // Check if the price is inclusive of GST
        if (selectedRoomDetail.is_gst_included) {
          // dont add additional gst
          this.totalPriceWithGst = this.totalPriceWithoutGst / 100;

        } else {

          this.gst = (this.totalPriceWithoutGst / 100) * (this.gstPercentage / 100);
          this.gst = parseFloat(this.gst.toFixed(2))
          this.totalPriceWithGst = (this.totalPriceWithoutGst / 100) + this.gst;

        }

        this.totalPriceWithGst += this.platformCharges;

        console.log(this.totalPriceWithGst)
        this.totalPriceWithGst = parseFloat(this.totalPriceWithGst.toFixed(2))
        console.log(this.totalPriceWithGst)

      }
    }
  }

  processPayment() {

    this.showPayButton = false;
    this.showSpinner = true;
    // Check if user has already logged in or not

    if (this.userService.isUserLoggedIn()) {
      // 1.Check the room is available to make sure the room is not booked by anyone else in the meantime 
      // and insert a entry in the backend to block these rooms

      // 2. process payment

      //this.showPaymentProcessingMessage = true;
      this.createOrderRp();

    } else {

      // Turn off the spinner as we are navigating to different page (diefferent section)
      this.showSpinner = false;
      this.showPayButton = true;
      // Show Login form if user comes from cart automatically

      this.showCart = false;
      this.showLogin = true;
      this.showRegistraion = false;

      // else {
      //   // If user comes from registration page by clicking login link
      //   if (this.showLogin) {
      //     this.showCart = false;
      //     this.showLogin = true;
      //     this.showRegistraion = false;

      //   }
      //   // if user come from login page by clicking registration link
      //   else if (this.showRegistraion) {
      //     this.showCart = false;
      //     this.showLogin = false;
      //     this.showRegistraion = true;

      //   }
      // }



    }

  }

  showLoginForm() {

    this.showCart = false;
    this.showLogin = true;
    this.showRegistraion = false;

  }
  showRegistraionForm() {
    this.showCart = false;
    this.showLogin = false;
    this.showRegistraion = true;
  }
  showCartForm() {
    this.showCart = true;
    this.showLogin = false;
    this.showRegistraion = false;
  }

  setUserAsLoggedIn() {
    this.showCart = true;
    this.showLogin = false;
    this.showRegistraion = false;
    this.processPayment();

  }

  getSelectedRoomCount() {
    let noOfRoomsSelected = 0;
    this.selectedRoomDetails.map(room => {
      noOfRoomsSelected += room.quantity;
      return room;
    })
    return noOfRoomsSelected;

  }
  createOrderRp() {

    // Details like no of adult, no of children and age are stored in browser storage as thease of user provided information.
    // Details like selected room_id and price will come from this.selectedRoomDetails array
    // For BookRoom Request we need details for both.
    let searchJsonObj: ISearchFieldsValues;
    const searchJsonStr = this.browserStorageService.get('searchJson');
    console.log(this.selectedRoomDetails);

    if (searchJsonStr) {

      searchJsonObj = JSON.parse(searchJsonStr);
      console.log("searchJsonObj", searchJsonObj)

      this.roomDetailsArr = [];
      let roomDetails!: IBrrRoomDetails;
      /*
  
      The counter is used to count the no(index not the total no) of room selected. 
      For eg, user may select 2 rooms from the same bed type and one room from the other bed type in which case counter will be 1 to 3. 
      This will be used to get the user entered details like adultcount, children count etc from browser storage where we have one object for each room. 
      But in selectedRoomDeails we may have one entry for more than one room with quantity
      
  */
      let counter = -1;
      // Iterate seleted rooms for diffent bed type / category ids
      for (var selectedRoomDetail of this.selectedRoomDetails) {
        // Iterate to add rooms equal to the quantity for the same 
        for (var i = 0; i < selectedRoomDetail.quantity; i++) {
          counter++;
          // Convert [{age:2}, {age:3] to [2, 3]
          const ageArr = searchJsonObj.roomDetailsFormArray[counter].childrenAgeFormArray.map(obj => obj.age);
          console.log(ageArr.join(','));
          roomDetails = {
            room_id: selectedRoomDetail.available_room_ids[i],
            no_of_adult: searchJsonObj.roomDetailsFormArray[counter].adult_count,
            no_of_children: searchJsonObj.roomDetailsFormArray[counter].children_count,
            children_age: ageArr.join(','),
            price: selectedRoomDetail.price,
            discounted_price: selectedRoomDetail.discounted_price,
            is_gst_included: selectedRoomDetail.is_gst_included
          }
          this.roomDetailsArr.push(roomDetails);


        }
        console.log(roomDetails.no_of_adult);

      }

      const params = {
        property_id: this.property_id,
        total_booking_amount: this.totalPriceWithGst * 100, // amount in paise
        from_date: this.searchValuesArr.from_date,
        to_date: this.searchValuesArr.to_date,
        roomDetails: this.roomDetailsArr

      }



      this.razorPayService.createOrder(params).subscribe(response => {
        this.showSpinner = false;
        
        this.openRazorPayModal(response.razorpay_order, response.master_booking_id, response.user_id);
      })
    }
  }

  openRazorPayModal(response: IrpOrderResponse, master_booking_id: number, user_id: number) {

    const options: any = {
      //key: environment.razorPay.keyId,
      order_id: response.id,
      amount: response.amount, // Amount in paise 
      currency: response.currency,
      name: 'CourtallamRooms',
      image: 'https://courtallamrooms.com/assets/images/logo.png',
      description: 'booking room',

      handler: (checkoutResponse: IrpCheckoutResponse) => {

        // User selected information 
        const params = {
          property_id: this.property_id,
          //total_booking_amount: this.totalPriceWithGst * 100, // amount in paise
          from_date: this.searchValuesArr.from_date,
          to_date: this.searchValuesArr.to_date,
          roomDetails: this.roomDetailsArr

        }

        const bookingDetails = {
          amount_received: response.amount,
          total_gst: this.gst * 100,
          master_booking_id: master_booking_id,
          user_id: user_id
        };


        const orderAndCheckoutResponse = { ...params, ...bookingDetails, ...checkoutResponse };
        this.savePaymentDetails(orderAndCheckoutResponse)

      },

      notes: {
        address: 'address here'
      },

      theme: {
        color: '#8bc34a'
      },

      modal: {
        ondismiss: () => {
          this.deleteBookingsByMasterId(master_booking_id)
          console.log('dismissed');
          this.showPayButton = true;

        }

      }

    }
    const razorPay = new Razorpay(options);
    razorPay.open(options);
  }

  savePaymentDetails(params: any) {
    this.showOverlay = true;
    this.showPaymentSuccessMsg = true;
    console.log(this.showOverlay);
    console.log(this.showPaymentSuccessMsg);
    this.razorPayService.savePaymentDetails(params).subscribe(response => {
      this.showOverlay = false;
    this.showPaymentSuccessMsg = false;
      this.router.navigate(['user/success-page']);

    });


  }

  getNumberOfDays() {
    console.log(this.searchValuesArr.from_date);
    console.log(this.searchValuesArr.to_date);

    const fromDate = moment(this.searchValuesArr.from_date, "YYYY-MM-DD");
    const toDate = moment(this.searchValuesArr.to_date, "YYYY-MM-DD");
    console.log(fromDate);
    console.log(toDate);
    return toDate.diff(fromDate, 'days');

  }

  // Show dialog to inform the user that you have closed the payment window. Do  you want to continue the payment
  onDismiss() {

  }

  removeRoomFromCart(bed_type_id: number) {
    console.log(bed_type_id);
    this.selectedRoomDetails = this.selectedRoomDetails.filter(room => room.bed_type_id != bed_type_id);
    this.updateRoomSelections.emit(bed_type_id);
    this.noOfSelectedRoom = this.getSelectedRoomCount();
  }

  deleteBookingsByMasterId(master_booking_id: number) {
    this.showOverlay = true;
    this.showCancelMsg = true;
    this.propertyService.deleteBookingsByMasterId(master_booking_id).subscribe(result => {
      this.showOverlay = false;
      this.showCancelMsg = false;
      console.log('deleted successfully');
    })

  }

  showConfirmationDialog(bed_type_id: number) {

    //const bedType = this.bedTypePipe.transform(bed_type_id);
    //const bedType = '';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      minHeight: '150px',
      maxHeight: '95vh',
      data: {
        title: "Confirm",
        message: 'Are you sure you want to remove this room?',
        bedTypeId: bed_type_id,
      }
    });


    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
      console.log(this.confirmAction)
      if (this.confirmAction) {
        this.removeRoomFromCart(bed_type_id);
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result.value;
      // console.log(this.dialogResult)
      dialogSub.unsubscribe();
    });


  }
}