import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PropertyService } from '../../../shared/services/property.service';
import { HttpClientModule } from '@angular/common/http';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, timestamp } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../shared/services/notification.service';
import { SpinnerComponent } from '../../../shared/spinner/spinner.component';
import { IBookingDetail, IBookingDetailForRoom, ICancelMsg } from '../../../property/property';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { MatCardModule } from '@angular/material/card';
import moment from 'moment';

@Component({
  selector: 'app-view-booking-details',
  standalone: true,
  providers: [
    DatePipe
  ],
  imports: [CommonModule,
    RouterLink,
    RouterOutlet,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    TranslateModule,
    SpinnerComponent],
  templateUrl: './view-booking-details.component.html',
  styleUrl: './view-booking-details.component.scss'
})

export class ViewBookingDetailsComponent implements OnInit {
  public masterBookingId!: number;
  public fromDate!: string;
  public toDate!: string;
  public totalAmountReceived!: number;
  public bookedOn!: string;
  public propertyName!: string;
  public dialogResult!: boolean;
  private confirmAction = false; // Recieves value from the its child component (ConfirmDialogComponent)
  public booking_id!: number;
  public viewDataSource!: IBookingDetail[];
  public bookingDetailsForRooms!: IBookingDetailForRoom[];
  public cancelSuccessMsg!: ICancelMsg;
  public bookingDetailsForRoomsLength!: number;
  public disableCancelAllButton: boolean = false;
  public showCancelAllButton: boolean = false;
  public showSpinner: boolean = false;
  public showButtonSpinner: boolean = false;
  public showButton!: boolean;
  public showCancel!: boolean;
  dataSource = this.viewDataSource;


  constructor(
    private readonly route: ActivatedRoute,
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private readonly translate: TranslateService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe) {

    //     const master_booking_id = this.route.snapshot.paramMap.get('master_booking_id');
    // console.log(master_booking_id)
    // if (master_booking_id) {
    //   this.masterBookingId = +master_booking_id

    // }
    //this.getViewBookingDetails();
    const master_booking_id = this.route.snapshot.paramMap.get('master_booking_id');
    const fromDate = this.route.snapshot.paramMap.get('from_date');
    const toDate = this.route.snapshot.paramMap.get('to_date');
    const totalAmountReceived = this.route.snapshot.paramMap.get('total_amount_received');
    const bookedOn = this.route.snapshot.paramMap.get('createdAt');
    console.log(bookedOn)
    const propertyName = this.route.snapshot.paramMap.get('property_name');

    if (master_booking_id && fromDate && toDate && totalAmountReceived && propertyName) {
      this.masterBookingId = +master_booking_id;
      this.fromDate = fromDate;
      this.fromDate = moment(this.fromDate).format('DD-MM-YYYY');
      this.toDate = toDate;
      this.toDate = moment(this.toDate).format('DD-MM-YYYY');
      this.totalAmountReceived = +totalAmountReceived;

      this.propertyName = propertyName;
    }
    if (bookedOn) {
      this.bookedOn = bookedOn;
      this.setDateFormat(bookedOn)
      // this.bookedOn = moment(this.bookedOn).format('DD-MM-YYYY');
    }

    console.log(master_booking_id)
  }

  ngOnInit() {
    this.showButton = true;
    // alert(this.masterBookingId)
    this.getViewBookingDetailsForRoom();
    this.showSpinner = true;
    this.disableCancelAllButton = false;
  }

  getViewBookingDetailsForRoom() {
    this.showButton = true;
    this.propertyService.getBookingDetailsForRoom(this.masterBookingId).subscribe(result => {
      console.log(result);
      this.bookingDetailsForRooms = result;
      if (this.bookingDetailsForRooms) {
        this.checkAllRoomsCancelled();
      }
      this.showSpinner = false;
    });

  }

  // getViewBookingDetails() {

  //   this.propertyService.getViewBookingDetails().subscribe(result => {

  //     console.log(result.data);
  //     this.dataSource = result.data;
  //   });

  // }


  public openCancelAllRoomDialogBox() {

    let title = '';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      minHeight: '150px',
      maxHeight: '95vh',
      data: {
        // propertyId: this.propertyId,
        // floorDetails: this.floorDetails,
        title: "Cancel All Rooms",
        message: "Do you want to cancel all the rooms"
      }
    });

    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
      if (this.confirmAction) {
        this.showButton = false;
        this.cancelAllRoom();
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result.value;
      console.log(this.dialogResult)
      dialogSub.unsubscribe();
    });
  }
  public openCancelThisRoomDialogBox(booking_id: number) {

    let title = '';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      minHeight: '150px',
      maxHeight: '95vh',
      data: {
        // propertyId: this.propertyId,
        // floorDetails: this.floorDetails,
        title: "Cancel This Room",
        message: "Do you want to cancel this room"
      }
    });

    const dialogSub = dialogRef.componentInstance.confirmAction.subscribe((action) => {
      this.confirmAction = action;
      if (this.confirmAction) {
        this.showButton = false;
        this.cancelOneRoom(booking_id);
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogResult = result.value;
      console.log(this.dialogResult)
      dialogSub.unsubscribe();
    });
  }

  cancelAllRoom() {
    this.showButtonSpinner = true;
    this.propertyService.cancelAllRoom(this.masterBookingId).subscribe(result => {
      this.showButtonSpinner = false;
      this.showButton = false;
      console.log(result.data);
      this.getViewBookingDetailsForRoom();
      this.showButton = false;
    });
  }

  cancelOneRoom(booking_id: number, ) {
    this.showButtonSpinner = true;
    this.showButton = false;
    this.propertyService.cancelOneRoom(booking_id).subscribe(result => {
      this.cancelSuccessMsg = result;
      this.showButtonSpinner = false;
      this.showButton = false;
      // Reload the page after cancalling a room
      this.getViewBookingDetailsForRoom();

    });
  }
  checkAllRoomsCancelled() {
    //  this.disableCancelAllButton = false;

    const checkedInBookings = this.bookingDetailsForRooms.filter(room => room.checkin_at !== null);
    if (checkedInBookings.length) {
      // Atlease one room has beeen checked in. So dont show the cancel button in the top section
      this.showCancelAllButton = false;
    } else {
      this.showCancelAllButton = true;
      // get all the rooms which can be allowed to cancel
      const bookingsWithCancelAllowed = this.bookingDetailsForRooms.filter(room => room.is_cancel_allowed === true);
      this.disableCancelAllButton = (bookingsWithCancelAllowed.length == 0) ? true : false;

    }
    //const isCancelledTrue = this.bookingDetailsForRooms.filter(room => room.checkin_at == null && room.checkout_at == null && room.is_cancel_allowed == true);

  }
  setDateFormat(timestamp: string): string {

    const formattedDate = this.datePipe.transform(timestamp, 'dd-MM-YYYY');
    this.bookedOn = moment(this.bookedOn).format('DD-MM-YYYY');
    // this.createdAt = formattedDate;
    // console.log(this.createdAt)
    return formattedDate ? formattedDate : '';

  }

}
