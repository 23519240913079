import { Pipe, PipeTransform } from '@angular/core';
import { MasterDataService } from '../../services/master-data.service';


@Pipe({
    name: 'YesNo',
    standalone: true
})
export class YesNoPipe implements PipeTransform {

    constructor(private masterDataService: MasterDataService) { }


    transform(value: 0 | 1): string {


        return (value) ? 'Yes' : 'No';
    }

}
