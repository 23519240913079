<div class="jk-bd-container">
    <h1 class="jk-bd-error-header">Your Bookings</h1>
    <div class="jk-bd-content">
        @if(dataSource){
        <table mat-table [dataSource]="dataSource">

            <!-- Booking Id Column -->
            <ng-container matColumnDef="booking_id">
                <th mat-header-cell *matHeaderCellDef> Booking Id </th>
                <td mat-cell *matCellDef="let row"><a
                        [routerLink]="['/user/view-booking-details', row.master_booking_id,row.booking_details[0].from_date,row.booking_details[0].to_date,row.amount_received,row.created_at,row.booking_details[0].property.property_name]">{{row.master_booking_id}}</a>
                </td>
            </ng-container>

            <!--Property Name Column -->
            <ng-container matColumnDef="property_name">
                <th mat-header-cell *matHeaderCellDef> Property Name </th>
                <td mat-cell *matCellDef="let row"> {{row.booking_details[0].property.property_name}} </td>
            </ng-container>

            <!-- From Date Column -->
            <ng-container matColumnDef="from_date">
                <th mat-header-cell *matHeaderCellDef> From Date </th>
                <td mat-cell *matCellDef="let row"> {{setDateFormat(row.booking_details[0].from_date)}} </td>
            </ng-container>



            <!-- To Date Column -->
            <ng-container matColumnDef="to_date">
                <th mat-header-cell *matHeaderCellDef> To Date </th>
                <td mat-cell *matCellDef="let row"> {{setDateFormat(row.booking_details[0].to_date)}} </td>
            </ng-container>

            <!-- Room Type Column -->
            <ng-container matColumnDef="total_amount_received">
                <th mat-header-cell *matHeaderCellDef> Total Amount Received </th>
                <td mat-cell *matCellDef="let row"> &#8377; {{row.amount_received/100}} </td>
            </ng-container>

            <!-- Room No Column -->
            <ng-container matColumnDef="booked_on">
                <th mat-header-cell *matHeaderCellDef> Booked on</th>
                <td mat-cell *matCellDef="let row"> {{setDateFormat(row.created_at)}} </td>
            </ng-container>




            <!-- Contact No Column -->
            <!-- <ng-container matColumnDef="mobile_no">
                <th mat-header-cell *matHeaderCellDef> Mobile No. </th>
                <td mat-cell *matCellDef="let row"> {{row.mobile_no}} </td>
            </ng-container> -->


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


        }@else {

        <div>
            
            <div class="jk-bd-error-content">You Have No Bookings</div>
        </div>

        }
        <div class="jk-home-button">
            <a [routerLink]="['/landing']"><button mat-raised-button color="primary">Home</button></a>
        </div>
    </div>

    @if(showSpinner){
    <div class="jk-spinner">
        <div class="jk-loading-text">Loading Data...</div>
        <app-spinner></app-spinner>
    </div>
    }
</div>