import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../shared/services/property.service';

@Component({
  selector: 'app-static-pages',
  standalone: true,
  imports: [],
  templateUrl: './static-pages.component.html',
  styleUrl: './static-pages.component.scss'
})
export class StaticPagesComponent implements OnInit{
  ngOnInit(){
    this.loadFalls();
  }
  constructor(
    private propertyService: PropertyService
) {}

loadFalls() {
  this.propertyService.loadFalls().subscribe(result => {
       const loadFalls = result.data;
    console.log(loadFalls);


  });
}
}
