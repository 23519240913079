<div class="jk-cu-container">
    <div class="jk-cu-contact">
        <h1>Contact Us</h1>
        <p>For any kind of assistance, please feel free to contact us</p>
    </div>

    <div>Email: <span class="jk-cu-bold">{{email}}</span> <mat-icon (click)="copyText()">content_copy</mat-icon></div>
    <p>or call <span class="jk-cu-bold">74185 111 02, 74185 222 43</span></p>

    <div>
        <h3>Address</h3>
        <div>74/R2, PSRKS Plaza, Madurai Main Road,</div>
        <div>Tenkasi - 627811, Tamilnadu - India.</div>
        
    </div>
</div>