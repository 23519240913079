<div class="jk-user-review-container">
    <h1>Review</h1>
    <form class="jk-user-review-form" [formGroup]="reviewFormGroup" (submit)="onSubmit()">
        <div class="jk-user-review-field">
            <mat-form-field>
                <mat-label>Review</mat-label>
                <textarea matInput placeholder="" formControlName="review"></textarea>
            </mat-form-field>
        </div>
            <div class="jk-user-review-submitbtn">
                <button mat-raised-button type="submit">Submit</button>
            </div>
    </form>
</div>
<app-review-list></app-review-list>