<div class="jk-sr-card-container" [ngClass]="screenInfo.cssClass">
    <ng-container *ngIf="properties.length">
        <div *ngFor="let property of properties; index as i; " class="jk-card-container">

            <div class="jk-card-img">
                <a target="_blank" [routerLink]="['view-property', property.property_id]">
                    @if(property.primary_photo_path){
                    <img src="{{thumbnailUrl + property.primary_photo_path }}" alt="{{property.property_name}}">
                    }@else{
                    <img src="{{thumbnailUrl + '2024/02/22/50_18.jpg' }}" alt="{{property.property_name}}">
                    }
                </a>
            </div>
            <div class="jk-card-content">
                <div class="jk-card-title">{{property.property_name}} </div>
                <div class="jk-card-text-container">
                    <div class="jk-card-content-left">

                        <div class="jk-sr-facilities">
                            <ul>

                                @if(property.free_wifi===1){
                                <li>Free Wifi:<span>{{property.free_wifi | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Free Wifi:<span>{{property.free_wifi | YesNo}}</span>
                                </li>
                                }


                                @if(property.tv===1){
                                <li>Tv:<span>{{property.tv | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Tv:<span>{{property.tv | YesNo}}</span></li>
                                }


                                @if(property.ac===1){
                                <li>Ac:<span>{{property.ac | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Ac:<span>{{property.ac | YesNo}}</span></li>
                                }



                                @if(property.washroom_type_id===1){
                                <li class="jk-facility-wt">Washroom <span class="jk-hide">Type</span>:<span>{{property.washroom_type_id | washroomType}}</span></li>
                                }@else{
                                <li class="jk-alert">Washroom Type:<span>{{property.washroom_type_id |
                                        washroomType}}</span>
                                </li>
                                }
                                @if(property.elevator===1){
                                <li>Lift:<span>{{property.elevator | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Lift:<span>{{property.elevator | YesNo}}</span></li>
                                }
                                @if(property.water_heater===1){
                                <li>Water Heater:<span>{{property.water_heater | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Water Heater:<span>{{property.water_heater |
                                        YesNo}}</span>
                                </li>
                                }


                                @if(property.car_parking===1){
                                <li>Car Parking:<span>{{property.car_parking | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Car Parking:<span>{{property.car_parking | YesNo}}</span>
                                </li>
                                }

                                @if(property.restaurant===1){
                                <li>Restaurant:<span>{{property.restaurant | YesNo}}</span></li>
                                }@else{
                                <li class="jk-facility-strike">Restaurant:<span>{{property.restaurant | YesNo}}</span>
                                </li>
                                }


                                @if(property.private_falls){
                                <li>Private Falls:<span>{{property.private_falls | YesNo}}</span></li>
                                }

                                @if(property.refrigerator){
                                <li>Refrigerator:<span>{{property.refrigerator | YesNo}}</span></li>
                                }



                                <!-- <li>Breakfast</li>
                            <li>Wifi</li>
                            <li>Bar</li>
                            <li>Kitchen</li> -->
                            </ul>
                        </div>
                        <!-- <div class="jk-card-desc">{{property.property_description}} </div> -->
                    </div>
                    <div class="jk-card-content-right">
                        <div class="jk-card-price">
                            @if(property.price){
                            <div class="jk-price-strike">&#8377;{{ property.price/100 }}</div>
                            }
                            &#8377;{{property.discounted_price/100}}
                        </div>
                        <div class="jk-card-view-button">
                            @if(screenInfo.screenSize !== 'xs'){
                            <a target="_blank" [routerLink]="['view-property', property.property_id]"><button
                                    mat-raised-button color="primary">View</button></a>
                            }@else{
                            <!-- opend the details page in the same window for the mobile -->
                            <a [routerLink]="['view-property', property.property_id]"><button mat-raised-button
                                    color="primary">View</button></a>
                            }

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </ng-container>

</div>