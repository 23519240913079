<div class="jk-pv-container" [ngClass]="screenInfo.cssClass" *ngIf="screenInfo.screenSize !== 'xs'">
    <div class="jk-pv-thumbnail-container">
        <div *ngFor="let photo of photos" class="jk-pv-thumbnail"
            [ngClass]="{'jk-pv-thumb-active': activePhoto.property_photo_id === photo.property_photo_id}">
            <!-- <img src="http://mettioliadmin.test/photos/" . {{photo.name}} . '.' {{photo.ext}} alt="{{user.first_name}}" /> -->
            <img src="{{ thumbnailUrl + photo.path + '/' + photo.name + '.' + photo.ext}}" alt="Photo"
                (click)="setActivePhoto(photo)" />
        </div>

    </div>
    <div class="jk-pv-photo-container" *ngIf="activePhoto">
        <img src="{{ mainPhotolUrl + activePhoto.path + '/' + activePhoto.name + '.' + activePhoto.ext}}" alt="Photo" />

        <!-- <div class="jk-pv-comment-section" [ngClass]="{'jk-pv-comment-active': showComment === true}">

            <div class="jk-pv-comments">
                <label>Photo Taken: </label> <span>Jul 2022</span>
                <div><label>Comments: </label>
                    <p>
                        The tooltip will be displayed below the element but this can be configured using the
                        matTooltipPosition input. The tooltip can be displayed above, below, left, or right of the
                        element. By default the position will be below. If the tooltip should switch left/right
                        positions in an RTL layout direction, then the positions before and after should be used instead
                        of left and right, respectively.
                    </p>
                </div>
            </div>
            <mat-icon class="" (click)="showComments()">comment</mat-icon>

        </div> -->
    </div>

    <div class="jk-pv-action-items">
        <div class="jk-pv-close" (click)="closeDialog()"> X </div>

    </div>
</div>

<div class="jk-pv-container" [ngClass]="screenInfo.cssClass" *ngIf="screenInfo.screenSize === 'xs'">

    <div class="jk-pv-photo-container">
        <div *ngFor="let photo of photos">
            <img src="{{ mainPhotolUrl + photo.path + '/' + photo.name + '.' + photo.ext}}"
                alt="Photo" />

            <!-- <div class="jk-pv-comment-section" [ngClass]="{'jk-pv-comment-active': showComment === true}">

            <div class="jk-pv-comments">
                <label>Photo Taken: </label> <span>Jul 2022</span>
                <div><label>Comments: </label>
                    <p>
                        The tooltip will be displayed below the element but this can be configured using the
                        matTooltipPosition input. The tooltip can be displayed above, below, left, or right of the
                        element. By default the position will be below. If the tooltip should switch left/right
                        positions in an RTL layout direction, then the positions before and after should be used instead
                        of left and right, respectively.
                    </p>
                </div>
            </div>
            <mat-icon class="" (click)="showComments()">comment</mat-icon>

        </div> -->
        </div>
    </div>

    <div class="jk-pv-action-items">
        <div class="jk-pv-close" (click)="closeDialog()"> X </div>

    </div>
</div>