import { Routes } from '@angular/router';
import { SearchComponent } from './property/search/search.component';
import { ViewPropertyComponent } from './property/view-property/view-property.component';

import { AboutUsComponent } from './components/about-us/about-us.component';
import { BookingPolicyComponent } from './components/booking-policy/booking-policy.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { LayoutNewUserComponent } from './core/layout-new-user/layout-new-user.component';
import { LayoutComponent } from './core/layout/layout.component';

import { LoginOtpComponent } from './components/login-otp/login-otp.component';
import { ReviewComponent } from './property/review/review.component';
import { StaticPagesComponent } from './static-pages/static-pages.component';
import { BookingDetailsComponent } from './user/booking-details/booking-details.component';
import { LandingComponent } from './user/landing/landing.component';
import { RegistrationComponent } from './user/registration/registration.component';
import { SearchResultsComponent } from './user/search-results/search-results.component';
import { SuccessPageComponent } from './user/success-page/success-page.component';
import { ViewBookingDetailsComponent } from './user/booking-details/view-booking-details/view-booking-details.component';
import { ViewMyProfileComponent } from './user/view-my-profile/view-my-profile.component';

export const routes: Routes = [

    { path: '', redirectTo: 'landing', pathMatch: 'prefix' },
    {
        path: 'new-user', component: LayoutNewUserComponent,
        children: [
            { path: 'login', component: LoginComponent },
            { path: 'signup', component: RegistrationComponent },
            { path: 'login/otp', component: LoginOtpComponent },
            /* :userId is for the new users who were added by the admin  */
            { path: 'login/otp/:userId', component: LoginOtpComponent },
            { path: 'verify/:userId', component: LoginOtpComponent },

            /* This will take user to verification form as the otp would be sent from another page */
            { path: 'login/otp/verify/:verificationId', component: LoginOtpComponent },

            { path: 'logout', component: LogoutComponent },

            { path: 'about-us', component: AboutUsComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'terms-conditions', component: TermsConditionsComponent },
            { path: 'faqs', component: FaqsComponent },
            { path: 'contact-us', component: ContactUsComponent },
            { path: 'booking-policy', component: BookingPolicyComponent },
            
        ]
    },

    {
        path: 'user', component: LayoutComponent, data: {},
        children: [
            
            { path: 'search', component: SearchComponent },
            { path: 'search/view-property/:propertyId', component: ViewPropertyComponent },
            { path: 'review', component: ReviewComponent },
            { path: 'static-pages', component: StaticPagesComponent },
            { path: 'success-page', component: SuccessPageComponent },
            { path: 'success-page/cancel', component: SuccessPageComponent },
            { path: 'booking-details', component: BookingDetailsComponent },
            { path: 'view-booking-details/:master_booking_id/:from_date/:to_date/:total_amount_received/:createdAt/:property_name', component: ViewBookingDetailsComponent },

            // Temporarily keeping it for razor pay
            { path: 'about-us', component: AboutUsComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'terms-conditions', component: TermsConditionsComponent },
            { path: 'faqs', component: FaqsComponent },
            { path: 'contact-us', component: ContactUsComponent },
            { path: 'booking-policy', component: BookingPolicyComponent },
            { path: 'view-my-profile', component: ViewMyProfileComponent },
            
        ]
    },

   
    { path: 'landing', component: LandingComponent },
   
    { path: 'search-results', component: SearchResultsComponent },

    // { path: '**',  component: ErrorPageComponent }


];
