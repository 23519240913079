import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { SKIP_TOKEN } from "../../core/interceptors/interceptors";


@Injectable({
    providedIn: 'root'
  })

export class UserService {

    private loginServiceUrl = environment.apiUrl + 'login';
    private logoutServiceUrl = environment.apiUrl + 'logout';
    private logoutAllServiceUrl = environment.apiUrl + 'logout/all';
    private loginOtpServiceUrl = environment.apiUrl + 'login';
    private loginOtpGenerateServiceUrl = environment.apiUrl + 'login/otp/generate';
    private loginVerifyOtpServiceUrl = environment.apiUrl + 'login/otp/verify';
    private getUserMobileNumbersUrl = environment.apiUrl + 'getUserMobileNumbers';


    constructor(private readonly http: HttpClient,) {
        //console.log(environment.apiUrl);
      }


  /* Login */

  loginUser(data: object): Observable<any> {

    return this.http.post<any>(this.loginServiceUrl, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }


  generateOtp(data: object): Observable<any> {

    return this.http.post<any>(this.loginOtpGenerateServiceUrl, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }

  getUserMobileNumbers(userId: string): Observable<any> {
    return this.http.get(this.getUserMobileNumbersUrl + '/' + userId);
  }

  verifyOtp(data: object, verfication_id: string): Observable<any> {

    return this.http.post<any>(this.loginVerifyOtpServiceUrl + '/' + verfication_id, data, { context: new HttpContext().set(SKIP_TOKEN, true) });
  }


  /* Logout */

  logoutUser(): Observable<any> {

    return this.http.get(this.logoutServiceUrl);
  }

  logoutUserFromAllDevices(): Observable<any> {

    return this.http.get(this.logoutAllServiceUrl);
  }


  isUserLoggedIn(): boolean {
    // Check if user is logged in by accessing authentication token from local storage or session storage
    return !!localStorage.getItem('token');
  }



}
