import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { IScreensize } from '../../app';
import { ScreensizeDetectService } from '../../shared/services/screensize-detect.service';
import { ScrollHandleService } from '../../shared/services/scroll-handle.service';
import { BrowserStorageService } from '../../shared/services/storage.service';
const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, RouterLink, MatIconModule, TranslateModule, RouterOutlet, MatSidenavModule, MatMenuModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  public token!:string | null;
  public environment!: any;
  public screenInfo!: IScreensize;
  public searchResultsFound: boolean = false;
  public hideFooterMenuForMobile: boolean = false;
  /* This flag is used to hide the toolbar on the result page when user starts to scroll */
  public scrollTop: number = 0;
  /* This will hold the current scolling position  */
  public currentScrollTop: number = 0;
  //mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  public mainUrl = environment.imageUrl + 'main/temp/';

  constructor(private screensizeDetectService: ScreensizeDetectService, private scrollHandleService: ScrollHandleService,    private router: Router,   private browserStorageService: BrowserStorageService,) {
    this.environment = environment;
    this.screensizeDetectService.screenSize$.subscribe(screenInfo => {
      this.screenInfo = screenInfo;
    });


    /* Enable the flag to send the flag to the serach component when it reaches the bottom */
    this.scrollHandleService.searchResult$.subscribe(resultFound => {
      if (resultFound) {
        this.searchResultsFound = true;
      }
    })


    this.token = this.browserStorageService.get('token');


  }

  handleScroll(event: any) {
    // Handle scroll only for search page and if search results found
    if (this.searchResultsFound) {
      // if Search results found meaning, we are in seach results page. So hide the footer link from layout and introduce the footer from search component.
      this.hideFooterMenuForMobile = true;
      const targetElement = event.target;
      this.scrollTop = targetElement.scrollTop;

      
      /* Check if 70% of scrolling has completed */
      const scrollEnd = targetElement.scrollTop > (targetElement.scrollHeight * 50 / 100)
      if (scrollEnd) {
        // if(scrollEnd){
        // Trigger Seach for the next result set
        this.scrollHandleService.triggerSearch();
        /* Reset the flag and wait for the notification from the search component, if they found new result set */
        this.searchResultsFound = false;

      }
    } else {
      this.hideFooterMenuForMobile = false;
    }

  }
 
}
